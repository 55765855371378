.slick {
  @media screen and (max-width : $screen-xs-max) {
    width  : 90%;
    margin : 0 auto;
  }
}

.slick-slide {
  text-align : center;

  .slick-initialized & {
    display        : inline-block;
    vertical-align : bottom;
    outline        : none;
    @media #{$phone} {
      float : none;
    }

    .group-associes & {
      padding : 0;
      height  : auto;
      @media screen and (max-width : $screen-sm-max) and (min-width: $screen-xs-max){
        padding : 0 5px;
      }

      .group-wrapper-avant-hover {
        .slick-slide {
          width : 100% !important;
        }
      }
    }
  }

  #slick-views-partenaires-2-slider & {
    vertical-align : middle;
  }

  img {
    display : inline-block;
  }
}

.slick-track {
  .group-wrapper-avant-hover &,
  .group-infos-hover & {
    width : 100% !important;

    .slick-slide {
      width : 100% !important;
      float : none;
    }
  }
}

.slick-list {
  .group-wrapper-avant-hover &,
  .group-infos-hover & {
    height : auto !important;
  }
}

.slick-arrow {
  position  : absolute;
  color     : #666a6c;
  font-size : 20px;

  //.vocabulary-hw_collections & {
  //  color : $white;
  //}

  .node-type-hw-intervenant & {
    color : $red;
  }
}

.slick__arrow {
  width     : 100%;
  position  : absolute;
  top       : 50%;
  transform : translateY(-50%);

  .slick-next {
    right : -10px;
  }

  .slick-prev {
    left : -10px;
  }
}

.slick-next {
  .group-associes & {
    @extend .glyphicon;
    position  : absolute;
    top       : 50%;
    font-size : 0;
    z-index   : 50;

    &:before {
      content   : "\e080";
      font-size : 20px;
    }
  }
}

.slick-prev {
  .group-associes & {
    @extend .glyphicon;
    position  : absolute;
    top       : 50%;
    font-size : 0;
    z-index   : 50;

    &:before {
      content   : "\e079";
      font-size : 20px;
    }
  }
}
