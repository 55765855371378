#navbar {
  margin           : 0;
  background-color : $white;
  border           : none;

  &:before {
    content    : '';
    height     : 4px;
    width      : 100%;
    background : $red;
    @media (max-width : $screen-sm-min - 1) {
      display : none;
    }
  }
}

.navbar-header,
.navbar-second {
  .header-top & {
    display        : inline-block;
    vertical-align : middle;
    float          : none;
  }
}

.header-top {
  padding : 18px 0 10px;
  @media (max-width : $screen-sm-min - 1) {
    padding : 0;
  }

  > .container-fluid {
    font-size : 0;
    @media (max-width : $screen-sm-min - 1) {
      padding : 0;
    }
  }

  .navbar-header {
    width : 40%;
    margin-top: -20px;
    @media (max-width : $screen-sm-min - 1) {
      width      : 100%;
      padding    : 10px 0;
      margin     : 0;
      text-align : center;
    }
  }

  .navbar-second {
    width   : 60%;
    height  : 58px;
    padding : 0;
    @media (max-width : $screen-sm-min - 1) {
      position         : relative;
      width            : 100%;
      padding          : 0 15px;
      background-color : $red;
      text-align       : center;
    }

    .region-navigation {
      position : relative;
      @extend .size-zero;
      overflow : hidden;
      @media (max-width : $screen-sm-min - 1) {
        height : 58px;
      }
    }
  }
}

.navbar-toggle {
  float   : none;
  margin  : 0;
  padding : 0;
  border  : none;
  @media (max-width : $screen-sm-min - 1) {
    position  : absolute;
    top       : 50%;
    transform : translateY(-50%);
    left      : 15px;
    z-index   : 10;
  }

  &:hover,
  &:focus {
    background : transparent !important;
  }
}

.glyphicon-menu-hamburger:before {
  font-size : 25px;
  color     : $white;
}

.logo {
  @media (max-width : $screen-sm-min - 1) {
    float     : none !important;
    max-width : 158px;
    margin    : 0 auto !important;
    display   : block;
  }
}

.slogan {
  display: none;
  color       : $red;
  @include rem(font-size, 18px);
  @include rem(line-height, 20px);
  @extend .phenomena-regular;
  font-weight : 700;
  @media screen and (max-width : $mobile - 1) {
    display : none;
  }
}

#page-header {
  border-top    : 1px solid $gris-base;
  border-bottom : 1px solid $gris-base;

  @media screen and (max-width : $screen-xs-max) {
    padding : 0;

    .region-header {
      margin : 0 -15px;
    }
  }
}

.header-top {
  padding-bottom: 0;
  .navbar-header {
    a.logo {
      margin-bottom: 0;
    }
  }
}

/****
IMAGES LIENS HEADER
 *******/
#block-menu-menu-menu-secondaire {
  .espace-perso {
    background: url(../images/espace-perso-header.png) no-repeat center top;
    /*background-position-x: center;
    background-position-y: top;
    background-size: initial;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;*/
  }
  .catalogue {
    background: url(../images/catalogue.png) no-repeat center top;
  }
  .catalogue:hover {
    background: url(../images/catalogue-hover.png) no-repeat center top;
  }
  .point-ventes {
    background: url(../images/point-vente.png) no-repeat center top;
  }
  .point-ventes:hover {
    background: url(../images/point-vente-hover.png) no-repeat center top;
  }
  .contact {
    background: url(../images/contact.png) no-repeat center top;
  }
  .contact:hover {
    background: url(../images/contact-hover.png) no-repeat center top;
  }
  .search {
    background: url(../images/search.png) no-repeat center top;
  }
  .search:hover {
    background: url(../images/search-hover.png) no-repeat center top;
  }

  li.connected {
    a {
      color: #ea6a6a!important;
      background: url(../images/avatar-connected.png) no-repeat center top;
    }
  }
}


/** REFONTE EDITO HEADER **/
.glyphicon-menu-hamburger:before {
  color: #6a7379;
}

.logo-mobile {
  display: none;
}

@media (max-width : $screen-sm-min - 1) {
  .navbar-header {
    border-bottom: 1px solid #e7e7e7;
    border-top: 5px solid #db716e;
  }
  .header-top .navbar-second {
    display: none;
  }
  .header-top .navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
  }

  .header-top .navbar-right {
    float: right!important;
    margin-right: 20px;
    display: block !important;
    
    li {
      float: left;
      margin-left: 10px;
    }

    .search {
      background: url(../images/search.png) no-repeat center top;
    }
    .espace-perso {
      background: url(../images/espace-perso-header.png) no-repeat center top;
      height: 25px;
    }
  }
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: block;
  }
}

#edit-search-api-views-fulltext-wrapper label {
  display: none;
}
#views-exposed-form-recherche-page .views-exposed-form {
  width: 100%;
  margin: 0 auto;
}
#views-exposed-form-recherche-page #edit-search-api-views-fulltext-wrapper {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

#block-views-exp-recherche-page .views-widget {
  position: inherit!important;
  right: inherit!important;
  top: inherit!important;
  width: 100%!important;
  text-align: center!important;
}

#block-views-exp-recherche-page .form-item-search-api-views-fulltext{
  width: 60%;
  float: left;
}

#edit-search-api-views-fulltext-wrapper .description {
  display: none;
}
#block-views-exp-recherche-page .views-submit-button {
  position: inherit;
  right: inherit;
  top: inherit;
  float: right;
}
#block-views-exp-recherche-page button {
  margin: 0;
  @extend .montserrat-regular;
}
.views-exposed-form .views-exposed-widget .btn:after {
  content: none;
}

@media (max-width : $screen-sm-min - 1) {
  #block-views-exp-recherche-page .content {
    width: 100%!important;
    padding-top: 20px!important;
  }
  #block-views-exp-recherche-page .form-item-search-api-views-fulltext {
    width: 100%;
    input {
      text-align: center;
      font-size: 14px;
    }
  }
  #block-views-exp-recherche-page .views-submit-button {
    float: inherit!important;
    right: 0!important;
    clear: both;
  }
}