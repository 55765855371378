html {
  font-size   : 16px;
  line-height : 1.1;

  @media screen and (max-width : $screen-sm-max) {
    font-size : 14px;
  }
  @media screen and (max-width : $screen-xs-max) {
    font-size : 12px;
  }
}

/**********
   FONTS
**********/
.montserrat-regular {
  font-family : "montserratregular", "serif";
}

.montserrat-bold {
  font-family : "montserratbold", "serif";
}

.montserrat-light {
  font-family : "montserratlight", "serif";
}

.phenomena-regular {
  font-family : "phenomenaregular", "serif";
}

.phenomena-bold {
  font-family : "phenomenabold", "serif";
}

.firasanscondensed-regular {
  font-family: "firasanscondensedregular", "serif";
}

.firasanscondensed-semibold {
  font-family: "firasanscondensedsemibold", "serif";
}

.firasanscondensed-bold {
  font-family: "firasanscondensedbold", "serif";
}

/**********
   TYPO
**********/
a {
  white-space : normal !important;

  &:hover,
  &:focus {
    color : $gris-base;
  }
}

span,
p,
b {
  color     : $gris-contenu;
  font-size : 14px;
}

/**********
  TITRES
**********/

h1 {
  @include rem(font-size, 40px);
  @include rem(line-height, 40px);
  @extend .phenomena-regular;

  .node-hw_intervenant-full & {
    color : $red;
  }

  .page-edito & {
    color          : $white;
    @extend .phenomena-regular;
    @include rem(font-size, 36px);
    @include rem(line-height, 38px);
    font-weight    : 700;
    text-transform : uppercase;
    text-align     : center;
  }

  .node-hw_intervenant-full & {
    margin-top : 0;
  }

  .page-user.not-logged-in &,
  .page-espace-perso &,
  .page-faq &,
  .node-type-page &,
  .page-outils &,
  .page-recherche &,
  .page-recherche-avancee &,
  .page-numerique &,
  .node-type-hw-intervenant &,
  .actualites &,
  .page-contact &,
  .page-sitemap & {
    @extend .phenomena-regular;
    font-weight    : 700;
    color          : $white;
    text-transform : uppercase;
    text-align     : center;
    @media #{$tablet} {
      @include rem(font-size, 70px);
    }
    @media screen and (max-width : $screen-xs-max) {
      font-size : 30px;
    }
  }

  .page-agenda &,
  .page-podcasts &,
  .page-dossiers &,
  .page-liste-videos {
    text-transform: capitalize;
  }

  .page-user.page-user-password.not-logged-in & {
		@media #{$tablet} {
		  @include rem(font-size, 40px);
		}
  }
}

h2 {
  @extend .phenomena-regular;
  color : $gris-base;

  .view-niveaux &,
  .view-niveaux & span {
    @extend .montserrat-regular;
    margin: 0;
    color: $red;
    @include rem(font-size, 30px);
    font-weight: normal !important;
    font-style: italic;

    span {
      @media screen and (max-width : $screen-xs-max) {
        display : none;
      }
    }
  }

  .content-bottom-bis & {
    color : $white;
  }

  #block-views-livres-block-10 & {
    @include rem(margin-bottom, 25px);
    margin-top : 0;
  }

  .ancre-bloc &,
  .view-faq & {
    @extend .phenomena-bold;
    @include rem(margin-bottom, 20px);
    color      : $gris-contenu;
    text-align : center;
    @include rem(font-size, 35px);
  }

  .group-details-pratiques & {
    margin           : 0;
    padding          : 9px 0;
    font-size        : 11px;
    font-weight      : 700;
    background-color : $red;
    text-align       : center;

    span {
      @extend .montserrat-regular;
      font-size      : 11px;
      font-weight    : 700;
      text-transform : uppercase;
      color          : $white;
    }
  }

  .view-header & {
    font-weight : 700;
  }

  .view-social-content & {
    font-size : 35px;
  }
}

h3 {
  .wrapper-infos-num & {
    @extend .phenomena-bold;
    font-size : 35px;
    @include rem(line-height, 40px);
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 0;
      font-size  : 30px;
    }
  }

  .current-search-item & {
    margin : 0;
    @extend .montserrat-regular;
    color  : $red;
    @include rem(font-size, 26px);
  }

  .node-faq-teaser & {
    margin     : 0;
    padding    : 5px 0 5px 30px;
    @extend .montserrat-regular;
    @include rem(font-size, 16px);
    background : url("../images/icone-plus.png") no-repeat left;
    cursor     : pointer;

    &.active {
      .page-numerique & {
        color      : $numerique;
        background : url("../images/icone-moins.png") no-repeat left;
      }

      .page-faq & {
        color      : $red;
        background : url("../images/icone-moins-rouge.png") no-repeat left;
      }
    }
  }

  .extrait & {
    @extend .montserrat-regular;
    font-size      : 16px;
    font-weight    : 700;
    line-height    : 20px;
    text-transform : uppercase;

    .#{$enfants} & {
      color : $vert-clair;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;
    }

    .outils & {
      color : $univers-outils;
    }

    .enseignants & {
      color : $univers-enseignants;
    }

    .page-numerique & {
      color : $numerique;
    }

    @media #{$tablet} {
      &:before,
      &:after {
        content        : "";
        display        : inline-block;
        vertical-align : middle;
        height         : 1px;
        width          : 42.5%;

        .#{$enfants} & {
          background-color : $vert-clair;
        }

        .adolescents & {
          background-color : $univers-ado;
        }

        .#{$tid-grands-ados} &,
        .#{$grands-ados} & {
          background-color : $univers-adulte;
        }

        .outils & {
          background-color : $univers-outils;
        }

        .enseignants & {
          background-color : $univers-enseignants;
        }

        .page-numerique & {
          background-color : $numerique;
        }
      }

      &:before {
        margin-right : 2%;
      }

      &:after {
        margin-left : 2%;
      }
    }
  }
}

/**********
  IMAGES
**********/
img {
  max-width : 100% !important;
  height    : auto !important;
}


/**********
  PARAGRAPHES
**********/

.field-name-field-chapo,
.field-name-hw-evenement-description,
.field-name-body {
  h2 {
    @extend .montserrat-regular;
    font-size   : 24px;
    color       : $gris-contenu;
  }
  h3 {
    @extend .montserrat-regular;
    font-size   : 18px;
    color       : $gris-contenu;
  }
  blockquote {
    background: #f7f7f7;
    @extend .montserrat-light;
    font-size: 20px;
    border-left: none;
    padding: 30px 5%;
    width: 110%;
    margin-left: -5%;
  }
  p {
    @include rem(margin, 18px 0);
    color       : $gris-contenu;
    @extend .montserrat-light;
    font-size   : 16px;
    line-height : 20px;
    clear       : both;

    a {
      color: #ea6a6a;
    }
  }
  ul, ol {
    li {
      color       : $gris-contenu;
      @extend .montserrat-light;
      font-size   : 16px;
    }
  }
  .bouton {
    border-radius: 1.5rem;
    border-width: 1px;
    border-style: solid;
    font-size: 13px;
    padding: 0.5rem 1.25rem;
    font-weight: 700;
    display: inline-block !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    text-transform: uppercase;
    min-width: 8.4375rem;
    text-align: center;
    color: #ffffff;
    background-color: #ea6a6a;
    border-color: #ea6a6a;
  }

  .bouton:hover {
    background: transparent;
    color: #ea6a6a;
  }
}

ul {
  .node-page & {
    max-width : 640px;
    @include rem(margin, 40px auto 10px);
    @include rem(padding, 0 40px);

    li {
      position       : relative;
      display        : inline-block;
      vertical-align : top;
      @include rem(margin, 15px 0);
      padding-left   : 15px;
      @media #{$tablet} {
        width : 49%;
      }
      @media screen and (max-width : $screen-xs-max) {
        width : 100%;
      }

      &:before {
        content    : "";
        width      : 7px;
        height     : 7px;
        display    : block;
        position   : absolute;
        top        : 10px;
        left       : -8px;
        background : url("../images/list-base.png") no-repeat left;
      }
    }
  }
}
