.pager--infinite-scroll {

  &.pager {
    margin : 0;
  }

  > .pager__item {
    display : inline-block;
    @include rem(margin, 20px 0);
  }

  li {
    &:before {
      content : "" !important;
    }

    a {
      @extend .bouton;
      background-color : transparent;

      .view-actualites &,
      .view-display-id-block_1 & {
        color            : $white;
        border-color     : $red;
        background-color : $red;

        &:hover {
          color            : $red;
          background-color : transparent;
        }
      }

      .view-outils-recherche- & {
        color            : $white;
        border-color     : $univers-outils;
        background-color : $univers-outils;

        &:hover {
          color            : $univers-outils;
          background-color : transparent;
        }
      }

      .view-catalogue-numerique &,
      .view-ressources-gratuites & {
        color            : $white;
        border-color     : $numerique;
        background-color : $numerique;

        &:hover {
          color            : $numerique;
          background-color : transparent;
        }
      }

      .view-niveaux &,
      .view-recherche-collection & {
        .enfants-et-preados & {
          color            : $white;
          border-color     : $vert-clair;
          background-color : $vert-clair;

          &:hover {
            color            : $vert-clair;
            background-color : transparent;
          }
        }

        .adolescents & {
          color            : $white;
          border-color     : $univers-ado;
          background-color : $univers-ado;

          &:hover {
            color            : $univers-ado;
            background-color : transparent;
          }
        }

        .grands-ados-et-adultes & {
          color            : $white;
          border-color     : $univers-adulte;
          background-color : $univers-adulte;

          &:hover {
            color            : $univers-adulte;
            background-color : transparent;
          }
        }

        .outils & {
          color            : $white;
          border-color     : $univers-outils;
          background-color : $univers-outils;

          &:hover {
            color            : $univers-outils;
            background-color : transparent;
          }
        }

        .#{$page-enseignants} &,
        .#{$enseignants} & {
          color            : $white;
          border-color     : $univers-enseignants;
          background-color : $univers-enseignants;

          &:hover {
            color            : $univers-enseignants;
            background-color : transparent;
          }
        }
      }
    }
  }
}

.pagination {
  width : 100%;

  > li {
    display        : inline-block;
    vertical-align : middle;
    margin         : 0 0.2% 15px;

    span,
    a {
      width         : 38px;
      height        : 38px;
      padding-left  : 0;
      padding-right : 0;
      @extend .montserrat-regular;
      @include rem(font-size, 20px);
      line-height   : 23px;
      border        : none;
      border-radius : 25px !important;

      .view-ressources-gratuites & {
        color            : $numerique !important;
        background-color : transparent;

        &:hover {
          color            : $white !important;
          background-color : $numerique;
        }
      }
    }

    a {
      color : $gris-base !important;
    }

    &.active {
      span {
        color            : $white;
        background-color : $gris-base;

        &:hover {
          background-color : $gris-base;
        }

        .view-ressources-gratuites & {
          color            : $white !important;
          background-color : $numerique !important;
        }
      }
    }
  }

  .prev {
    margin-left  : 0;
    margin-right : 1.5%;
  }
  .next {
    margin-left  : 1.5%;
    margin-right : 0;
  }

  .pager-ellipsis {
    display : none;
  }
}