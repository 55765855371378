.node-edito-teaser {
  @media (max-width : $screen-xs-max) {
    text-align : center;
  }
}

/**********
Livres
**********/
.view-mode-bloc_nouveautes,
.views-livre .views-row {
  position  : relative;
  @include rem(margin, $grid-gutter-width/2 auto);
  max-width : 198px;

  .group-associes & {
    margin-top : 0;
  }
}

.node-hw_livre-full {
  @include rem(margin, 30px 0);
}

.node-hw_livre-favoris {
  @include rem(margin, 45px 0);
}

.view-mode-ressources {
  cursor : pointer;
}

/**********
Partenaires
**********/
.node-partenaires-teaser {
  max-width        : 160px;
  margin           : 0 auto;
  height           : 86px;
  line-height      : 86px;
  background-color : $white;
  @extend .radius-10;
}

/**********
  Recherche
**********/
.view-mode-recherche {
  @include rem(margin, 40px 0);
  background-color : $white;
  border           : 1px solid $border-gris;

  @media #{$tablet} {
    @include rem(padding-left, 15px);

    //&:not(.block-views &):hover {
    //  @include box-shadow(0 0 18px rgba(0, 0, 0, 0.2));
    //}

    &.node:hover {
      @include box-shadow(0 0 18px rgba(0, 0, 0, 0.2));
    }

    .field-name-field-image,
    .field-name-hw-livre-couverture,
    .field-name-hw-intervenant-photos,
    .field-name-hw-evenement-date {
      max-width : inherit;
      margin    : 0 auto;
      padding   : 0;
    }

    .col-sm-8 {
      padding-top    : 20px;
      padding-bottom : 25px;
    }
  }

  @media (max-width : $screen-xs-max) {
    padding-bottom : 0;
    text-align     : center;

    .field-name-field-image,
    .field-name-hw-livre-couverture,
    .field-name-hw-intervenant-photos,
    .field-name-hw-evenement-date {
      max-width : 115px!important;
      margin    : 0 auto;
      padding   : 15px 0 0 0;
    }
  }
}

.node-hw_evenement-liste {
  font-size : 0;

  .col-sm-2,
  .col-sm-8 {
    @media #{$tablet} {
      float          : none;
      display        : inline-block;
      vertical-align : middle;
    }
  }

  .col-sm-2:last-child {
    vertical-align : top;
  }

  .view-evenements.view-display-id-block_5 & {
    @include rem(margin, 10px 0 40px);
  }

}

.node-hw_evenement-recherche {
  .group-details-pratiques {
    border: none;
    text-align: center;
    margin-top: 0;
    box-shadow: none;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;

    .field-name-hw-evenement-date {
      margin: 0 auto;
      display: inline-block;
    }
  }
}

/**********
  FAQ
**********/
.node-faq-teaser {
  padding : 10px 0;
}

/**********
  Intervenant
**********/
.node-hw_intervenant-full {
  max-width    : 960px;
  margin-left  : auto;
  margin-right : auto;
  @media #{$tablet} {
    @include rem(margin-top, 40px);
    @include rem(margin-bottom, 85px);
  }
  @media (max-width : $screen-xs-max) {
    @include rem(margin-bottom, 40px);
  }
}

/**********
  DELEGUE PEDAGOGIQUE / DISTRIBUTEUR
***********/

.node-distributeur-teaser,
.node-delegue_pedagogique-teaser {
  @extend .cadre-blanc-teaser;

  .col-sm-12 {
    /*@include rem(height, 220px);*/
    position : relative;
    width    : 100%;

    .page-contact & {
      padding-bottom : 64px;
    }
  }
}

.node-delegue_pedagogique-teaser {
  /*width : 442px; */
}

.node-delegue_pedagogique-teaser,
.node-distributeur-teaser {
  width : 100%;
}

/**********
Page Edito - actus, agendas
**********/
.node-edito-full {
  max-width : 912px;
  @media #{$tablet} {
    @include rem(margin, 60px auto);
  }
  @media screen and (max-width : $screen-xs-max) {
    margin-top : 15px;
  }
}

/**********
  CC
**********/

.view-mode-inclus {
  .field-wrapper {
    display        : inline-block;
    vertical-align : middle;
    margin         : 15px 0 0;
    @media #{$normal} {
      width : 25%;
    }
    @media screen and (max-width : $screen-sm-max) {
      width : 100%;
    }

    &:after {
      content          : "+";
      width            : 18px;
      height           : 18px;
      @extend .montserrat-bold;
      font-weight      : bold;
      color            : $white;
      font-size        : 20px;
      display          : inline-block;
      line-height      : 16px;
      vertical-align   : middle;
      background-color : $vert-clair;
      @extend .radius-20 ;
      @media screen and (max-width : $screen-sm-max) {
        margin-top : 10px;
      }
    }

    &:last-child:after {
      content : "";
      display : none;
    }

    .field-item {
      @media #{$normal} {
        display        : inline-block;
        vertical-align : middle;
        max-width      : 110px;
        width          : 80%;
        padding-left   : 1%;
        text-align     : left;
      }

      &:before {
        content        : "";
        display        : inline-block;
        vertical-align : middle;
        width          : 100%;
        max-width      : 40px;
        height         : 40px;
        @media #{$normal} {
          max-width : 30px;
          width     : 50%;
          height    : 30px;
        }
      }

      .field-inner {
        @media #{$normal} {
          display        : inline-block;
          width          : 60%;
          padding-left   : 8%;
          vertical-align : middle;
        }
      }

      &.nature-tid-8528,
      &.nature-tid-8530,
      &.nature-tid-8536,
      &.nature-tid-8537 {
        &:before {
          background      : url("../images/livre.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8520,
      &.nature-tid-8550 {
        &:before {
          background      : url("../images/picto_me.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8518 {
        &:before {
          background      : url("../images/picto_mc.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8522,
      &.nature-tid-8523 {
        &:before {
          background      : url("../images/picto_son.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8596 {
        &:before {
          background      : url("../images/picto_ressource_complementaire.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8508 {
        &:before {
          background      : url("../images/picto_video.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8547 {
        &:before {
          background      : url("../images/picto_interactif.png") no-repeat left;
          background-size : contain;
        }
      }

      &.nature-tid-8556 {
        &:before {
          max-width       : 50px;
          background      : url("../images/picto_parcours.png") no-repeat left;
          background-size : contain;
          @media #{$normal} {
            max-width : 40px;
          }
        }
      }

      &.nature-tid-12854 {
        &:before {
          background      : url("../images/picto_telecharger.png") no-repeat left;
          background-size : contain;
        }
      }
    }
  }
}

.view-mode-inclus_dans_ouvrage {
  @media #{$normal} {
    display        : inline-block;
    vertical-align : middle;
    max-width      : 110px;
    width          : 80%;
    padding-left   : 5%;
    text-align     : left;
  }

  &:before {
    content        : "";
    display        : inline-block;
    vertical-align : middle;
    width          : 100%;
    max-width      : 40px;
    height         : 40px;
    @media #{$normal} {
      max-width : 30px;
      width     : 50%;
      height    : 30px;
    }
  }

  .content {
    @media #{$normal} {
      display        : inline-block;
      width          : 57%;
      padding-left   : 8%;
      vertical-align : middle;
    }
  }

  &.nature-00261 {
    &:before {
      max-width       : 50px;
      background      : url("../images/picto_parcours.png") no-repeat left;
      background-size : contain;
      @media #{$normal} {
        max-width : 40px;
      }
    }
  }

  &.nature-00154 {
    &:before {
      background      : url("../images/picto_son.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00155 {
    &:before {
      background      : url("../images/picto_son.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00162 {
    &:before {
      background      : url("../images/picto_ressource_complementaire.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00098 {
    &:before {
      background      : url("../images/picto_video.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00071 {
    &:before {
      background      : url("../images/picto_interactif.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00166 {
    &:before {
      background      : url("../images/picto_telecharger.png") no-repeat left;
      background-size : contain;
    }
  }

  &.nature-00178 {
    &:before {
      background      : url("../images/picto_me.png") no-repeat left;
      background-size : contain;
    }
  }
}

/**********
SOCIAL CONTENT
**********/
.node-facebook-teaser {
  position : relative;

  &:before {
    content    : "";
    position   : absolute;
    top        : -28px;
    left       : 0;
    width      : 58px;
    height     : 28px;
    background : url("../images/picto-facebook.png") no-repeat top;
  }
}

/**********
PAGE DE BASE
**********/
.node-page {
  max-width : 790px;
  margin    : 0 auto;
  @extend .montserrat-regular;

  p, span, b {
    font-size : 16px;
  }
}

.page-edito {
  #block-crumbs-breadcrumb {
    text-align: center;
    width: 100%;
    @include rem(margin-top, 20px);
  }
  .node.view-mode-full {
    margin-top: 0;
  }
  .group-contenu {
    max-width: 660px;
    margin: 0 auto;
  }
  .group-associes {
    .field-type-entityreference > .field-items > .field-item {
      float: left;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

/**********
RESSOURCES COMPLEMENTAIRES RECHERCHE
********/
.node-hw_livre-recherche {
  .field-livre-link-contenus-complementaires {
    display: block;
    float: left;
    width: auto;
    @include rem(margin-top, 10px);
    @include rem(margin-right, 10px);
    padding: 0px;
    a {
      display: block;
      float: left;
      margin: 0 0 0 0;
      padding: 5px 20px;
      @include rem(font-size, 10px);
      text-transform: uppercase;
      border: 1px solid #ea6a6a;
      color: #ea6a6a;
      border-radius: 50px;
      text-decoration: none;
    }
    a::before {
      content: "\f019";
      font-family: "fontAwesome";
      margin-right: 10px;
    }
  }
  .field-name-hw-livre-couverture {
    max-width: inherit;
    padding: 0;
    img {
      margin: 0;
      width: 100%;
    }
  }
}

.view-id-recherche .row > .col-sm-2:first-child {
  padding: 0;
}