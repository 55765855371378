$red :                #ea6a6a;
$vert :               #58b690;
$vert-clair:          #b3cb75;
$h2-niveaux:          #6eb1a6;
$bleu-turquoise:      #72BDC3;
$gris-base :          #70787c;
$gris-secondaire :    #dcdddd;
$gris-contenu :       #404547;
$gris-classique :     #ccc;
$bg-gris :            #f7f7f7;
$bg-gris-livre :      #f2f2f2;
$border-gris :        #e7e7e7;
$gris-recherche :     #888e90;
$bleu-ebook :         #006ab0;
$bleu-fonce-ebook :   #00395e;
$orange-ebook :       #f06600;

// Niveau
$univers-ado :        #f4bc4b;
$univers-adulte :     #97cfc5;
$univers-user :       #b3aeab;
$univers-outils :     #ff9999;
$univers-enseignants: #a6b5de;

$numerique:           #58b690;
$auteur:              #b3aeac;


$menu :               #404547;

// BLANC & NOIR
$white :              #ffffff;
