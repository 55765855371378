.container-fluid {
  max-width : $container-large-desktop;

  .my-account .main-content > & {
    @media screen and (max-width : $mobile) {
      padding-left  : 0;
      padding-right : 0;
    }
  }
}

#page-title {
  //.page-numerique &,
  .front &,
  .page-numerique-manuels-numeriques &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books &,
  .page-numerique-application-tablette & {
    display : none;
  }

  .page-edito & {
    max-width    : 535px;
    margin-left  : auto;
    margin-right : auto;
  }
}

.page-user.not-logged-in #page-title-wrapper {
  position : relative;
  #page-title {
    @media screen and (max-width : $screen-xs-max) {
      text-transform : none;
    }
  }
}

/*.page-user.not-logged-in #visuel {
  background : url(../images/visuel-profil.png) no-repeat right bottom;
  display    : inline-block;
  width      : 200px;
  height     : 142px;
  position   : absolute;
  right      : 5%;
  bottom     : 0;
  @media screen and (max-width : $tab) {
    background      : url(../images/visuel-profil.png) no-repeat right bottom;
    background-size : 55% auto;
  }
  @media screen and (max-width : $mobile) {
    background-size : 40% auto;
    right           : 5%;
  }
  @media screen and (max-width : $min-mobile) {
    background-size : 30% auto;
    right           : 0;
  }
}*/

.header-top {
  #info-user {
    //display: none;
    text-align : center;
    font-size  : 0;
    @media #{$tablet} {
      //position : absolute;
      top      : 0;
      //right    : 15px;
      text-align: right;
    }
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 15px;
      display: none;
    }

    #info-user-bonjour {
      display : none;

      span {
        color     : $red;
        @extend .montserrat-regular;
        font-size : 13px;
      }
    }

    #info-user-logout {
      display     : inline-block;
      margin-left : 13px;

      span a {
        @extend .montserrat-regular;
        font-size      : 13px;
        color          : #9fa2a3;
        text-transform : uppercase;
        .info-user-logout-icon {
          font-size : 11px;
          @extend .montserrat-bold;
          color     : #9fa2a3;
        }
      }
    }
  }
}

.highlighted-top {
  &:not(.page-taxonomy &) {
    @media screen and (max-width : $screen-xs-max) {
      margin-bottom : 30px;
    }
  }

  .page-user.not-logged-in & #page-title-wrapper,
  .page-espace-perso &,
  .page-faq &,
  .node-type-page &,
  .page-outils &,
  .page-recherche &,
  .page-recherche-avancee &,
  .node-type-hw-intervenant &,
  .actualites &,
  .page-actualites &,
  .page-edito &,
  .page-contact &,
  .page-sitemap &,
  .page-agenda &,
  .page-podcasts &,
  .page-liste-videos &,
  .page-dossiers & {
    background-color : $univers-user;
    @media #{$tablet} {
      @include rem(padding, 45px 0 53px);
    }
    @media screen and (max-width : $screen-sm-max) {
      padding : 15px 0;
    }
  }

  .page-user.not-logged-in & #page-title-wrapper {
    @media screen and (max-width : $screen-sm-max) {
      margin : 0 -15px;
    }
  }

  .page-outils &,
  .outils & {
    background-color : $univers-outils;
  }

  .page-numerique & {
    background-color : $numerique;
    @media #{$normal} {
      @include rem(padding, 47px 0 57px);
    }
  }

  //.page-numerique & {
  //  @media screen and (max-width : $screen-sm-max) {
  //    padding : 0;
  //  }
  //}

  .page-user & {
    background-color : $univers-user;
  }

  .page-faq & {
    @media screen and (max-width : $mobile) {
      @include rem(margin-bottom, 38px);
    }
  }
}

.highlighted {
  .front & {
    background-color : $bg-gris;
  }

  .page-recherche &,
  .page-recherche-avancee & {
    font-size : 0;
  }

  .actualites &,
  .page-agenda &,
  .page-podcasts &,
  .page-dossiers &,
  .page-liste-videos &{
    max-width : 943px;
    margin    : 0 auto;
    @media #{$tablet} {
      @include rem(padding-top, 0px);
      @include rem(padding-bottom, 10px);
    }
    @media screen and (max-width : $screen-sm-max) {
      @include rem(padding-bottom, 40px);
    }
  }

  .vocabulary-hw_collections & {
    @media screen and (max-width : $screen-sm-max) {
      margin : 15px 0 50px;

      .block {
        padding-left  : 15px;
        padding-right : 15px;
      }
    }
  }

  .page-actualites & {
    @media screen and (max-width : $screen-xs-max) {
      margin-top : $grid-gutter-width;
    }
  }

  .page-faq & {
    @media #{$tablet} {
      margin-bottom : 4%;
    }
    @media screen and (max-width : $screen-xs-max) {
      margin-bottom : $grid-gutter-width;
    }
  }
}

.region-highlighted:not(.actualites &) {
  @media screen and (max-width : $screen-sm-max) {
    padding : 0 15px;
  }
}

.page-faq .row:after,
.page-user.my-account > .row:after {
  background : #f2f2f2;
  /*display: none;*/
}

.main-wrapper {
  .front &,
  .page-contact & {
    @include rem(padding, 45px 0 0);
  }

  .page-contact & {
    @media screen and (max-width : $mobile) {
      padding-top : 0;

      #block-system-main {
        margin : 0 -15px;
      }
    }
  }

  .page-taxonomy-term &,
  .page-outils &,
  .page-recherche &,
  .page-recherche-avancee &,
  .page-numerique-manuels-numeriques &,
  .page-numerique-application-tablette &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books &,
  .page-numerique-ressources &,
  .actualites &,
  .page-contact & {
    background-color : $bg-gris-livre;
  }

  .actualites & {
    @include rem(padding, 10px 0 20px);
  }

  .page-faq & {
    @media #{$tablet} {
      @include rem(padding, 63px 0 0);
    }
  }

  .page-user.my-account & {
    @media #{$tablet} {
      @include rem(padding, 63px 0 0);
    }
  }

  .page-numerique-manuels-numeriques &,
  .page-numerique-application-tablette &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books &,
  .page-numerique-ressources &,
  .vocabulary-hw_collections &,
  .page-outils &,
  .page-recherche &,
  .page-recherche-avancee & {
    @include rem(padding-top, 30px);

    .tabs--primary {
      @include rem(margin-bottom, 60px);
    }
  }

  .page-numerique-ressources & {
    @include rem(margin-top, 62px);
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 30px;
    }
  }

  .page-numerique-manuels-numeriques &,
  .page-numerique-application-tablette &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books &,
  .page-faq &,
  .page-user.my-account &,
  .page-outils &,
  .page-recherche &,
  .page-recherche-avancee & {
    .main-content {
      background : #f2f2f2;
    }
  }

  .vocabulary-hw_collections &,
  .vocabulary-hw_education_niveau &, {
    background: $white;
  }

  .page-contact & {
    @include rem(padding-bottom, 80px);
  }

  .page-user.my-account & .main-content {
    @media #{$tablet} {
      @include rem(padding-bottom, 80px);
    }
  }

  .page-numerique-ressources &,
  .page-numerique-manuels-numeriques &,
  .page-numerique-application-tablette &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books &,
  .page-outils & {
    @include rem(margin-top, 50px);
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 30px;
    }
  }

  .page-numerique & {
    @include rem(margin-bottom, 66px);
    /*@media screen and (max-width : $screen-xs-max) {
      margin : 0;
    }*/
  }

  .page-numerique-manuels-numeriques &,
  .page-numerique-application-tablette &,
  .page-numerique-parcours-digital &,
  .page-numerique-e-books & {
    margin-bottom : 0;
  }
}

.page-taxonomy-term {
  .highlighted {
    .container-fluid {
      @media screen and (max-width : $tab) {
        max-width     : 100%;
        padding-left  : 0;
        padding-right : 0;
      }
    }
  }
}

.content-footer {
  clear : both;
  @include rem(padding, 30px 0);

  .page-recherche &,
  .page-recherche-avancee & {
    padding-bottom : 0;
  }

  .page-numerique & {
    //@include rem(padding, 80px 0);
    background : $bg-gris-livre;
  }

  .node-type-hw-intervenant & {
    @media screen and (max-width : $screen-xs-max) {
      padding-top : 0;
    }
  }
}

.pre-footer {
  @include rem(margin-top, 35px);

  .page-numerique &,
  .page-user.my-account &,
  .page-faq &,
  .page-edito &,
  .page-contact & {
    @include rem(margin-top, 0);
  }
}

.content-bottom {
  clear : both;

  .vocabulary-hw_collections & {
    @include rem(padding-top, 30px);
  }

  .node-type-hw-livre & {
    @include rem(padding-bottom, 70px);
    background-color : $bg-gris-livre;
    @media #{$tablet} {
      @include rem(padding, 30px 0 70px);
    }
  }

  .node-type-hw-intervenant & {
    @include rem(padding, 50px 0 70px);
    background-color : $bg-gris-livre;
    @media screen and (max-width : $screen-xs-max) {
      padding-bottom : 20px;
    }
  }

  .page-numerique & {
    background-color : $bg-gris;
  }

  .page-taxonomy-term-7835 & {
    display : none;
  }

  .page-edito & {
    @include rem(padding, 0px 0 70px);
    background-color : $bg-gris-livre;
  }
}

.ancres-bloc {
  text-align : center;
}

// Padding sur nouvelles régions
.region-content-bottom,
.region-content-bottom-bis {
  padding : 0 15px;
}

//
.content-bottom-bis {
  clear : both;

  .vocabulary-hw_education_niveau & {
    padding          : 25px 0;
    background-color : $vert !important;
  }

  .vocabulary-hw_collections & {
    @include rem(margin-top, 30px);
    @include rem(padding, 30px 0 0);
  }

  .#{$enfants} &,
  .enfants-et-preados & {
    background-color : $vert-clair;
  }

  .adolescents & {
    background-color : $univers-ado;
  }

  .#{$tid-grands-ados} &,
  .#{$grands-ados} & {
    background-color : $h2-niveaux;
  }

  .outils & {
    background-color : $univers-outils;
  }

  .#{$tid-formation} & {
    background-color : $univers-enseignants;
  }
}

.tabs--primary {
  .page-user.my-account & {
    @include rem(margin, 0 0 100px);
    position : absolute;
    right    : 0;
    .page-user.my-account & {
      li {
        background : $white;
      }
    }
  }
}

.messages {
  .page-user & {
    @include rem(margin, 14px 0 100px 0);

    &.alert {
      margin : 30px 0;
    }
  }
}

ul.contextual-links li {
  @include rem(line-height, 16px);
}

.page-actualites, .page-agenda, .page-podcasts, .page-dossiers, .page-liste-videos, .page-le-blog  {

  #block-crumbs-breadcrumb {
    display: none;
  }

  .highlighted-top {
    background: none;
    @include rem(padding-bottom, 10px);

    #page-title-wrapper {
      text-align: center;
    }

    h1 {
      color: #414547;
      background : url(../images/communications.png) no-repeat;
      display: inline-block;
      @include rem(padding-left, 50px);
      @extend .montserrat-regular;
      @include rem(font-size, 40px);
    }
  }

  .main-wrapper {
    background: white;
  }

  .view-display-id-page, .view-display-id-page_1 {
    padding-right: 0;

    .view-content {
      clear: both;
    }

    .view-filters {
      float: right;

      .form-select {
        background: url(../images/selector.png) no-repeat right 20px center;
        @extend .montserrat-bold;
        color: #9ea4a8;
        border-color: #e7e7e7;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
      }

      .form-item.form-type-select:after {
        width: 0;
      }

      .views-exposed-widgets {
        @include rem(margin-bottom, 40px);
      }
    }
  }
}

.page-agenda {
  .highlighted-top h1 {
    background : url(../images/evenement-large.png) no-repeat;
  }
}

.page-podcasts {
  .highlighted-top h1 {
    background : url(../images/podcast-large.png) no-repeat;
  }
}

.page-dossiers {
  .highlighted-top h1 {
    background : url(../images/dossier-large.png) no-repeat;
  }
}

.page-liste-videos {
  .highlighted-top h1 {
    background : url(../images/video-large.png) no-repeat;
  }
}

.view-donn-es-associees-edito {
  .field-content {
    .node {
      float: left;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

/** PAGE BLOG **/
.page-le-blog {
  .highlighted-top h1 {
    background: none;
    padding-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    @include rem(margin-top, 60px);
  }
  .view-blog {

    .slick--optionset--blog {
      @include rem(margin-top, 60px);
      @include rem(margin-bottom, 90px);


      .field {
        text-align: left;
      }
      .field-name-field-chapo {
        width: 80%;
        @include rem(font-size, 16px);
      }
      .field-name-type-edito {
        @include rem(font-size, 24px);
      }
      .field-name-title-field {
        min-height: inherit;
        @include rem(margin-bottom, 10px);
        h3 {
          @include rem(font-size, 30px);
          @extend .montserrat-regular;
        }
      }
      .slick-arrow {
        display: inline-block!important;
        border: none;
        background: none;
        color: #000000;
        padding: 20px 25px;
        border-radius: 50%;
        box-shadow: 0 2px 6px 1px rgba(0,0,0,0.15);
        background-color: white;
        transform: scale(1);
        font-size: 0;
        transition: all 0.4s ease 0s;
        cursor: pointer;
      }
      .slick-arrow:before {
        display: block;
        float: left;
        content: "\f054";
        font-family: "fontAwesome";
        font-size: 1rem;
        color: #4d5254;
        transition: color 0.5s ease;
      }
      .slick-prev {
        @include rem(left, -40px);
        transform: rotate(180deg);
      }
    }

    .view-filters {
      width: 100%;
      .views-exposed-widget {
        width: 100%;
        text-align: center;

        .form-item.form-group {
          @include rem(margin-left, 20px);
          @include rem(margin-right, 20px);
          @extend .montserrat-regular;
          @include rem(font-size, 16px);
          text-transform: uppercase;
          @media screen and (max-width : $screen-xs-max) {
            width: 100%;
            margin: 10px auto;
          }

          a {
            color: #404547;
          }
          a.active {
            color: #ea6a6a;
            padding-bottom: 10px;
            border-bottom: 3px solid #ea6a6a;
          }
        }
      }
    }
  }

  .view-blog.view-display-id-block_2 {
    @include rem(margin-top, 60px);
    @include rem(margin-bottom, 60px);

    .agenda-title, .soulign {
      float: left;
    }
    .agenda-title {
      @extend .firasanscondensed-semibold;
      font-size: 24px;
    }
    .soulign {
      width: 60%;
      margin-left: 20px;
    }
    .more-infos {
      @extend .montserrat-light;
      @include rem(font-size, 16px);
      color: #404547;
      clear: both;
      p{
        @extend .montserrat-light;
        @include rem(font-size, 16px);
        color: #404547;
        clear: both;
        @include rem(padding-top, 5px);
      }
    }
    .more {
      float: right;
      position: absolute;
      top: 10px;
      right: 20px;
      a {
        @extend .montserrat-regular;
        @include rem(font-size, 12px);
        color: #68737a;
      }
      i {
        -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .views-view-grid {

      .data-event {
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.04);
        border-radius: 15px;
        @include rem(padding-top, 15px);
        @include rem(padding-bottom, 15px);
      }

      .views-field-hw-evenement-date {
        float: left;
        color: #ea6a6a;
        text-align: left;
        @include rem(padding-left, 15x);

        .prefix-date {
          @include rem(font-size, 10px);
        }
        .date-day {
          @include rem(font-size, 30px);
          font-weight: bold;
          @include rem(line-height, 25px);
        }
        .date-month, .date-year {
          @include rem(font-size, 14px);
        }
      }
      .views-field-title {
        float: right;
        @include rem(padding, 0 20px);
        @extend .montserrat-regular;
        a {
          color: #404547;
          @include rem(font-size, 16px);
          @media screen and (max-width : $screen-xs-max) {
            @include rem(font-size, 14px);
          }
        }
      }
    }

  }
}
