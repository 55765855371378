.view-header {
  #block-views-livres-block-1 & {
    display : none;

    .node-type-hw-livre & {
      display : block;
      @extend .titre-ressources;
    }
  }

  .view-outils-recherche- & {
    background-color : $univers-outils;
  }

  .view-catalogue-numerique & {
    background-color : $numerique;
  }

  .view-social-content & {
    vertical-align : middle;
  }
}

.view-empty {
  @include rem(margin, 30px 15px);
  @extend .montserrat-light;
  color : $gris-base;
  @include rem(font-size, 14px);
}

/******
FAQ
******/

.view-faq {
  .view-header {
    @extend .montserrat-regular;
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
    color : $gris-base;
    @include rem(margin-bottom, 30px);
    @media screen and (max-width : $screen-sm-max) {
      margin-bottom : 0;
    }
  }

  #accordion {
    background : $bg-gris-livre;

    .views-row-last .field-name-body .field-item {
      border-bottom : none;
    }

    .views-row-last h2 {
      border-bottom : none;
    }

    h2 {
      border     : none;
      background : none;
      padding    : 0;
      margin     : 0;
    }

    .field-name-body {
      .field-item {
        //@include rem(padding, 6px 0);
        //border-bottom : 1px solid $gris-contenu;

        p {
          @extend .montserrat-light;
          font-size   : 14px;
          line-height : 18px;
          @media screen and (max-width : $screen-sm-max) {
            font-size : 12px;
          }
        }
      }
    }

    h2 {
      @extend .montserrat-regular;
      @include rem(font-size, 16px);
      @include rem(padding-left, 32px);
      @include rem(padding-top, 24px);
      @include rem(padding-bottom, 24px);
      border-bottom : 1px solid $gris-contenu;
      @media screen and (max-width : $screen-sm-max) {
        font-size : 12px;
      }

      .ui-accordion-header-icon {
        background : url("../images/toggle-faq-open.png") no-repeat left;
        height     : 18px;
      }

      &.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
        border-bottom-right-radius : 0;
        border-bottom-left-radius  : 0;
        border-top-right-radius    : 0;
        border-top-left-radius     : 0;
      }

      &.ui-accordion-header-active {
        border-bottom  : none;
        padding-bottom : 0;
        color          : $red;
        .ui-accordion-header-icon {
          background : url("../images/toggle-faq-close.png") no-repeat left;
          top        : 75%;
        }
      }

      .ui-accordion-header-icon {
        @include rem(padding-right, 32px);
        display : inline-block;
        left    : 0;
        top     : 50%;
      }
    }
  }

  .views-row:not(.views-row-last) {
    border-bottom : 1px solid #e6e6e6;
  }

  .views-widget-filter-field_categorie_faq_tid {
    width : 100%;

    .bef-select-as-links > .form-item > .form-item {
      @extend .onglet;
    }
  }
}

/**********
NIVEAUX
**********/
.view-niveaux {
  position : relative;
  @include rem(padding, 20px 0);

  .vocabulary-hw_education_niveau #block-system-main & {
    display : none;
  }

  #block-views-niveaux-block-1 & {
    .view-header {
      width : 60%;
    }

    .view-filters {
      @media #{$tablet} {
        width : 39%;
      }
    }
  }

  .view-header {
    @media #{$tablet} {
      @include rem(padding, 0 15px 8px);
    }
  }

  .view-filters {
    text-align : right !important;
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 15px;
    }

    .ctools-auto-submit-full-form {
      @media screen and (max-width : $screen-xs-max) {
        display : none;
      }
    }
  }

  .views-exposed-widget {
    float : none;

    &.views-submit-button {
      display : none;
    }

    .form-item {
      display : inline-block;
      @extend .montserrat-regular;
      @include rem(font-size, 14px);
      color   : $gris-base;
      @media screen and (max-width : $screen-xs-max) {
        width      : 100%;
        text-align : left;
      }

      &:not(.form-item-hw-livre-date-parution-value-1) {
        @include rem(padding-right, 25px);
      }
    }
  }

  .filter-mobile {
    position       : absolute;
    top            : 40px;
    right          : 0px;
    @extend .montserrat-regular;
    font-size      : 12px;
    font-weight    : 700;
    text-transform : uppercase;
    color          : $gris-base;

    &:after {
      content     : '';
      display     : inline-block;
      width       : 14px;
      height      : 9px;
      margin-left : 10px;
      background  : url("../images/arrow-dark-down.png") no-repeat center;
    }
  }

  .view-content {
    margin-top : 25px;
    @media screen and (max-width : $screen-xs-max) {
      margin-left  : -15px;
      margin-right : -15px;
    }

    .views-row {
      @include rem(margin-bottom, 25px);

      img {
        @include box-shadow(0 5px 15px 3px rgba(0,0,0,0.6));
      }
    }
  }
}

/**********
COLLECTIONS
**********/
.view-collection {
  position: relative;
  @include rem(padding, 20px 0);
}

.view-filters {
  .view-display-id-page & {
    text-align : center;
  }
}

.filter-mobile {
  @media #{$tablet} {
    display : none;
  }
}

.view-content {
  .view-display-id-block_9 & {
    @include rem(margin, $grid-gutter-width 0 55px);
  }

  .view-social-content & {
    vertical-align : middle;
  }
}

.views-submit-button {
  #block-views-exp-recherche-avancee-page &,
  .view-display-id-page &,
  .bloc-distributeur-monde & {
    display : none !important;
  }
}

.views-exposed-widget {
  position : relative;

  #views-exposed-form-recherche-avancee-page & {
    float   : none;
    display : inline-block;
    //max-width : 264px;
    width   : 100%;
    @media #{$tablet} {
      //width : 47%;
    }
    @media screen and (max-width : $screen-xs-max) {
      @include rem(margin, 25px 0 0);
    }

    &:first-child {
      @media #{$tablet} {
        margin-right : 5%;
      }
    }
  }

  #block-views-exp-recherche-avancee-page & {
    @media #{$tablet} {
      width : 47%;
    }
  }

  .view-niveaux & {
    float : none;
    @media #{$tablet} {
      display : inline-block;
    }
    @media screen and (max-width : $screen-xs-max) {
      @include rem(margin, 10px 0 0);
      width : 100%;
    }
  }
}

#edit-field-francais-pro-intensif-value-wrapper,
#edit-field-francais-general-value-wrapper {
  display : none;

  .page-taxonomy-term-7872 & {
    display : inline-block;
  }
}

.view-recherche {
  @include rem(padding-bottom, 70px);
}

#block-views-niveau-block-1 {
  .#{$page-enseignants} & {
    display : none;
  }
}

/******
DISTRIBUTEURS / DELEGUES PEDAGOGIQUES
******/

.view-distributeur,
.view-delegue-pedagogique {
  font-size  : 0;
  text-align : center;

  .form-type-select {
    max-width : 340px;
    @media screen and (max-width : $mobile) {
      margin : 0 auto;
    }

    .page-user & {
      margin : 0 auto;
    }
  }
  &.user-data-ready {
    > .view-footer {
      text-align  : center;
      font-size   : 14px;
      line-height : 20px;
      max-width   : 240px;
      margin      : 20px auto;
      @extend .montserrat-light;
      color       : $gris-base;

      a.contact-link {
        color       : $red;
        font-size   : 14px;
        line-height : 20px;
      }
      .page-contact & {
        display : none;
      }
    }
  }
}

.view-delegue-pedagogique {
  padding-left : 0 !important;
}

.block-views {
  .page-contact & {
    text-align : right;
  }

  .view {
    .page-contact & {
      @media #{$tablet} {
        display    : block;
        width      : 100%;
        //padding-left : 15px;
        text-align : left;
      }
    }
  }
}

.view-content {
  .quicktabs-tabpage & {
    .page-contact & {
      @media #{$tablet} {
        display    : inline-block;
        width      : 65%;
        //padding-left : 15px;
        text-align : left;
      }
    }
  }

  .hover-slide & {
    @include rem(padding, $grid-gutter-width/2 0);
  }
}

.block-fle-profil {
  .page-contact & {
    @media #{$tablet} {
      display      : inline-block;
      width        : 65%;
      padding-left : 15px;
      text-align   : left;
    }
  }
}

.page-contact {
  .view-delegue-pedagogique {
    .view-content {
      width: 100%;
      display: block;
    }
  }
}

.view-footer {
  .page-contact & {
    text-align : left;
  }
}

.view-distributeur {
  /*.form-item-zones {
    @include rem(margin, 0 auto 34px);
    @include rem(padding, 0 0 0 5px);
  }*/
  #edit-field-zone-geographique-tid-wrapper {
    @include rem(padding, 0 0 22px 15px);
    @media #{$tablet} {
      @include rem(margin, -10px 0 12px);
    }
    @media (max-width : $screen-xs-max) {
      margin-top   : 9px;
      padding-left : 0;
    }
  }
  #block-views-distributeur-block-1 .block-title-wrapper,
  #block-views-distributeur-block-4 .block-title-wrapper {
    display : none;
  }

  #edit-field-zone-geographique-tid-wrapper {
    float : none;

    .form-submit {
      display : none;
    }
  }
  /* #block-fle-profil-filtre-geo-distributeur {
     .page-contact & {
       width : 100%;
     }
   }*/
  &.form-item-zones {
    margin : 0 auto;
  }
}

.view-distributeur,
.view-delegue-pedagogique {

  &.view-display-id-block,
  &.view-display-id-block_2,
  &.view-display-id-block_3 {
    .view-header, .view-footer {
      .block-title {
        text-align    : center;
        margin-bottom : 30px;
        font-weight   : normal;
        @media screen and (max-width : $screen-xs-max) {
          margin-top : 30px;
        }
      }
    }

    > .view-content {
      font-size : 0;

      .views-row {
        display        : inline-block;
        vertical-align : bottom;
        text-align     : center;
        @media screen and (max-width : $screen-xs-max) {
          padding-left  : 0;
          padding-right : 0;
        }

        .node-teaser {
          border         : 1px solid $border-gris;
          display        : inline-block;
          vertical-align : middle;
        }
      }
    }
  }
}

.view-distributeur.view-display-id-block_3, .view-distributeur.view-display-id-block_2, .view-distributeur.view-display-id-block_4, .view-distributeur.view-display-id-block_5,
.view-delegue-pedagogique.view-display-id-block, .view-delegue-pedagogique.view-display-id-block_1, .view-delegue-pedagogique.view-display-id-block_2 {
  .node-distributeur-listes, .node-delegue-pedagogique {
    background: white;
    @include rem(margin-bottom, 20px);

    .group-middle-title {
      float: left;
      width: 40%;
      margin-top: auto;
      margin-bottom: auto;
      @include rem(padding-right, 15px);

      .field-name-field-prenom, .field-name-field-nom {
        @extend .montserrat-light;
        font-weight: 600;
      }

      h3 {
        font-size: 18px;
        @extend .montserrat-light;
        font-weight: 600;
      }

      @media screen and (max-width : $screen-xs-max) {
        width: 100%;
      }
    }
    .group-middle-data {
      float: left;
      width: 60%;
      margin-top: auto;
      margin-bottom: auto;
      @include rem(padding-left, 15px);

      div {
        text-align: left;
        font-size: 14px;
        color: #70787c;;
      }

      p {
        font-size: 14px;
        text-align: left;
        color: #70787c;
        margin: 0 0 .5rem;
      }

      @media screen and (max-width : $screen-xs-max) {
        width: 100%;
      }
    }

    .col-sm-7 {
      display: flex;
      min-height: 132px;
    }

    .col-sm-3:last-child {
      display: flex;
      height: 132px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .field-name-field-image {
      img {
        margin: 0;
        max-height: 150px;
      }
    }
    @media screen and (max-width : $screen-xs-max) {
      .field-name-field-image {
        display: none;
      }
      .col-sm-2:last-child {
        clear: both;
      }
    }
  }
}

.view-delegue-pedagogique.view-display-id-block {
  .group-middle-title {
    font-size: 18px;
  }
  .group-middle-data {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.bloc-distributeur-main {
  .view-content {
    .page-contact & {
      display : block;
      width   : 100%;
      @media screen and (max-width : $screen-xs-max) {
        margin-top : 34px;
      }
    }
  }
  > .view-content {
    .page-contact & {
      /*width   : 65%;
      display : inline-block;*/
    }
  }
}

.view-distributeur {
  .distributeur-monde {
    display : none;
  }
}

.view-delegue-pedagogique,
.view-distributeur {
  .page-user &.user-data-ready {
    .view-footer {
      display : none;
    }
  }

  &.view-display-id-block_1,
  &.view-display-id-block_2 {
    .view-filters,
    .view-content {
      display        : block;
      vertical-align : top;
      width          : 100%;
      font-size      : 0;
      @media screen and (max-width : $screen-xs-max) {
        width : 100%;
      }
    }

    .view-filters {
      max-width : 367px;
      margin    : 0 auto;
      @media screen and (max-width : $screen-xs-max) {
        width : 100%;
      }
    }

    .view-content {
      text-align : center;
      margin-top : 34px;
      @media screen and (max-width : $screen-xs-max) {
        text-align : center;

        .views-row {
          padding-left  : 0;
          padding-right : 0;
        }
      }
    }

    .view-filters {
      #views-exposed-form-delegue-pedagogique-block-1,
      #views-exposed-form-delegue-pedagogique-block-2,
      #views-exposed-form-distributeur-block-1,
      #views-exposed-form-distributeur-block {
        @include rem(padding-top, 16px);
      }

      #views-exposed-form-delegue-pedagogique-block-1,
      #views-exposed-form-delegue-pedagogique-block-2,
      #views-exposed-form-distributeur-block-1,
      #views-exposed-form-distributeur-block {
        .page-contact & {
          @media #{$tablet} {
            padding-left : 15px;
          }
        }
      }
    }
  }

  .view-footer {
    min-height : 400px;

    #block-fle-profil-filtre-geo-delegue-pedagogique,
    #block-fle-profil-filtre-geo-distributeur,
    #views-exposed-form-delegue-pedagogique-block-1,
    #views-exposed-form-delegue-pedagogique-block-2,
    #views-exposed-form-distributeur-block-1,
    #views-exposed-form-distributeur-block {
      position : relative;
      z-index  : 10;

      .views-exposed-widgets {
        font-size  : 0;
        text-align : center;

        div {
          display : block;
          width   : 100%;

          &.views-submit-button {
            display : none;
          }
        }
      }
    }
  }
}

#block-fle-profil-filtre-geo-delegue-pedagogique,
#block-fle-profil-filtre-geo-distributeur,
#block-fle-contact-contact-autres-demandes > .content {
  width          : 50%;
  margin         : 0 auto;
  vertical-align : top;
  @media screen and (max-width : $screen-xs-max) {
    width : 100%;
  }

  > .content, > .content-inner {
    overflow   : hidden;
    text-align : center;

    /*form {
    width   : 56%;
    display : inline-block;
    @media screen and (max-width : $mobile) {
      width        : 100%;
      margin-right : 0;
    }
  }*/
  }
}

#block-views-delegue-pedagogique-block-1,
#block-views-delegue-pedagogique-block-2,
#block-views-distributeur-block-1,
#block-views-distributeur-block {
  .page-contact & {
    @media #{$tablet} {
      margin-top : -60px;
    }
  }
}

#block-views-delegue-pedagogique-block-1,
#block-views-delegue-pedagogique-block-2,
#block-views-distributeur-block-1,
#block-views-distributeur-block {
  display: none;
}

/**********
  Numérique
**********/
.view-catalogue-numerique {
  border-bottom : 1px solid #cfd0d0;
  @media #{$tablet} {
    @include rem(padding-bottom, 30px);
  }
}

/************
Contenus complémentaires
**************/

.view-natcon {
  > .view-content {
    font-size : 0;
  }
}

.view-contenus-complementaires:not(.feuilletage) {
  > .view-content {
    font-size : 0;

    .node-type-hw-livre & {
      @media #{$normal} {
        margin : 0 -15px;
      }
    }

    > .views-row {
      .node-type-hw-livre & {
        @media #{$normal} {
          padding : 0 $grid-gutter-width/2;
        }
      }

      .block-bootstrap-login-modal {
        margin : 0;
        height : 75px;

        .navbar-nav {
          padding-top : 10px;
          overflow    : hidden;

          > li {
            margin-bottom : 5px;

            > a {
              color            : $white;
              background-color : $red;
              border-color     : $red;

              &:hover {
                background : transparent;
                color      : $red;
              }
            }
          }
        }
      }
    }

    .group-inclus-dans-ouvrage & {
      .views-row {
        margin : 15px 0 0;
        @media #{$normal} {
          width : 25%;
        }
        @media screen and (max-width : $screen-sm-max) {
          width : 100%;
        }

        &:not(.views-row-last):after {
          content          : "+";
          width            : 18px;
          height           : 18px;
          @extend .montserrat-bold;
          font-weight      : bold;
          color            : $white;
          font-size        : 20px;
          display          : inline-block;
          line-height      : 16px;
          vertical-align   : middle;
          background-color : $vert-clair;
          @extend .radius-20 ;
          @media screen and (max-width : $screen-sm-max) {
            margin-top : 10px;
          }
        }
      }
    }
  }
}

/**********
  Social
**********/
.view-social-content {
  .views-row {
    @media screen and (max-width : $screen-xs-max) {
      padding : 0;
    }
  }

  article {
    margin : $grid-gutter-width 0;
    border : 1px solid $border-gris;

    footer {
      display : none;
    }
  }
}

/**********
  Favoris
**********/

.view-favoris {
  .view-empty {
    p {
      text-align : center;
    }
    .bouton-favoris {
      cursor      : auto;
      @extend .bouton;
      @extend .montserrat-regular;
      font-size   : 12px;
      font-weight : normal;
      max-width   : 200px;
      width       : 100%;
      margin      : 0 auto;
      padding     : 7px 10px;
      margin-top  : 16px;
      &:before {
        content      : $favoris-out;
        font-size    : 16px;
        margin-right : 6px;
        @include fontawesome-icon();
      }
    }
  }
}

/*************************
  Ressources téléchargées
***************************/
.view-ressources-gratuites,
.view-ressources {
  //min-height : 800px;

  > .view-content {
    position      : relative;
    /*overflow      : hidden;*/
    margin-bottom : 30px;

    > .views-row, > .view-content-inner > .views-row {
      .group-wrapper {
        background : none;
        border     : none;
      }

      .node-hw_livre-ressources, .row, .col-sm-12 {
        > .row > .col-sm-12 {
          padding-bottom : 0;

          .group-wrapper {

            padding-bottom : 32px;

            .field-name-title-field h2 {
              margin-top    : 0;
              margin-bottom : 0;
              padding       : 10px 15px 25px;
              background    : $white;
            }
          }

          .group-bottom {
            position   : relative;
            display    : none;
            width      : 100%;
            margin-top : 0;
            z-index    : 10;
            @media screen and (max-width : $screen-sm-max) {
              margin-left : -30px;
            }

            .view-contenus-complementaires {
              background : $white;

              > .view-content {
                overflow : hidden;

                > .views-row {
                  padding : 0 15px;

                  .entity-paragraphs-item.view-mode-full {
                    background : $bg-gris-livre;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .group-bottom-open {
    .group-wrapper {
      background : url(../images/triangle.png) no-repeat bottom center !important;
    }
  }
}

.view-ressources {
  > .view-content {
    //min-height    : 700px;
  }
}

.ressources {
  .group-bottom & {
    @include rem(padding, 40px 0);

  }
}

.view-ressources-gratuites.filtre-recherche-view {
  > .view-header {
    //margin-top : 0;
  }

  > .view-content {
    //@include rem(margin-top, 60px);
  }

  .view-contenus-complementaires:not(.feuilletage) {

    > .view-content {
      padding-top : 0;
      text-align  : center;
      > .views-row {
        text-align : left;
        @media #{$normal} {
          width : 90%;
          &.views-row-odd {
            margin-right : 0;
          }
        }
      }
    }
  }
}

/**********
SEARCH
**********/
#edit-search-api-views-fulltext-wrapper {
  #views-exposed-form-recherche-avancee-page & {
    display : none;
  }
}

.page-agenda {
  .view-evenements {
    .views-exposed-widgets {
      .views-submit-button {
        display: none;
      }
    }
  }
}
