@import '_mixins.scss';

/***
Overrides des float left
***/
.make-xs-column {
  float          : none;
  display        : inline-block;
  margin         : 0 -0.125em;
  vertical-align : top;
}

.make-sm-column {
  @media screen and (min-width : $screen-sm-min) {
    float   : none;
    display : inline-block;
    margin  : 0 -0.125em;

    #block-views-social-content-block & {
      vertical-align : top;
    }
  }
}

.make-md-column {
  @media screen and (min-width : $screen-md-min) {
    float   : none;
    display : inline-block;
    margin  : 0 -0.125em;
  }
}

.make-lg-column {
  @media screen and (min-width : $screen-lg-min) {
    float   : none;
    display : inline-block;
    margin  : 0 -0.125em;
  }
}

/***
Suppression de marges pour les inline-blocks
***/

.size-zero {
  font-size : 0;

  > div {
    font-size : initial;
  }

  > .view-content {
    font-size : 0;

    > div {
      font-size : initial;
    }
  }
}

/***
Différentes classes menu
***/

.typo-grise {
  color : $gris-base;

  &:hover {
    color : $red;
  }
}

.typo-rouge {
  color : $red;

  &:hover {
    color : $gris-base;
  }
}

/*.newsletter {
  background : url("../images/newsletter-gris.png") no-repeat center top;

  @media screen and (max-width : $screen-xs-max) {
    background : url("../images/newsletter-blanc.png") no-repeat center;
  }
}*/

.catalogue {
  background : url("../images/catalogue-gris.png") no-repeat center top;

  @media #{$tablet} {
    &:hover {
      background : url("../images/catalogue-rouge.png") no-repeat 51% 1px;
    }
  }

  @media screen and (max-width : $screen-xs-max) {
    background : url("../images/catalogue-blanc.png") no-repeat center;
  }
}

.espace-perso {
  background : url("../images/espace-perso-gris.png") no-repeat center top;

  @media #{$tablet} {
    &:hover {
      background : url("../images/espace-perso-red.png") no-repeat 49% -1px;
    }
  }

  @media screen and (max-width : $screen-xs-max) {
    background : url("../images/espace-perso-blanc.png") no-repeat center;
  }
}

/***
Boutons
***/

.bouton {
  border-radius  : 1.5rem;
  border-width   : 1px;
  border-style   : solid;
  font-size      : 13px;
  @include rem(padding, 8px 20px);
  @extend .montserrat-regular;
  font-weight    : 700;
  display        : inline-block !important;
  box-shadow     : none;
  cursor         : pointer;
  text-transform : uppercase;
  @include rem(min-width, 135px);
  text-align     : center;

  &:hover {
    text-decoration : none;
  }

  span {
    font-size : 13px;
  }

  .dropdown-menu & {
    border-color     : $white;
    background-color : $white !important;
    color            : $vert-clair !important;

    &:hover {
      color            : $white !important;
      background-color : transparent !important;
    }
  }

  #block-menu-menu-menu-2-home-page & {
    width            : 270px;
    margin           : 7px 0;
    @extend .phenomena-regular;
    font-size        : 26px;
    @include rem(line-height, 30px);
    text-transform   : inherit;
    color            : #71c4cf;
    background-color : $white;

    &:hover {
      color            : $white;
      background-color : transparent;
    }
  }

  .wrapper-infos-num & {
    @extend .montserrat-bold;
    border-color     : $white;
    color            : $vert !important;
    background-color : $white;

    &:hover {
      color            : $white !important;
      background-color : transparent;
    }

    .page-taxonomy-term-7871 &,
    .tid-7871 & {
      color : $vert-clair;
    }

    .page-taxonomy-term-7867 &,
    .tid-7867 & {
      color : $univers-ado;
    }

    .page-taxonomy-term-7872 &,
    .tid-7872 & {
      color : $univers-adulte;
    }

    .tid-4 & {
      color : $univers-outils;
    }

    .page-taxonomy-term-7835 &,
    .#{$tid-formation} & {
      color : $univers-enseignants;
    }
  }

  .blocs-support-num & {
    @include rem(margin-top, 35px);
    color            : $white;
    background-color : $numerique;
    border-color     : $numerique;

    &:hover {
      color            : $numerique;
      background-color : $white;
    }
  }

  #block-block-12 & {
    @include rem(padding-left, 60px);
    background      : url("../images/search-blanc.png") no-repeat $numerique 25px 50%;
    background-size : 17px;

    &:hover {
      background      : url("../images/search-vert.png") no-repeat $white 25px 50%;
      background-size : 17px;
    }
  }

  #block-block-21 & {
    @include rem(padding-left, 50px);
    @extend .montserrat-bold;

    .page-numerique & {
      color        : $numerique;
      border-color : $numerique;
      background   : url("../images/lettre-verte.png") no-repeat 20px 50%;

      &:hover {
        color      : $white;
        background : url("../images/lettre-blanche.png") no-repeat $numerique 20px 50%;
      }
    }

    .page-faq & {
      color        : $red;
      border-color : $red;
      background   : url("../images/lettre-rouge.png") no-repeat 20px 50%;
      @media screen and (max-width : $screen-sm-max) {
        width            : 277px;
        background-color : $white;
        border           : none;
        font-weight      : 400;
      }

      &:hover {
        color      : $white;
        background : url("../images/lettre-blanche.png") no-repeat $red 20px 50%;
      }
    }
  }

  .view-contenus-complementaires & {
    color : $white;

    .#{$enfants} &,
    .enfants-et-preados & {
      background-color : $vert-clair;
      border-color     : $vert-clair;
    }

    .adolescents & {
      background-color : $univers-ado;
      border-color     : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      background-color : $univers-adulte;
      border-color     : $univers-adulte;
    }

    .outils & {
      background-color : $univers-outils;
      border-color     : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      background-color : $univers-enseignants;
      border-color     : $univers-enseignants;
    }

    .page-numerique & {
      background-color : $numerique;
      border-color     : $numerique;
    }

    &:hover {
      background-color : transparent;

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }
    }
  }

  #user-register-form & {
    @include rem(margin-top, 50px);
  }

  .modal-footer &,
  #ajax-user-login-wrapper &,
  #register-modal & {
    color            : $white;
    background-color : $red;
    border-color     : $red !important;

    &:hover {
      color            : $red;
      background-color : $white;
    }
  }
}

.petit-bouton {
  color          : $red;
  border         : 1px solid $red;
  border-radius  : 1.5rem;
  font-size      : 13px;
  line-height    : 22px;
  min-width      : 121px;
  @extend .montserrat-regular;
  display        : inline-block;
  padding        : 3px 1rem;
  box-shadow     : none;
  cursor         : pointer;
  text-transform : uppercase;
  text-align     : center;

  span {
    font-size : 13px;
  }

  &:hover {
    text-decoration : none;
  }
}

.bouton-niveaux {
  a {
    @extend .bouton;
    border-color     : $white;
    background-color : $white;

    .page-taxonomy-term-7871 &,
    .tid-7871 & {
      color : $vert-clair;
    }

    .page-taxonomy-term-7867 &,
    .tid-7867 & {
      color : $univers-ado;
    }

    .page-taxonomy-term-7872 &,
    .tid-7872 & {
      color : $univers-adulte;
    }

    .tid-4 & {
      color : $univers-outils;
    }

    .#{$page-enseignants} &,
    .#{$tid-formation} & {
      color : $univers-enseignants;
    }

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;
    }

    .outils & {
      color : $univers-outils;
    }

    &:hover {
      color            : $white;
      background-color : transparent;

      .tid-7871 & {
        background-color : $vert-clair;
      }

      .tid-7867 & {
        background-color : $univers-ado;
      }

      .tid-7872 & {
        background-color : $univers-adulte;
      }

      .tid-4 & {
        background-color : $univers-outils;
      }

      .#{$tid-formation} & {
        background-color : $univers-enseignants;
      }
    }
  }
}

/**********
Border
**********/
.border {
  border-width  : 1px;
  border-style  : solid;
  border-radius : 25px;
}

.radius-10 {
  border-radius : 10px;
}

.radius-20 {
  border-radius : 20px;
}

.radius-40 {
  border-radius : 40px;
}

/**********
Width
**********/
.width-0 {
  @media #{$tablet} {
    width   : 0 !important;
    display : none !important;
  }
}

.width-90 {
  width : 80% !important;
  @media (max-width : $screen-xs-max) {
    width : 100%;
  }
}

/**********
Classes
**********/
.sous-menu-title {
  #block-system-main-menu .dropdown-menu & {
    @include rem(margin-bottom, 10px);
    @include rem(font-size, 38px);
    @extend .phenomena-regular;
    cursor : default;
    display: none;

    &:hover {
      text-decoration : none !important;
    }
  }
}

.nolink {
  cursor : default;
}

.tooltip {
  @media #{$tablet} {
    z-index : 99999999 !important;
  }
}

.none {
  @media (max-width : $screen-xs-max) {
    display : none;
  }
}

.light-text {
  p {
    @extend .montserrat-light;
    line-height : 22px;
    text-align  : justify;
  }
}

.titre-recherche {
  a {
    color       : $gris-contenu;
    font-size   : 15px;
    line-height : 20px;
    @extend .montserrat-regular;
    font-weight : 700;
  }
}

.thinner {
  @extend .montserrat-light;
}

.titre-bloc-header {
  clear : both;
  @include rem(margin-bottom, 67px);

  .block-title {
    @include rem(margin-bottom, 25px);
    @include rem(font-size, 40px);
    @include rem(line-height, 40px);
    @extend .phenomena-regular;
    color : $numerique;
    @media (max-width : $screen-xs-max) {
      @include rem(margin-top, 25px);
      text-align : center;
    }
  }
}

.light-text-bloc {
  p {
    @extend .montserrat-light;
    @media #{$tablet} {
      font-size   : 16px;
      line-height : 24px;
    }
    @media (max-width : $screen-xs-max) {
      font-size   : 14px;
      line-height : 22px;
    }
  }
}

.cadre-blanc-teaser {
  overflow   : hidden;
  font-size  : 0;
  background : $white;
  @include rem(padding, 20px);
  @include rem(margin-bottom, 20px);
  text-align : left;
  @media screen and (max-width : $mobile) {
    width      : 100%;
    height     : auto;
    text-align : center;
  }
}

.couleurs-pages {
  color : $vert-clair;
}

.margin-top {
  @include rem(margin-top, 80px);
}

.img-gauche {
  float  : left;
  margin : 15px 15px 15px 0;
}

.img-droite {
  float  : right;
  margin : 15px 0 15px 15px;
}

.bloc-faq {
  p {
    @extend .montserrat-light;
    @include rem(font-size, 16px);
    @include rem(line-height, 24px);
  }
}

.fixed {
  position   : fixed;
  top        : 0;
  margin-top : 15px !important;
  z-index    : 99999;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color : $red !important;
}

.has-error .form-control {
  border-color : $red !important;
}

.modal-header {
  @include rem(padding, 20px 30px 20px 45px);
  background-color : $red;

  .close {
    margin-top : 5px;
    opacity    : 0.8;

    &:hover {
      opacity : 1;
    }

    span {
      @include rem(font-size, 36px);
      color : $white;
    }
  }
}

.modal-title {
  color       : #ffffff;
  @extend .phenomena-regular;
  font-weight : 700;
  @include rem(font-size, 36px);
}

.modal-footer {
  button {
    @extend .bouton;
  }
}

.full-width {
  width : 100% !important;
}

.alert-success {
  color : $gris-contenu;
  @extend .montserrat-light;
  @include rem(font-size, 18px);
}

.facetapi-limit-link {
  color          : $white;
  @extend .montserrat-light;
  text-transform : uppercase;
  font-size      : 14px;

  &:hover {
    color : $white;
  }
}

.close-button {
  position   : absolute;
  @include rem(top, 13px);
  left       : 17px;
  width      : 25px;
  height     : 25px;
  background : url('../images/icone-fermer.png') no-repeat center;
  @extend .radius-20;
}

/* Apply CSS to iOS affected versions only */
body.iosBugFixCaret.modal-open {
  position : fixed;
  width    : 100%;
}

/**********
Titre ressources livres
**********/
.titre-ressources {
  @include rem(margin, 30px 0);
  color       : $gris-contenu;
  @extend .phenomena-bold;
  @include rem(font-size, 35px);
  line-height : 22px;
  text-align  : center;
}

/**********
Ancres
**********/
.ancres-wrapper {
  @media #{$tablet} {
    @include rem(margin, 20px 0 45px);
  }
}

.ancres-liste {
  display : none;
  @media #{$normal} {
    display       : inline-block;
    padding       : 0;
    list-style    : none;
    @include box-shadow(0 0 18px rgba(0, 0, 0, 0.11));
    border-radius : 10px;
    overflow      : hidden;
    font-size     : 0;
  }

  #block-block-16 & {
    width     : 100%;
    max-width : 966px;
  }

  li {
    display  : inline-block;
    position : relative;

    a {
      display          : inline-block;
      @include rem(padding, 9px 25px);
      border           : 1px solid rgba(112, 120, 124, 0.4);
      background-color : #f8f8f8;
      opacity          : 0.5;
      color            : #404547;
      @extend .montserrat-regular;
      font-size        : 12px;
      text-transform   : uppercase;

      &:hover {
        opacity         : 1;
        color           : $white;
        text-decoration : none;

        .#{$enfants} &,
        .enfants-et-preados & {
          background-color : $vert-clair;
          border-color     : $vert-clair;
        }

        .adolescents & {
          background-color : $univers-ado;
          border-color     : $univers-ado;
        }

        .#{$tid-grands-ados} &,
        .#{$grands-ados} & {
          background-color : $univers-adulte;
          border-color     : $univers-adulte;
        }

        .outils & {
          background-color : $univers-outils;
          border-color     : $univers-outils;
        }

        .#{$tid-formation} &,
        .#{$enseignants} & {
          background-color : $univers-enseignants;
          border-color     : $univers-enseignants;
        }

        .page-numerique & {
          background-color : $numerique;
          border-color     : $numerique;
        }

        #block-block-16 & {
          background-color : $numerique;
          border-color     : $numerique;
        }
      }

      #block-block-16 & {
        width : 100%;
      }
    }

    &:nth-child(n+2) {
      a {
        border-left : none;
      }
    }

    /*&:first-child {
      a {
        border-radius : 10px 0 0 10px;
      }
    }

    &:last-child {
      a {
        border-radius : 0 10px 10px 0;
      }
    }

    &:nth-child(1) {
      a {
        border-radius : 10px;
      }
    }*/

    &:hover {
      &:after {
        content  : "";
        display  : block;
        margin   : -1px auto 0 auto;
        position : absolute;
        left     : 0;
        right    : 0;
        width    : 40px;
        height   : 16px;
        //background : url("../images/triangle-bas.png") no-repeat bottom;

        #block-block-16 & {
          margin-top : 0;
          background : url("../images/triangle-bas-vert-num.png") no-repeat bottom;
        }
      }
    }

    #block-block-16 & {
      width : 33.3339%;
    }
  }
}

.ancre-seule {
  border-radius : 10px !important;
}

.ancre-seule-left {
  border-radius : 0 0 10px !important;
}

.ancre-seule-right {
  border-radius : 10px 0 0 !important;
}

.ancre-bloc {
  max-width : 987px;
  @media #{$tablet} {
    @include rem(margin, 30px auto 90px);
  }
  @media screen and (max-width : $screen-xs-max) {
    @include rem(margin, 50px auto 50px);
  }

  p {
    @extend .montserrat-light;
    line-height : 22px;
  }
}

/**********
Retour collection
**********/
.link-to-before {
  float : right;
  @include rem(margin-top, $grid-gutter-width/2);
  @include rem(margin-bottom, $grid-gutter-width/2);
  @media screen and (max-width : $screen-xs-max) {
    display : none;
  }

  a {
    @extend .montserrat-regular;
    display        : inline-block;
    font-size      : 13px;
    text-transform : uppercase;

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;

      &:before {
        border-color : $vert-clair;
      }
    }

    .adolescents & {
      color : $univers-ado;

      &:before {
        border-color : $univers-ado;
      }
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;

      &:before {
        border-color : $univers-adulte;
      }
    }

    .outils & {
      color : $univers-outils;

      &:before {
        border-color : $univers-outils;
      }
    }

    .#{$tid-formation} & {
      color : $univers-enseignants;

      &:before {
        border-color : $univers-enseignants;
      }
    }

    .page-numerique & {
      color : $numerique;

      &:before {
        border-color : $numerique;
      }
    }

    &:before {
      content        : "<";
      display        : inline-block;
      margin-right   : 10px;
      font-size      : 15px;
      line-height    : 25.39px;
      text-transform : uppercase;
      text-align     : center;
      @extend .montserrat-regular;
      width          : 29px;
      height         : 29px;
      border         : 2px solid;
      border-radius  : 25px;
    }
  }
}

/**********
More Link
**********/
.more-link {
  clear      : both;
  text-align : center;

  a {
    @extend .bouton;

    .view-niveaux & {
      .#{$enfants} &,
      .enfants-et-preados & {
        color            : $white;
        background-color : $vert-clair;

        &:hover {
          background-color : transparent;
          border-color     : $vert-clair;
          color            : $vert-clair;
        }
      }

      .adolescents & {
        color            : $white;
        background-color : $univers-ado;

        &:hover {
          background-color : transparent;
          border-color     : $univers-ado;
          color            : $univers-ado;
        }
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color            : $white;
        background-color : #81c1b6;

        &:hover {
          background-color : transparent;
          border-color     : #81c1b6;
          color            : #81c1b6;
        }
      }

      .outils & {
        color            : $white;
        background-color : $univers-outils;

        &:hover {
          background-color : transparent;
          border-color     : $univers-outils;
          color            : $univers-outils;
        }
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color            : $white;
        background-color : $univers-enseignants;

        &:hover {
          background-color : transparent;
          border-color     : $univers-enseignants;
          color            : $univers-enseignants;
        }
      }
    }
  }

  .view-niveaux & {
    @include rem(margin, 25px 0);
  }
}

/***
More content FX
***/
.morecontent div {
  display : none;
}

.morelink {
  display        : block;
  @extend .montserrat-regular;
  text-transform : uppercase;
  font-size      : 13px;
  font-weight    : bold;
  color          : $vert-clair;
  cursor         : pointer;

  &:hover,
  &:focus {
    text-decoration : none;
    color           : $vert-clair;
  }

  .adolescents & {
    color : $univers-ado;
  }

  .#{$tid-grands-ados} &,
  .#{$grands-ados} & {
    color : $h2-niveaux;
  }

  .outils & {
    color : $univers-outils;
  }

  .#{$tid-formation} &,
  .#{$enseignants} & {
    color : $univers-enseignants;
  }

  .voir-tout & {
    display : none;
  }

  .field-name-field-argumentaire-collection & {
    &:nth-child(n+5) {
      display : none !important;
    }
  }
}

.moreellipses {
  .field-name-field-argumentaire-collection & {
    &:nth-child(n+5) {
      display : none !important;
    }
  }
}

.lesslink {
  display : none;

  .voir-tout & {
    display : block !important;

    &:nth-child(n+4) {
      display : none;
    }
  }
}

/***
Filtre recherche
***/
.filtre-recherche-view {
  display : inline-block;
  width   : 100%;
  clear   : both;
  @media #{$tablet} {
    @include rem(margin-top, -45px);
    @include rem(margin-bottom, 30px);
  }
  @media screen and (max-width : $screen-xs-max) {
    margin-top : 24px;
  }

  .view-header {
    @include rem(padding, 40px 18px 24px);
    @include rem(min-height, 200px);
    @include rem(margin-top, -30px);
    @include rem(margin-bottom, 65px);
    border-radius : 10px 0 10px 0;
    @media screen and (max-width : $screen-sm-max) {
      min-height    : initial;
      @include rem(padding, 0 0 0 18px);
      margin-top    : -10px;
      margin-bottom : 0;
    }
    @media screen and (max-width : $screen-xs-max) {
      margin-top : -65px;
    }

    .titre-facettes,
    .block-title,
    .facetapi-facetapi-checkbox-links li,
    .facetapi-facetapi-checkbox-links a,
    .facetapi-facetapi-checkbox-links span {
      color       : $white;
      @include rem(font-size, 14px);
      @include rem(line-height, 30px);
      font-weight : normal;
    }

    .titre-facettes {
      display        : inline-block;
      @include rem(font-size, 50px);
      @include rem(line-height, 50px);
      @include rem(padding-bottom, 16px);
      @extend .phenomena-regular;
      text-transform : uppercase;
      @media screen and (max-width : $screen-sm-max) {
        display : none;
      }
    }

    .toggle-facettes {
      width          : 100%;
      display        : inline-block;
      background     : url(../images/fleche-bas.png) no-repeat right 18px center;
      font-size      : 14px;
      @include rem(line-height, 50px);
      color          : $white;
      @extend .montserrat-regular;
      text-transform : uppercase;
      font-weight    : 700;
      cursor         : pointer;
      @media #{$normal} {
        display : none;
      }
    }

    .block-facetapi {
      @media screen and (max-width : $screen-sm-max) {
        display : none;
      }

      .block-title {
        width          : 75%;
        @include rem(padding-bottom, 7px);
        @include rem(margin-bottom, 7px);
        font-weight    : 700;
        text-transform : uppercase;
        @extend .montserrat-regular;
        border-bottom  : 1px solid $white;
      }
      .facetapi-facetapi-checkbox-links {
        padding : 0;
        ul {
          padding : 0;
        }
        li.expanded {
          > .facetapi-checkbox {
            display : none;
          }

          > a {
            font-weight : bold;
          }
          > input.facetapi-checkbox {
            display : none;
          }
        }
        input.facetapi-checkbox {
          margin-right : 6px;
          background   : none;
          background   : url(../images/fleche-bas.png) no-repeat center;
          border       : 1px solid $white;
        }
      }
    }
  }

  .view-content {
    @include rem(padding-top, 30px);
    @media screen and (max-width : $screen-sm-max) {
      padding : 0;
    }

    .view-content-inner {
      overflow : hidden;
      @include rem(padding-top, 20px);
      @media screen and (max-width : $screen-sm-max) {
        padding-left  : 0;
        padding-right : 0;
      }

      .node-hw-livre {
        margin : 0 auto;
      }

      .views-row {
        @include rem(padding-bottom, 36px);

        &.views-row-odd {
          @media screen and (max-width : $screen-xs-max) {
            padding-left : 0;
          }
        }

        &.views-row-even {
          @media screen and (max-width : $screen-xs-max) {
            padding-right : 0;
          }
        }
      }
    }
  }
}

/**********
Onglets
**********/

.onglet {
  @include rem(height, 63px);
  @include rem(line-height, 63px);
  display        : inline-block;
  vertical-align : middle;
  text-align     : center;
  width          : 16%;
  margin-left    : -1px;

  &.active,
  &:hover,
  &.selected {
    background : $bg-gris-livre;

    > a {
      color : $red;
    }
  }

  > a {
    display        : inline-block;
    vertical-align : middle;
    @include rem(font-size, 13px);
    @include rem(line-height, 16px);
    color          : $gris-base;
    font-weight    : bold;
    border         : 1px solid $border-gris;
  }
}

/**********
WYSIWYG
**********/
.page-node {
  .titre-rouge {
    @include rem(margin-top, 50px);
    @include rem(font-size, 35px);
    @extend .phenomena-regular;
    font-weight : 700;
    color       : $red;
  }

  .texte-rouge {
    color : $red;
  }

  .gros-chiffre {
    font-size : 72px;
    @extend .phenomena-bold;
    color     : $white;
  }

  .moyen-chiffre {
    font-size : 48px;
    @extend .phenomena-bold;
    color     : $white;
  }

  .bloc-rouge {
    width            : 32%;
    max-width        : 243px;
    min-height       : 243px;
    padding          : 50px 15px 25px;
    @include rem(margin-top, 40px);
    @include rem(margin-bottom, 40px);
    background-color : $red;
    text-align       : center;
    color            : $white;
    @media #{$tablet} {
      display        : inline-block;
      vertical-align : top;
      margin-left    : 0.8%;
      margin-right   : 0.8%;
    }
    @media screen and (max-width : $screen-xs-max) {
      width        : 100%;
      margin-left  : auto;
      margin-right : auto;
    }

    p, a {
      margin-top    : 0;
      margin-bottom : 15px;
      color         : $white;
      text-align    : center !important;
    }

    p:last-child {
      margin : 0;
    }

    span {
      margin  : 15px 0;
      display : inline-block;
    }
  }
}

/**********
BULLE CONTACT
**********/
.bulle-contact {
  @extend .none;
  z-index : 10;
}

.button-absolute {
  position : absolute;
  right    : 0 !important;
  bottom   : 80px;
}

.button-fixed {
  position : fixed;
  bottom   : 30px;
} 