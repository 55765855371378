.field-label-inline {
  .field-label,
  .field-items {
    float   : none;
    display : inline-block;
  }
}

.field-name-title-field {
  .view-mode-bloc_nouveautes & {
    display : inline-block;
    width   : 100%;
  }

  .node-hw_livre-full & {
    @media screen and (max-width : $screen-xs-max) {
      padding : 0 15px;
    }

    h1 {
      @extend .phenomena-bold;
      @include rem(font-size, 40px);
      @media #{$tablet} {
        margin-top : 0;
      }
      @media screen and (max-width : $screen-xs-max) {
        text-align : center;
      }

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }
    }
  }

  .node-distributeur-teaser & h3 {
    font-size : 16px;
    @extend .montserrat-bold;
    @include rem(padding, 0 0 12px);
    margin    : 0;
  }

  .node-hw_livre-ressources & {
    .field-items {
      background : $white;
      .field-item {
        @include rem(margin-bottom, 25px);
        //@include rem(padding, 0 $grid-gutter-width/2);
        text-align : center;
      }
    }
  }

  h2 {
    margin-top    : 0;
    margin-bottom : 0;

    .node-liste:not(.view-mode-recherche) & {
      a {
        color          : $red;
        text-transform : uppercase;
      }
    }
    .view-mode-ressources &,
    .view-mode-favoris &,
    .view-mode-bloc_nouveautes & {
      @include rem(margin-top, 10px);
      @include rem(margin-bottom, 25px);
      @include rem(padding, 0 $grid-gutter-width/2);
      text-align : center;
      color      : $gris-base;
      font-size  : 13px;
      @extend .montserrat-regular;

      a {
        color     : $gris-base;
        font-size : 13px;
      }
    }

    a {
      @extend .montserrat-regular;

      .node-edito-recherche & {
        .page-recherche &,
        .page-recherche-avancee & {
          color : $gris-contenu;
        }
      }
    }

    .view-mode-recherche & {
      line-height : 16px;
    }
  }

  .view-mode-recherche & {
    @extend .titre-recherche;
    @media screen and (max-width : $screen-xs-max) {
      width  : 90%;
      margin : 0 auto;
    }

    a {
      text-transform  : uppercase;
      text-decoration : none;
      @media screen and (max-width : $screen-xs-max) {
        display    : block;
        margin-top : 15px;
      }
    }
  }

  .node-article-recherche &,
  .node-hw_intervenant-recherche &,
  .node-hw_evenement-recherche & {
    @include rem(margin-bottom, 15px);
  }

  .node-hw_intervenant-recherche & {
    margin-top : 10px;
  }

  .node-article-recherche &,
  .node-hw_evenement-recherche &,
  .node-podcast-recherche &,
  .node-dossier-recherche{
    a {
      text-transform : initial;
    }
  }
}

.field-label {
  .field-name-hw-intervenants & {
    margin-right   : 5px;
    font-weight    : normal;
    text-transform : uppercase;
    font-size      : 13px;
    @media (max-width : $screen-xs-max) {
      float : none;
    }

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;
    }

    .outils & {
      color : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      color : $univers-enseignants;
    }

    .page-numerique & {
      color : $numerique;
    }

    .view-mode-recherche & {
      color          : $gris-recherche;
      @extend .montserrat-light;
      text-transform : inherit;
      @media (max-width : $screen-xs-max) {
        font-size : 10px;
      }
    }
  }
}

.field-name-hw-intervenants {
  .node-hw_livre-full &,
  .view-mode-recherche & {
    @include rem(margin, 11px 0);
    @media (max-width : $screen-xs-max) {
      padding    : 0 5px;
      text-align : center;
    }
  }

  .field-items {
    @media (max-width : $screen-xs-max) {
      float : none;
    }
  }

  .field-item {
    .node-hw_livre-full &,
    .view-mode-recherche & {
      display      : inline-block;
      margin-right : 5px;

      &:not(:last-child):after {
        content : ",";
      }
    }

    .view-mode-recherche & {
      &:not(:last-child):after {
        color : $gris-recherche;
      }
    }
  }

  a {
    @extend .montserrat-regular;
    font-size   : 13px;
    line-height : 13px;
    color       : $gris-contenu;

    .view-mode-recherche & {
      @extend .montserrat-light;
      color : $gris-recherche;
      @media (max-width : $screen-xs-max) {
        font-size : 10px;
      }
    }
  }
}

.field-name-hw-presentation-editoriale,
.field-name-hw-intervenant-biographie p {
  @include rem(margin-bottom, $grid-gutter-width/2);
  @extend .montserrat-regular;
  @include rem(font-size, 14px);
  @include rem(line-height, 20px);
  color      : $gris-contenu;
  text-align : justify;

  .view-mode-recherche & {
    @extend .montserrat-light;
    @media screen and (max-width : $screen-xs-max) {
      display : none;
    }
  }

  a {
    color          : $gris-contenu;
    -ms-word-break : break-all;
    word-break     : break-all;
  }
}

.field-type-taxonomy-term-reference,
.field-type-taxonomy-term-reference div {
  .node-liste:not(.view-mode-recherche) & {
    display : inline-block;
  }
}

.field-type-taxonomy-term-reference {
  .node-teaser & {
    @include rem(margin-top, 20px);
  }

  .field-item {
    .node-edito:not(.view-mode-recherche) &,
    .node-edito:not(.group-associes &) &,
    .node-liste:not(.view-mode-recherche) & {
      display    : inline-block;
      @include rem(padding, 7px 12px);
      @include rem(line-height, 16px);
      font-size  : 13px;
      //background : #f2f2f2;
      @media #{$tablet} {
        @include rem(margin-right, 20px);
      }

      a {
        color     : $gris-contenu;
        font-size : 13px;
      }

    }
  }
}

.field-name-node-link {
  display : inline-block;
  a {
    .node-liste & {
      @extend .petit-bouton;
    }
  }
}

.field-name-hw-evenement-date {

  .node-edito & {
    text-align     : center;
    text-transform : uppercase;
    @include rem(padding, 10px 0);

    .d {
      color : $red;
      @include rem(font-size, 40px);
      @include rem(line-height, 40px);
      @extend .phenomena-bold;
      @media screen and (max-width : $screen-xs-max) {
        font-size   : 47px;
        line-height : 47px;
      }
    }

    .m, .y {
      color       : $gris-base;
      @include rem(font-size, 14px);
      @include rem(line-height, 16px);
      @extend .montserrat-regular;
      font-weight : 700;
      @media screen and (max-width : $screen-xs-max) {
        font-size   : 16px;
        line-height : 16px;
      }
    }
  }

  .group-bloc-infos-node & {
    width      : 100%;
    text-align : center;
    @media #{$tablet} {
      width     : 9%;
      max-width : 80px;
    }
  }
}

.field-name-type-contenu {
  .node-liste:not(.view-mode-recherche) & {
    position : absolute;
    @include rem(top, -18px);
    right    : -5px;

    .field-item {
      @include rem(font-size, 16px);
      color          : $white;
      background     : $bleu-turquoise;
      @extend .phenomena-regular;
      text-transform : uppercase;
      text-align     : center;
      min-width      : 80px;
      padding        : 1px;
    }
  }
}

.field-name-type-contenu,
.field-name-hw-education-disciplines {
  .view-mode-recherche & {
    padding          : 5px 0;
    font-size        : 20px;
    @extend .phenomena-regular;
    font-weight      : 700;
    color            : $white;
    text-align       : center;
    background-color : $red;
    text-transform   : uppercase;
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 11px;
      //padding    : 0;
    }
  }
}

.field-name-field-image .field-item,
.field-name-hw-evenement-image .field-item {
  .node-edito-full:not(.node-article-full) & {
    @include rem(margin-top, 18px);

    .page-edito & {
      text-align : left;
    }

    img {
      .page-edito & {
        display : inline-block;
      }
    }
  }

  .node-hw_evenement-full & {
    text-align : center !important;
  }
}

.field-name-body {
  .node-faq-teaser & {
    display : none;
  }

  .node-distributeur-teaser &,
  .node-delegue_pedagogique-teaser & {
    @include rem(margin-bottom, 6px);
    font-size : 10px;

    p {
      margin : 0;
    }
  }

  .view-social-content & {
    @include rem(padding, 20px $grid-gutter-width $grid-gutter-width);

    p, a {
      margin      : 0;
      @extend .montserrat-light;
      font-size   : 14px;
      line-height : 20px;
    }

    a {
      color      : $gris-contenu;
      word-break : break-all;
    }
  }

  .node-page & {
    text-align : center;

    h2,
    p,
    ul {
      text-align  : left;
      line-height : 24px;
    }

    strong {
      @extend .montserrat-regular;
    }
  }
}

.field-name-hw-education-niveau {
  .view-mode-ressources &,
  .view-mode-favoris &,
  .view-mode-bloc_nouveautes & {
    @extend .montserrat-regular;
    font-size      : 13px;
    font-weight    : bold;
    color          : $white;
    text-transform : uppercase;
    text-align     : center;

    &.tid-7867 {
      background-color : $univers-ado;
    }

    &.tid-7877 {
      background-color : $univers-ado;
    }

    &.tid-7871,
    &.tid-7879 {
      background-color : $vert-clair;
    }

    &.tid-7872 {
      background-color : $univers-adulte;
    }

    &.tid-4 {
      background-color : $univers-outils;
    }

    &.#{$tid-formation},
    &.#{$enseignants} {
      background-color : $univers-enseignants;
    }
  }

  .view-mode-recherche & {
    @include rem(padding, 10px 0);
    text-align : center;
    @media screen and (max-width : $screen-sm-max) {
      padding : 5px 0 3px;
    }

    &.tid-7867 {
      background-color : $univers-ado;
    }

    &.tid-7877 {
      background-color : $univers-ado;
    }

    &.tid-7871,
    &.tid-7879 {
      background-color : $vert-clair;
    }

    &.tid-7872 {
      background-color : $univers-adulte;
    }

    &.tid-4 {
      background-color : $univers-outils;
    }

    &.#{$tid-formation},
    &.#{$enseignants} {
      background-color : $univers-enseignants;
    }

    a {
      @extend .montserrat-regular;
      font-size      : 13px;
      font-weight    : 700;
      color          : $white;
      text-transform : uppercase;
    }
  }
}

.field-name-retour-collection {
  @extend .link-to-before;
}

.field-name-node-link {
  @extend .bouton;

  a {
    font-size   : 13px;
    font-weight : bold;
    color       : $white;
  }

  .view-mode-ressources &,
  .view-mode-favoris &,
  .view-mode-bloc_nouveautes & {

    .front & {
      background-color : #71c4cf;
      border-color     : #71c4cf;
    }

    .#{$enfants} &,
    .enfants-et-preados & {
      background-color : $vert-clair;
      border-color     : $vert-clair;
    }

    .#{$ados} &,
    .adolescents & {
      background-color : $univers-ado;
      border-color     : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      background-color : $univers-adulte;
      border-color     : $univers-adulte;
    }

    .outils & {
      background-color : $univers-outils;
      border-color     : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      background-color : $univers-enseignants;
      border-color     : $univers-enseignants;
    }

    .page-numerique & {
      background-color : $numerique;
      border-color     : $numerique;
    }

    &:hover {
      background-color : $white;

      a {
        .front & {
          color : #71c4cf;
        }

        .#{$enfants} &,
        .enfants-et-preados & {
          color : $vert-clair;
        }

        .adolescents & {
          color : $univers-ado;
        }

        .#{$tid-grands-ados} &,
        .#{$grands-ados} & {
          color : $univers-adulte;
        }

        .outils & {
          color : $univers-outils;
        }

        .#{$tid-formation} &,
        .#{$enseignants} & {
          color : $univers-enseignants;
        }

        .page-numerique & {
          color : $numerique;
        }
      }
    }
  }
}

.group-fields-content {
  position  : relative;
  max-width : 256px;
  margin    : 0 auto;
}

.group-wrapper-avant-hover {
  .node:not(.node-edito-full):hover &,
  .hover-slide .group-fields-content:hover &,
  .views-row:hover &,
  .group-associes .view-mode-pour_les_niveaux:hover & {
    &:after {
      content  : '';
      display  : inline-block;
      width    : 100%;
      height   : 100%;
      position : absolute;
      top      : 0;
      bottom   : 0;
      left     : 0;
      right    : 0;
      //max-width : 257px;
      @include box-shadow(0 0 18px rgba(0, 0, 0, 0.45));
    }
  }

  .views-row & {
    .filtre-recherche-view & {
      &:hover & {
        &:after {
          content  : '';
          display  : inline-block;
          width    : 100%;
          height   : 100%;
          position : absolute;
          top      : 0;
          bottom   : 0;
          left     : 0;
          right    : 0;
          //max-width : 257px;
          @include box-shadow(0 0 18px rgba(0, 0, 0, 0.45));
        }
      }
    }
  }

  .hover-slide .group-fields-content:hover &,
  .views-row:hover &,
  .group-associes .view-mode-pour_les_niveaux:hover & {
    &:after {
      @extend .radius-10;
      opacity : 0.9;
    }
  }
}

.group-wrapper,
.group-wrapper-avant-hover,
.group-wrapper-simple {
  position : relative;

  .node:not(.node-edito-full) & {
    border           : 1px solid $border-gris;
    background-color : $white;
  }

  .node:not(.node-edito-full):hover & {
    &:after {
      background-color : $menu !important;
      border-radius    : 0;
    }
  }

  .hover-slide .group-fields-content:hover &,
  .views-row:hover &,
  .group-associes .view-mode-pour_les_niveaux:hover & {

    &:after {
      @extend .radius-10;
      opacity : 0.9;

      .#{$enfants} &,
      .enfants-et-preados & {
        background-color : $vert-clair;
      }

      .adolescents & {
        background-color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        background-color : $univers-adulte;
      }

      .outils & {
        background-color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        background-color : $univers-enseignants;
      }

      .filtre-recherche-view & {
        border-radius    : inherit;
        background-color : transparent;
      }
    }

  }

  .views-row:hover .view-mode-pour_les_niveaux:hover &:after {
    border-radius: 0;
    box-shadow: none;
  }

  &:after {
    .#{$enfants} &,
    .tid-7871 & {
      background-color : $vert-clair;
    }

    .tid-7867 & {
      background-color : $univers-ado;
    }

    .tid-7872 & {
      background-color : $univers-adulte;
    }

    .tid-4 & {
      background-color : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      background-color : $univers-enseignants;
    }
  }
}

.group-infos-hover {
  display    : none;
  text-align : center;

  .node:not(.node-edito-full):hover &,
  .views-row:hover &,
  .hover-slide .group-fields-content:hover & {
    display   : block;
    position  : absolute;
    left      : 0;
    right     : 0;
    top       : 50%;
    transform : translateY(-50%);

    /*.filtre-recherche-view & {
      position  : inherit;
      left      : inherit;
      right     : inherit;
      top       : inherit;
      transform : inherit;
      display   : none;
    }*/
  }

  .view-mode-bloc_nouveautes:hover & {
    display   : block !important;
    position  : absolute;
    left      : 0;
    right     : 0;
    top       : 50%;
    transform : translateY(-50%);
  }

  .group-associes .view-mode-pour_les_niveaux:hover & {
    display   : block;
    position  : absolute;
    left      : 0;
    right     : 0;
    top       : 50%;
    transform : translateY(-50%);
  }

  a:hover {
    text-decoration : none;
  }
}

.field-name-hw-education-classe {
  @include rem(margin-bottom, 24px);
  @extend .montserrat-regular;
  font-size  : 10px;
  text-align : center;
  @media (max-width : $screen-xs-max) {
    font-size : 8px;

    .node-hw_livre-full & {
      margin-bottom : 0;
      margin-top    : 20px;
    }
  }

  .view-mode-recherche & {
    margin-bottom : 0;

    @media #{$tablet} {
      text-align: center;
    }
  }

  .field-item {
    display        : inline-block;
    vertical-align : middle;
    @include rem(margin, 8px);
    text-align     : center;

    .node-hw_livre-ressources &,
    .node-hw_livre-bloc_nouveautes &,
    .node-hw_livre-favoris & {
      color            : $white;
      margin-top       : 5px;
      font-size: 14px;
    }

    .node-hw_livre-full &,
    .view-mode-recherche & {
      font-size: 14px;

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }
    }

    .view-mode-recherche & {
      color : $vert-clair;
    }
  }

  #user-register-form & {
    text-align : left;
  }
}

.field-name-hw-education-disciplines {
  .view-mode-ressources &,
  .view-mode-bloc_nouveautes &,
  .view-mode-favoris & {
    width          : 90%;
    margin-left    : auto;
    margin-right   : auto;
    @include rem(margin-bottom, 24px);
    @extend .montserrat-bold;
    @include rem(font-size, 17px);
    color          : $white;
    text-transform : uppercase;
  }
}

.field-name-hw-livre-picto-nouveaute {
  &:before {
    content : "Nouveauté";
    width   : 100%;
  }
}

.picto-nouveaute {
  width            : 100%;
  max-width        : 94px;
  @include rem(margin, 0 auto -10px);
  @extend .phenomena-bold;
  @include rem(padding, 3px 0 10px);
  font-size        : 15px;
  @include rem(letter-spacing, 0.45px);
  text-transform   : uppercase;
  color            : $white;
  background-color : $red;
  border-radius    : 25px;
  text-align       : center;

  .view-recherche &,
  .view-recherche-avancee & {
    position       : absolute;
    top            : -25px;
    left           : 0;
    right          : 0;
    border-radius  : 25px 25px 0 0;
    padding-bottom : 0;
  }
}

.field-name-hw-livre-couverture {
  .node-hw_livre-full & {
    max-width : 270px;
    margin    : 0 auto;
    padding   : 5px;
    border    : 1px solid $border-gris;
  }
}

.group-details-techniques {
  max-width        : 265px;
  margin           : 0 auto $grid-gutter-width/2 auto;
  padding          : 25px;
  border           : 1px solid;
  @extend .radius-10;
  background-color : $bg-gris;
  @extend .montserrat-regular;
  text-transform   : uppercase;
  font-size        : 13px;
  line-height      : 26px;
  color            : $gris-base;

  .#{$enfants} &,
  .enfants-et-preados & {
    border-color : $vert-clair;
  }

  .adolescents & {
    border-color : $univers-ado;
  }

  .#{$tid-grands-ados} &,
  .#{$grands-ados} & {
    border-color : $univers-adulte;
  }

  .outils & {
    border-color : $univers-outils;
  }

  .#{$tid-formation} &,
  .#{$enseignants} & {
    border-color : $univers-enseignants;
  }

  .page-numerique & {
    border-color : $numerique;
  }

  h3 {
    margin-top : 0;
    font-size  : 14px;

    span {
      font-size : 14px;

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }
    }
  }

  .field-label {
    font-weight : normal;
  }
}

.field-name-hw-livre-largeur,
.field-name-hw-livre-hauteur {
  display        : inline-block;
  vertical-align : top;
}

.field-name-hw-livre-hauteur {
  &:before {
    content : "-";
    display : inline-block;
    margin  : 0 5px;
  }

  .field-items {
    display : inline-block;
  }
}

.field-name-hw-livre-date-parution {
  span {
    font-size : 13px;
    color     : $gris-base;
  }
}

.field-name-partager {
  text-align : center;
  margin     : $grid-gutter-width 0 15px 0;
  clear: both;

  .node-hw_intervenant-full & {
    @media #{$tablet} {
      margin-left : 15px
    }
  }

  .node-edito-full & {
    width : 29%;
    @media (max-width : $screen-xs-max) {
      display : none;
    }
  }

  h2 {
    max-width      : 200px;
    margin         : 0 auto;
    padding        : 10px 10px 10px 17px;
    @extend .border;
    @extend .montserrat-regular;
    color          : $vert-clair;
    font-size      : 12px;
    text-transform : uppercase;
    cursor         : pointer;

    .#{$enfants} &,
    .enfants-et-preados & {
      color        : $vert-clair;
      border-color : $vert-clair;
    }

    .adolescents & {
      color        : $univers-ado;
      border-color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color        : $univers-adulte;
      border-color : $univers-adulte;
    }

    .outils & {
      color        : $univers-outils;
      border-color : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      color        : $univers-enseignants;
      border-color : $univers-enseignants;
    }

    .page-numerique & {
      color        : $numerique;
      border-color : $numerique;
    }

    &:before {
      margin-right : 6px;
      @include fontawesome-icon();
      content      : $share;
      font-size    : 16px;

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }

      .node-hw_intervenant-full &,
      .node-edito-full & {
        padding-right : 5px;
        color         : $red;
      }
    }

    &:hover {
      color : $white;

      .#{$enfants} &,
      .enfants-et-preados & {
        background-color : $vert-clair;
      }

      .adolescents & {
        background-color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        background-color : $univers-adulte;
      }

      .outils & {
        background-color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        background-color : $univers-enseignants;
      }

      .page-numerique & {
        background-color : $numerique;
      }

      .node-hw_intervenant-full &,
      .node-edito-full & {
        background-color : $red;
        color            : $white;
      }

      &:before {
        color : $white;
      }
    }

    .node-hw_intervenant-full &,
    .node-edito-full & {
      color        : $red;
      border-color : $red;
      @media #{$tablet} {
        margin : 0 auto;
      }
    }

    .node-edito-full & {
      @include rem(margin-bottom, 40px);
    }
  }
}

/**********
  FAVORIS
**********/
.flag-outer-favoris {
  text-align : center;
}

.flag-favoris {

  &.flag-wrapper {

    .view-mode-full & {
      @extend .bouton;
      max-width : 200px;
      width     : 100%;
      margin    : 0 auto;
      padding   : 7px 10px;

      .#{$enfants} &,
      .enfants-et-preados & {
        border-color : $vert-clair;
      }

      .adolescents & {
        border-color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        border-color : $univers-adulte;
      }

      .outils & {
        border-color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        border-color : $univers-enseignants;
      }

      .page-numerique & {
        border-color : $numerique;
      }

      &:hover {
        .#{$enfants} &,
        .enfants-et-preados & {
          background-color : $vert-clair;
        }

        .adolescents & {
          background-color : $univers-ado;
        }

        .#{$tid-grands-ados} &,
        .#{$grands-ados} & {
          background-color : $univers-adulte;
        }

        .outils & {
          background-color : $univers-outils;
        }

        .#{$tid-formation} &,
        .#{$enseignants} & {
          background-color : $univers-enseignants;
        }

        .page-numerique & {
          background-color : $numerique;
        }

        a {
          color : $white;

          &:before {
            color : $white;
          }
        }
      }
    }
    &.flag-wrapper.flag-favoris-anonymous {

      width      : 100%;
      display    : inline-block;
      max-width  : 100%;
      border     : none;
      padding    : 0;
      margin     : 0;
      text-align : left;

      &:hover {
        background : none;
      }

      .anonymous-login-inner {
        padding-top : 10px;
        display     : inline-block;

        .nav li {

          width : 100%;

          > a {

            @include rem(padding, 8px);
            @include rem(margin-bottom, 12px);
            @include rem(margin-left, 0);
            @include rem(margin-right, 0);
            width     : 100%;
            max-width : 220px;

            &:hover {
              color : $red
            }
          }
        }
      }
    }
  }

  .flag {
    font-size       : 12px;
    font-weight     : normal;
    text-decoration : none;

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;
    }

    .outils & {
      color : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      color : $univers-enseignants;
    }

    .page-numerique & {
      color : $numerique;
    }

    &:before {
      margin-right : 6px;
      @include fontawesome-icon();
      font-size    : 16px;

      .#{$enfants} &,
      .enfants-et-preados & {
        color : $vert-clair;
      }

      .adolescents & {
        color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        color : $univers-adulte;
      }

      .outils & {
        color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        color : $univers-enseignants;
      }

      .page-numerique & {
        color : $numerique;
      }
    }

    &.flag-action {
      &:before {
        content : $favoris;
        .flag-outer-anonymous:hover & {
          color : $white;
        }
      }
      .flag-outer-anonymous:hover & {
        color : $white;
      }
    }

    &.unflag-action {
      &:before {
        content : $favoris-out;
      }
    }
  }
}

.flag-outer-telechargement {
  .view-mode-full & {
    display  : block;
    padding  : 40px;
    position : relative;
    z-index  : 100;

    .page-node &,
    .page-taxonomy &,
    .node-hw_livre-ressources & {
      display : none;
    }
  }
}

/**********/

.field-name-hw-audio-contenu {
  margin-top : 10px;
  //@include rem(min-height, 100px);

  .acces-protege.nature-00193 & {
    display : none;
  }

  .jp-audio {
    width : 100%;

    .jp-type-single {
      .jp-interface {
        background : $vert-clair;
        height     : 45px;
        @extend .radius-20;
        border     : none;

        .jp-progress {
          background : $white;
          top        : 15px;
          left       : 50px;
          width      : 80%;
          height     : 14px;
        }

        .jp-seek-bar {
          background : $white;
        }

        .jp-volume-bar-value {
          background : $white;
        }

        .jp-controls {
          font-size : 0;

          li {
            display   : inline-block;
            font-size : 0;

            a {
              display : inline-block;

              &.jp-play,
              &.jp-play:hover,
              &.jp-pause,
              &.jp-pause:hover {
                background : url("../images/play.png") no-repeat;
                width      : 19px;
                height     : 19px;
                top        : 12px;
                left       : 12px;
              }

              &.jp-mute,
              &.jp-mute:hover,
              &.jp-unmute,
              &.jp-unmute:hover {
                background : url("../images/volume.png") no-repeat;
                width      : 19px;
                height     : 19px;
                top        : 12px;
                right      : 12px;
                left       : initial;
              }
            }
          }
        }
      }
    }
  }
}

/**********
Taxo collections
**********/
.collection-list-title {
  @include rem(margin-bottom, 25px);

  a {
    @extend .montserrat-bold;
    color          : $white !important;
    font-size      : 1.25rem !important;
    text-transform : uppercase !important;

    .field-name-hw-evenement-collections & {
      @extend .montserrat-bold;
    }
  }
}

.field-name-field-image-description {
  .view-mode-pour_les_collections & {
    @include rem(margin-bottom, 25px);
  }
}

.field-name-field-argumentaire-collection {
  @extend .montserrat-light;
  color       : $gris-contenu;
  line-height : 22px;
  text-align  : justify;

  .field-items {
    overflow : hidden;
  }
}

.voir-tout {
  .field-items {
    height   : auto !important;
    overflow : initial;
  }

  .moreellipses,
  .more-link {
    display : none !important;
  }

  .lesslink {
    display : block;
  }
}

/************
Profil
**************/

/* favoris */

.page-user {
  .flag-favoris {
    font-size  : 0;
    display    : block;
    text-align : center;

    .unflag-action {
      display     : inline-block;
      background  : url(../images/icone-fermer.png) no-repeat center;
      width       : 28px;
      height      : 28px;
      position    : relative;
      bottom      : -14px;
      text-indent : -9999px;
      z-index     : 10;
      @media screen and (max-width : $mobile) {
        width      : 21px;
        height     : 21px;
        background : url(../images/icone-fermer-mini.png) no-repeat center;
      }
    }
  }

  .field-name-icone .icone-favoris {
    display    : inline-block;
    background : url(../images/icone-favoris.png) no-repeat center;
    width      : 35px;
    height     : 35px;
    position   : absolute;
    @include rem(top, -7px);
    @include rem(right, 7px);
    z-index    : 10;
    @media screen and (max-width : $mobile) {
      width      : 28px;
      height     : 28px;
      background : url(../images/icone-favoris-mini.png) no-repeat center;
    }
  }
}

/**********
Recherche
**********/
.group-titre-auteur {
  display        : inline-block;
  vertical-align : top;
  width          : 80%;
  padding-top    : 15px;
  @media (max-width : $screen-xs-max) {
    width      : 100%;
    text-align : center;
  }
}

.field-name-hw-evenement-collections,
.field-name-hw-univers-collections {
  .node-edito-liste & {
    @include rem(margin-top, 15px);
    @media (max-width : $screen-xs-max) {
      text-align : center;
    }

    .field-label,
    a {
      color : $gris-recherche;
      @extend .montserrat-light;
      @include rem(font-size, 14px);
    }
  }
}

.group-infos-event {
  @media (max-width : $screen-xs-max) {
    text-align : center;
  }

  .field {
    display : inline-block;
    color   : $gris-recherche;
    @extend .montserrat-light;
    @include rem(font-size, 14px);
    &.field-name-field-pays-event {
      margin-right : 5px;
    }
  }
}

.field-name-field-ville {
  margin-right : 5px;

  .field-item {
    &:after {
      content : ",";
      display : inline-block;

      .group-details-pratiques & {
        display : none;
      }
    }
  }

  .group-details-pratiques & {
    margin       : 0 !important;
    padding-left : 5px !important;
  }
}

.field-name-field-heure {
  @media #{$tablet} {
    margin-left : 40px;
  }
  @media (max-width : $screen-xs-max) {
    width : 100%;
  }

  .group-details-pratiques & {
    background : url("../images/heure.png") no-repeat left;
  }
}

.field-name-hw-intervenant-biographie,
.field-name-field-chapo,
.field-name-hw-evenement-description {
  .view-mode-recherche & {
    @media (max-width : $screen-xs-max) {
      display : none;
    }
  }
}

.field-name-hw-evenement-description {
  .node-edito-full & {
    @include rem(margin-bottom, 54px);
    @media (max-width : $screen-xs-max) {
      margin-bottom : 25px;
    }

    p {
      @media #{$tablet} {
        font-size   : 16px;
        line-height : 24px;
      }
      @media (max-width : $screen-xs-max) {
        font-size   : 14px;
        line-height : 22px;
      }
    }
  }
}

.field-name-hw-evenement-image {
  .node-edito-full & {
    @include rem(margin-bottom, 54px);
    @media (max-width : $screen-xs-max) {
      margin-bottom : 25px;
    }
  }
}

.field-name-hw-intervenant-photos {
  @media (max-width : $screen-xs-max) {
    max-width : 200px;
    margin    : 0 auto;
  }
}

.field-name-field-image {
  .node-delegue_pedagogique-teaser & {
    display        : inline-block;
    vertical-align : top;
    @include rem(padding-right, $grid-gutter-width/2);
    width          : 30%;
  }

  .node-article-full &, .node-dossier-full &, .node-podcast-full & {
    @media #{$tablet} {
      width : 120%;
      margin-left: -10%;
      @include rem(padding-top, 25px);
      clear: both;
    }
    img {
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }
}

.group-texte {
  .node-delegue_pedagogique-teaser & {
    width : 70%;
    @media (max-width : $screen-xs-max) {
      text-align : left;
    }

    .field {
      display : block;
    }
  }

  .node-distributeur-teaser &,
  .node-delegue_pedagogique-teaser & {
    display        : inline-block;
    vertical-align : top;

    .group-zone {
      @include rem(padding-top, 6px);
    }
  }
}

.group-zone {
  @media (max-width : $screen-xs-max) {
    text-align : left;
  }

  .node-delegue_pedagogique-teaser & {
    @include rem(margin-top, 12px);
  }
}

.node-distributeur-teaser,
.node-delegue_pedagogique-teaser {
  .field-name-hw-intervenant-copyrights-photos {
    margin-top : 5px;
    font-size  : 12px;
    @extend .montserrat-light;
    color      : $gris-base;
    text-align : center;
  }

  .field, .field p, .field a {
    @extend .montserrat-light;
    font-size  : 13px;
    margin-top : 0;
    word-break : break-all;
  }

  .field {
    color : $gris-base;

    a {
      color : $gris-base;
    }

    &.field-name-envoyer-message a {
      color : $white;
      &:hover {
        color : $red;
      }
    }

    &.field-name-field-prenom,
    &.field-name-field-nom {
      display : inline-block;
      @extend .montserrat-bold;
      @include rem(padding, 12px 0);

      .field-items, .field-item {
        display : inline;
      }
    }

    &.field-name-field-nom {
      margin-left : 6px;
    }

    &.field-name-body {
      p {
        margin : 0;
      }
    }
    &.field-name-field-departements,
    &.field-name-field-pays {
      width : 100%;
    }
    &.field-name-field-departements .field-item {
      padding-right : 2px;
    }
    &.field-name-field-departements .field-item + .field-item {
      &:before {
        content       : "-";
        padding-right : 2px;
      }
    }
    &.field-name-field-pays .field-item {
      padding-right : 2px;
    }
    &.field-name-field-pays .field-item + .field-item {
      &:before {
        content       : "-";
        padding-right : 2px;
      }
    }
  }
}

.field-name-envoyer-message {

  .bouton {
    color            : $white;
    background-color : $red;
    border-color     : $red;

    &:hover {
      background : transparent;
      color      : $red;
      .glyphicon {
        background : url("../images/icone-contact_hover.png") no-repeat left center;
      }
    }
    .glyphicon {
      display    : inline-block;
      width      : 26px;
      height     : 11px;
      background : url("../images/icone-contact.png") no-repeat left center;
    }
    a {
      color : #ffffff;
    }
  }
}

.field-name-hw-intervenant-biographie {
  @include rem(margin, 15px 0 30px);

  @media #{$tablet} {
    padding-left : 15px;
  }

  p {
    @extend .montserrat-light;
    text-align : left;
    @media #{$tablet} {
      font-size   : 16px;
      line-height : 24px;
    }
    @media (max-width : $screen-xs-max) {
      font-size   : 14px;
      line-height : 22px;
    }

    .node-hw_intervenant-recherche & {
      @include rem(margin, 18px 0);
      color       : $gris-contenu;
      font-size   : 14px;
      line-height : 20px;
    }
  }
}

.group-bloc-infos-node {
  > .field,
  .field-group-div {
    @media #{$tablet} {
      display        : inline-block;
      vertical-align : top;
    }
  }
  .field-name-title {
    h2 {
      color: #414547;
      @include rem(font-size, 36px);
      @extend .montserrat-regular;
    }
  }
}

.field-name-field-chapo {
  .group-bloc-infos-node & {
    @media #{$tablet} {
      width : 71%;
    }

    .field-items {
      @media #{$tablet} {
        @include rem(margin-left, 20px);
        @include rem(padding-left, 20px);
        @include rem(padding-right, 30px);
        border-left : 1px solid #b7bbbd;

        .node-article-full & {
          border : none;
        }
      }
    }

    .field-item,
    p {
      margin : 0;
      @extend .montserrat-light;
      color  : $gris-contenu;
      @media #{$tablet} {
        @include rem(font-size, 22px);
        @include rem(line-height, 32px);
      }
      @media (max-width : $screen-xs-max) {
        margin-top  : 10px;
        font-size   : 16px;
        line-height : 20.48px;
        font-weight : 700;
      }
    }
  }

  .node-edito-recherche & {
    @include rem(margin, 18px 0);
    color       : $gris-contenu;
    @extend .montserrat-light;
    font-size   : 14px;
    line-height : 20px;
  }
}

.node-type-article, .node-type-hw-evenement, .node-type-videos, .node-type-dossier, .node-type-podcast {
  .field-name-field-chapo {
    @media #{$tablet} {
      width : 100%;
    }
    .field-items {
      @include rem(margin-left, 0px);
      @include rem(padding-left, 0px);
      border: none;

      .field-item {
        @include rem(font-size, 20px);
        @include rem(line-height, 28px);
      }
    }
  }
  .group-auteur {
    @include rem(margin-top, 40px);
    border-top: 1px solid #e7e7e7;
    @include rem(padding-top, 20px);
    @include rem(height, 65px);

    .field-name-field-auteur-photo {
      width: 10%;
      float: left;

      img {
        border-radius: 51px;
      }
    }
    .field-name-field-auteur-titre {
      float: right;
      text-align: left;
      width: 85%;
      @extend .montserrat-bold;
      @include rem(font-size, 16px);
      color: #626667;
    }
    .field-name-field-auteur-sous-titre {
      float: right;
      width: 85%;
      @extend .montserrat-regular;
      @include rem(font-size, 14px);
      @include rem(line-height, 28px);
      color: #9ea4a8;
    }
    .col-sm-3, .col-sm-5 {
      @extend .montserrat-regular;
      @include rem(font-size, 14px);
      color: #9ea4a8;
      float: right;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
    }
  }
  .node-edito-full .rrssb {
    float: right;
    width: 10%;
    @media (max-width : $screen-sm-min - 1) {
      float: inherit;
      width: inherit;
      text-align: center;
    }

    li {
      display: block;
      @media (max-width : $screen-sm-min - 1) {
        display: inline-block;
      }
    }

    a {
      background-color: inherit !important;
    }
    .rrssb-icon {
      width: 38px;
      height: 38px;
    }
    .rrssb-linkedin .rrssb-icon {
      background : url("../images/linkedin-share.png");
    }
    .rrssb-linkedin .rrssb-icon:hover {
      background : url("../images/linkedin-share-hover.png");
    }
    .rrssb-facebook .rrssb-icon {
      background : url("../images/facebook-share.png");
    }
    .rrssb-facebook .rrssb-icon:hover {
      background : url("../images/facebook-share-hover.png");
    }
    .rrssb-twitter .rrssb-icon {
      background : url("../images/twitter-share.png");
    }
    .rrssb-twitter .rrssb-icon:hover {
      background : url("../images/twitter-share-hover.png");
    }
    .rrssb-email .rrssb-icon {
      background : url("../images/mail-share.png");
    }
    .rrssb-email .rrssb-icon:hover {
      background : url("../images/mail-share-hover.png");
    }
  }
  .field-name-body, .field-name-hw-evenement-description {
    float: left;
    width: 90%;
    @media (max-width : $screen-sm-min - 1) {
      float: inherit;
      width: inherit;
    }
  }
  .field-name-field-url-video {
    width: 100%;
    padding-top: 1.5625rem;
    clear: left;
    text-align: right;
    display: inline-block;

    iframe {
      width: 100%;
      margin-left: -10%;
      height: 515px;
    }
  }
}

.group-details-pratiques {
  clear: both;

  @media (max-width : $screen-xs-max) {
    max-width : 182px;
    width     : 100%;
    margin    : 25px auto 35px;
  }

  h2 {
    margin-bottom : 16px;
  }

  .field {
    margin       : 6px 0;
    padding-left : 30px;
    @extend .montserrat-regular;
    font-size    : 12px;
    color        : $gris-base;
  }

  .field-name-field-pays-event,
  .field-name-field-code-postal-event,
  .field-name-field-ville {
    display : inline-block;
  }
}

.field-name-field-code-postal-event {
  .group-details-pratiques & {
    margin  : 0;
    padding : 0;

    &:before {
      content : "-";
      margin  : 0 5px;
      display : inline-block;
    }

    .field-items {
      display : inline-block;
    }
  }
}

.group-details-pratiques {
  clear: both;
  @include rem(padding-top, 20px);
  @include rem(padding-bottom, 20px);
  @include rem(margin-top, 25px);
  border: 1px solid #f3f3f3;
  box-shadow: 1px 1px 1px 1px #f3f3f3;

  .col-sm-2 {
    border-right: 1px solid #e7e7e7;
    padding: 0;
    @include rem(padding-left, 10px);
  }

  .field-name-hw-evenement-date {
    color: #ea6a6a;
    padding: 0;
    text-align: left;

    .prefix-date {
      @include rem(font-size, 10px);
    }
    .date-day {
      @include rem(font-size, 30px);
      font-weight: bold;
    }
    .date-month, .date-year {
      @include rem(font-size, 14px);
    }
  }
}

.field-name-field-pays-event {
  .group-details-pratiques & {
    background : url("../images/lieu.png") no-repeat left;
  }
}

.field-name-field-info-comp {
  .group-details-pratiques & {
    //float: right;
    background : url("../images/info-lieu.png") no-repeat left;
  }
}

.group-prog-inscription {
  @include rem(margin-bottom, 50px);
  text-align : center;

  .field {
    display : inline-block;
    @include rem(margin, 7px 10px);
  }
}

.field-name-field-pays {
  @media screen and (max-width : $screen-xs-max) {
    width   : 100%;
    padding : 0 $grid-gutter-width/2;
  }
}

.field-name-field-telechargement-programme,
.field-name-hw-fichier-contenu {
  .file-icon {
    display : none;
  }

  a {
    @extend .bouton;
    min-width    : inherit;
    border-color : $red;
    color        : $red;

    &:hover {
      color            : $white;
      background-color : $red;
    }
  }
}

.field-name-field-rgpd {
  border  : thin solid $red;
  padding : $grid-gutter-width/2;

  #user-profile-form &,
  .help-block {
    display : none !important;
  }

  .control-label {
    font-size : 13px;
  }

}

.date-no-float {
  margin-right : 0;
}

.field-name-hw-url-contenu {
  .extrait-audio & {
    display : none;
  }

  .node-edito-full & {
    a {
      @extend .bouton;
      border-color     : $red;
      color            : $white;
      background-color : $red;

      &:hover {
        color            : $red;
        background-color : transparent;
      }
    }
  }

  a {
    .nature-00256 & {
      background : url(../images/icone-od.png) no-repeat center !important;
    }

    .nature-00255 & {
      background      : url(../images/picto_kdo.png) no-repeat center !important;
      background-size : cover !important;
    }

    .nature-00256 & {
      background : url(../images/icone-lien.png) no-repeat center !important;
    }
  }
}

.group-associes {
  clear : both;

  .field-name-hw-evenement-collections {
    .field-label {
      margin-top: 0;
    }
  }

  .field-label {
    @include rem(margin-top, 70px);
    @include rem(margin-bottom, $grid-gutter-width);
    @extend .montserrat-bold;
    @include rem(font-size, 25px);
    @include rem(line-height, 40px);
    font-weight : bold;
    color       : #fb6065;
    text-align: center;
    @media (max-width : $screen-xs-max) {
      text-align : center;
    }
  }

  > .field {
    margin : 0 0 $grid-gutter-width;
    @media #{$tablet} {
      display        : inline-block;
      vertical-align : top;
      width          : 100%;
    }
  }

  .view-mode-pour_les_niveaux {
    max-width : 260px;
    @media (max-width : $screen-xs-max) {
      max-width : 198px;
      margin    : 0 auto;
    }
  }
}

.field-name-hw-evenement-livres {
  .node-edito-full & {
    @media (max-width : $screen-xs-max) {
      max-width    : 290px;
      margin-left  : auto;
      margin-right : auto;
    }
  }
}

.field-name-field-visuel-collection {
  img {
    @extend .radius-10;
    .view-mode-pour_les_niveaux & {
      border-radius: 0;
    }
  }
}

.field-name-field-type-collection {
  .field-name-hw-evenement-collections &,
  .field-name-hw-univers-collections & {
    display : none;
  }
}

/*************
Contenus complémentaires
**************/

.entity-paragraphs-item {
  &.view-mode-full {
    position   : relative;
    font-size  : 0;
    @include rem(margin, 15px 0);
    @include rem(padding, 15px 15px 15px 10px);
    background : $white;
    @extend .radius-20;

    &.nature-00073,
    &.nature-00138 {
      .group-media {
        a {
          background-image : url(../images/icone-play.png) !important;
          background-size  : 50px !important;
        }
      }
    }

    &.nature-00162 {
      .group-media {
        a {
          background-image : url(../images/icon-link.png) !important;
        }
      }
    }

    .extraits-commentes & {
      .#{$enfants} &,
      .enfants-et-preados & {
        background-color : $vert-clair;
      }

      .adolescents & {
        background-color : $univers-ado;
      }

      .#{$tid-grands-ados} &,
      .#{$grands-ados} & {
        background-color : $univers-adulte;
      }

      .outils & {
        background-color : $univers-outils;
      }

      .#{$tid-formation} &,
      .#{$enseignants} & {
        background-color : $univers-enseignants;
      }

      .page-numerique & {
        background-color : $numerique;
      }
    }

    .content {
      display        : inline-block;
      vertical-align : middle;
      width          : 100%;
      font-size      : 0;

      > .field-group-div {
        font-size      : 14px;
        line-height    : 16px;
        display        : inline-block;
        vertical-align : middle;

        .extrait-audio & {
          display : block;
        }

        .field-name-hw-url-contenu,
        .field-name-hw-fichier-contenu,
        .field-name-hw-video-contenu {
          .field-item {
            .file-icon {
              display : none;
            }

            a {
              background  : url(../images/icone-media.png) no-repeat center;
              text-indent : -9999px;
              display     : inline-block;
              @include rem(width, 70px);
              @include rem(height, 70px);
              @extend .radius-40;
              cursor      : pointer;

              .#{$enfants} &,
              .enfants-et-preados & {
                background-color : $vert-clair;
              }

              .adolescents & {
                background-color : $univers-ado;
              }

              .#{$tid-grands-ados} &,
              .#{$grands-ados} & {
                background-color : $univers-adulte;
              }

              .outils & {
                background-color : $univers-outils;
              }

              .#{$tid-formation} &,
              .#{$enseignants} & {
                background-color : $univers-enseignants;
              }

              .page-numerique & {
                background-color : $numerique;
              }
            }
          }
        }

        .extraits &,
        .ressources &,
        .extrait-video &,
        .extraits-commentes & {
          &.group-media {
            float : left;
            @include rem(padding-right, 15px);
            @media #{$tablet} {
              width : 17%;
            }
            @media screen and (max-width : $screen-xs-max) {
              max-width : 65px;
            }
          }

          &.group-texte {
            display        : inline-block;
            vertical-align : middle;
            @extend .montserrat-regular;
            color          : $gris-base;
            @media #{$tablet} {
              width : 83%;
              @include rem(margin-top, 20px);
            }
            @media screen and (max-width : $screen-xs-max) {
              width      : 76%;
              margin-top : 8px;
            }
          }

          .field-name-hw-url-contenu {
            .field-item {
              > a {
                background : url(../images/icone-pdf.png) no-repeat center;
                @media screen and (max-width : $screen-xs-max) {
                  background-size : 58%;
                }

                .#{$enfants} &,
                .enfants-et-preados & {
                  background-color : $vert-clair;
                }

                .adolescents & {
                  background-color : $univers-ado;
                }

                .#{$tid-grands-ados} &,
                .#{$grands-ados} & {
                  background-color : $univers-adulte;
                }

                .outils & {
                  background-color : $univers-outils;
                }

                .#{$tid-formation} &,
                .#{$enseignants} & {
                  background-color : $univers-enseignants;
                }

                .page-numerique & {
                  background-color : $numerique;
                }
              }
            }
          }
        }

        .extraits-commentes & {
          &.group-media {
            float : left;
            @include rem(padding-right, 15px);

          }

          &.group-texte {
            width          : 72%;
            display        : inline-block;
            vertical-align : middle;
            @extend .montserrat-regular;
            color          : $gris-base;
          }
        }
      }
    }

    .group-texte {
      .gratuit & {
        @media #{$tablet} {
          width : 70% !important;
        }
      }
    }

    .field-name-hw-desc-contenu {
      font-size: 14px;
    }

  }
}

.view-mode-inclus .field-name-hw-natcon,
.field-name-hw-titre-contenu {
  .paragraphs-item-contenus-complementaires.view-mode-full & {
    font-weight : 700;

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $univers-adulte;
    }

    .outils & {
      color : $univers-outils;
    }

    .#{$tid-formation} &,
    .#{$enseignants} & {
      color : $univers-enseignants;
    }

    .page-numerique & {
      color : $numerique;
    }

    .extraits-commentes & {
      color : $white;
    }
  }
  .view-mode-inclus_dans_ouvrage & {
    @extend .montserrat-regular;
    font-size : 11px;
    color     : $gris-base;
    @media screen and (max-width : $screen-sm-max) {
      font-size : 12px;
    }
  }
}

.field-name-hw-titre-contenu,
.field-name-hw-natcon {
  .view-mode-inclus &,
  .view-mode-inclus_dans_ouvrage & {
    @extend .montserrat-regular;
    font-size : 10px;
    color     : $gris-base;
    @media screen and (max-width : $screen-sm-max) {
      font-size : 12px;
    }
  }
}

.field-name-extension {
  text-transform : uppercase;
}

.group-gratuit-protege {
  width : 13%;
  @media screen and (max-width : $screen-xs-max) {
    display : none !important;
  }

  .field {
    display        : inline-block;
    vertical-align : middle;
    margin-left    : 12%;
  }
}

.field-name-hw-contenu-gratuit {
  width : 13%;

  .gratuit & {
    width      : 18px;
    height     : 18px;
    background : url("../images/icone-gratuit.png") no-repeat center;
  }
}

.field-name-hw-acces-protege {
  .gratuit & {
    width      : 18px;
    height     : 18px;
    background : url("../images/cadena.png") no-repeat center;
  }
}

.group-inclus-dans-ouvrage {
  @include rem(margin, $grid-gutter-width/2 0 10px);
  text-align : center;
  border-top : thin solid $gris-base;

  h3 {
    display : none;

    /*&:before,
    &:after {
      content          : '';
      display          : inline-block;
      vertical-align   : middle;
      height           : 1px;
      width            : 30%;
      background-color : $gris-base;
      @media screen and (max-width : $screen-sm-max) {
        width : 24%;
      }
      @media screen and (max-width : $screen-xs-max) {
        display : none;
      }
    }

    &:before {
      margin-right : 2%;
    }

    &:after {
      margin-left : 2%;
    }*/
  }
}

.views-field-hw-url-contenu {
  .feuilletage & {
    margin     : $grid-gutter-width/2 0;
    text-align : center;

    a {
      width            : 100%;
      max-width        : 257px;
      color            : $white;
      @extend .montserrat-regular;
      @include rem(font-size, 15px);
      text-transform   : uppercase;
      text-decoration  : none;
      @extend .bouton;
      background-color : $red;

      &:hover {
        color            : $red;
        background-color : transparent;

        .icone {
          background      : url("../images/picto_feuilleter-red.png") no-repeat left;
          background-size : contain;
        }
      }

      .icone {
        @include rem(width, 26px);
        @include rem(height, 23px);
        display         : inline-block;
        vertical-align  : middle;
        @include rem(margin-right, 15px);
        background      : url("../images/picto_feuilleter.png") no-repeat left;
        background-size : contain;
      }
    }
  }
}

.login-block {
  margin-left : 20px;
}

.field-name-field-updated {
  width : 100%;
}
