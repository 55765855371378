/**********
  TRANSVERSAL
**********/
.bloc-standard {
  .block-title {
    @extend .montserrat-regular;
    @include rem(font-size, 35px);
    @include rem(line-height, 33px);
    font-weight : bold;

    .node-type-hw-livre & {
      color : $vert-clair;
    }

    .#{$enfants} &,
    .enfants-et-preados & {
      color : $vert-clair;
    }

    .#{$tid-grands-ados} &,
    .#{$grands-ados} & {
      color : $h2-niveaux;
    }

    .adolescents & {
      color : $univers-ado;
    }

    .page-outils {
      color : $red;
    }

    .#{$tid-formation} & {
      color : $univers-enseignants;
    }
  }

  .block-subtitle {
    @extend .montserrat-bold;
    text-transform : uppercase;
    @include rem(font-size, 14px);
    @include rem(line-height, 20px);
    @include rem(margin-top, 10px);
  }

  .list-title {
    margin : 0;
  }

  .content {
    color : $gris-contenu;
    @extend .montserrat-light;
    @include rem(font-size, 14px);
    @include rem(line-height, 20px);

    ul {
      padding : 0;
      margin  : 0;

      li {
        @include rem(line-height, 20px);
        @include rem(padding, 2px 0);
        list-style-image : none;
        list-style-type  : none;
        vertical-align   : middle;
      }

      li:before {
        content        : "\2022";
        @include rem(font-size, 48px);
        @include rem(line-height, 20px);
        vertical-align : middle;
        float          : left;
        margin-right   : 6px;
        margin-top     : -4px;
      }

      &.block-views li:before {
        content : "";
      }
    }

    p {
      color : $gris-contenu;
      @include rem(font-size, 14px);
      @include rem(line-height, 20px);
    }

    .bouton-wrapper {
      text-align : center;
    }
  }
}

.bloc-num {
  ul {
    padding-left : 1.125rem !important;
    @include rem(font-size, 19px);

    /*li {
      list-style : initial !important;
    }*/
  }
}

.bloc-edito {
  @include rem(margin, 15px auto 0);
  float     : none;
  @media screen and (max-width : $screen-xs-max) {
    text-align : center;
  }

  .block-title {
    color : $gris-base;
    @include rem(margin-bottom, 16px);
  }

  /*.views-row {
    border : 1px solid #e7e7e7;
    @include rem(padding, 18px 20px 28px 20px);
    @include rem(margin-bottom, 24px);
  }*/

}

.view.bloc-edito, .view-actualites.view-display-id-block_5, .view-display-id-block_6{
  td {
    @include rem(padding-left, 15px);
    @include rem(padding-right, 15px);
  }
  .field-name-type-edito {
    @include rem(margin-top, 20px);
    @include rem(margin-bottom, 10px);
    @include rem(font-size, 18px);
    color: #ea6a6a;
    @extend .phenomena-bold;
    background : url("../images/actu-logo.png") no-repeat 0;
    padding-left: 25px;
    a {
      color: inherit;
      font-size: inherit;
    }
  }
  .node-videos {
    .field-name-type-edito {
      background : url("../images/video-small.png") no-repeat 0;
    }
  }
  .node-dossier {
    .field-name-type-edito {
      background : url("../images/dossier-small.png") no-repeat 0;
    }
  }
  .node-podcast {
    .field-name-type-edito {
      background : url("../images/podcast-small.png") no-repeat 0;
    }
  }
  .node-hw-evenement {
    .field-name-type-edito {
      background : url("../images/evenement-small.png") no-repeat 0;
    }
  }
  .field-name-title-field {
    @include rem(min-height, 120px);
    word-wrap: break-word;
    max-width: 350px;
  }
  .field-name-title-field {
    a {
      color: #404547;
      @include rem(font-size, 20px);
      @extend .montserrat-regular;
    }
    h3 {
      margin-top: 0;
    }
  }
}

#block-views-actualites-block-5,
#block-views-dossiers-block-3,
#block-views-podcasts-block-3,
#block-views-videos-block-3,
#block-views-evenements-block-4 {
  .block-title {
    color: #fb6065;
    text-align: center;
    @include rem(margin-top, 45px);
    clear: both;
    font-size: 25px;
  }
}

/**********
  HEADER
**********/
#block-menu-menu-menu-secondaire,
#block-views-exp-recherche-page {
  display        : inline-block;
  vertical-align : middle;
}

#block-menu-menu-menu-secondaire {
  width : 100%;
  @media #{$tablet} {
    padding-right : 4%;
  }
  @media (max-width : $screen-xs-max) {
    width   : 100%;
    padding : 5px 40px;

    &.width-0 {
      .content {
        display : none;
      }
    }
  }
}

#block-views-exp-recherche-page {
  text-align  : left;
  position: absolute;
  z-index: 1000;
  background: white;
  width: 100%;
  top: 181px;
  left: 0;
  right: 0;
  height: 300px;
  transition: all 0.5s ease 0s;

  .content {
    width: 40%;
    @include rem(padding, 125px 0);
    margin: 0 auto;

    form {
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 10px;
    }
  }
  @media (max-width : $screen-xs-max) {
    width            : 18%;
    margin           : 0 !important;
    padding          : 0;
    position         : absolute;
    top              : 50%;
    transform        : translateY(-50%);
    right            : 0;
    border           : none;
    z-index          : 10;
    text-align       : right;
    background-color : $red;


    width: 100vw;
    background: white;
    position: absolute;
    top: 162px;
    height: 128px;

  }

  .views-widget {
    position : absolute;
    @include rem(right, -285px);
    @include rem(top, -3px);
    width    : 55%;
    @media (max-width : $screen-xs-max) {
      width : 82%;
    }
  }

  &.width-90 {
    .views-widget {
      @include rem(right, 70px);
      @media (max-width : $screen-xs-max) {
        top : -16px;
        @include rem(right, 56px);
      }
    }
  }

  .views-exposed-widgets {
    margin : 0;
  }

  .views-submit-button {
    @include rem(right, -25px);
  }

  .description {
    position : absolute;
    @include rem(right, -55px);
    top      : 5px;
  }

  &:after {
    content    : "";
    width      : 1px;
    @include rem(height, 40px);
    background : #acb6bb;
    position   : absolute;
    right      : 0;
    top        : 0;
    @media (max-width : $screen-xs-max) {
      display : none;
    }
  }
}

#block-views-exp-recherche-page {
  transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

#block-views-exp-recherche-page.active {
  visibility: visible;
  height: auto;
}

#edit-search-api-views-fulltext-wrapper {
  cursor     : pointer;
  @extend .montserrat-regular;
  font-size  : 0;
  text-align : center;
  @media (max-width : $screen-xs-max) {
    text-align : right;
  }

  .width-90 & {
    text-align : left;
  }

  label {
    padding    : 5px 0 5px 40px;
    margin     : 0;
    background : url("../images/search-red.png") no-repeat left;
    text-align : right;
    color      : $red;
    font-size  : 13px;
    cursor     : pointer;
    @media (max-width : $screen-xs-max) {
      background      : url("../images/search-blanc.png") no-repeat center;
      background-size : contain;
      font-size       : 0;
      width           : 25px;
      height          : 25px;
    }
  }

  .description {
    color  : $red;
    @include rem(font-size, 18px);
    cursor : pointer;
    @media (max-width : $screen-xs-max) {
      color : $white;
    }
  }
}

.block-title-wrapper {
  .bloc-livres & {
    @include rem(margin-bottom, 10px);
    @extend .montserrat-bold;
    text-align     : center;
    text-transform : uppercase;

    p {
      color : $red;
      @include rem(font-size, 35px);
    }
  }

  #block-views-niveaux-block-2 &,
  .node-type-hw-livre #block-views-livres-block-1 & {
    display : none;
  }

  #block-views-exp-recherche-page & {
    @extend .montserrat-regular;

    p {
      color     : $red;
      font-size : 13px;
    }
  }

  #block-menu-menu-menu-2-home-page & {
    @include rem(max-width, 577px);
    @include rem(margin, 0 auto 54px);
    @media (max-width : $screen-xs-max) {
      margin-bottom : 30px;
    }
  }

  #block-block-11 & {
    max-width : 290px;
  }

  #block-block-12 & {
    max-width : 250px;
  }

  .blocs-support-num & {
    margin : 0 auto;
  }

  .extrait & {
    margin     : 15px 0 25px;
    text-align : center;
  }
}

/**********
  BLOC ESPACE PERSO
**********/

#block-fle-profil-teaser-espace-perso {
  @media #{$tablet} {
    margin-left : 1%;
  }

  .block-title-wrapper {
    @media #{$tablet} {
      width : 70%;
    }
  }

  .block-title {
    color : $red;
  }
  .content {
    img {
      float      : right;
      margin-top : -50px;
      @media screen and (max-width : $screen-xs-max) {
        display : none;
      }
    }

    .bouton {
      background  : $red;
      color       : $white;
      font-weight : 700;

      &:hover {
        background   : transparent;
        color        : $red;
        border-color : $red;
      }
    }
    ul {
      color : $red;
      @include rem(margin, 0 0 8px 0);
      @media screen and (max-width : $screen-xs-max) {
        @include rem(margin, 14px 0 20px 0);
      }
    }
  }
}

/**********
  BLOC UNIVERS NUMERIQUE
**********/

#block-block-3 {
  @include rem(margin-bottom, 22px);
  @media #{$tablet} {
    margin-right : 1%;
  }
  @media screen and (max-width : $screen-xs-max) {
    text-align    : left;
    @include rem(padding-bottom, 32px);
    border-bottom : 1px solid $gris-classique;
  }

  .block-title {
    color     : $numerique;
    max-width : 90%;
    @media screen and (max-width : $screen-xs-max) {
      max-width : 100%;
    }
  }

  .content {
    .bouton-wrapper {
      @media #{$tablet} {
        display        : inline-block;
        vertical-align : top;
        width          : 45%;
      }
    }

    .img-wrapper {
      display : none;
      @media #{$tablet} {
        display    : inline-block;
        width      : 55%;
        margin-top : -30px;
      }
    }

    .bouton {
      background  : $numerique;
      color       : $white;
      font-weight : 700;

      &:hover {
        background   : transparent;
        color        : $numerique;
        border-color : $numerique;
      }
    }
  }

  ul {
    color : $numerique;
    @include rem(margin, 14px 0 20px 0);
  }
}

#block-fle-profil-teaser-espace-perso,
#block-block-3 {
  @media #{$tablet} {
    width  : 49%;
    @include rem(padding, 20px);
    border : 1px solid #e7e7e7;
  }
  @media screen and (max-width : $screen-xs-max) {
    @include rem(margin-bottom, 40px);
    display : inline-block;
  }

  .block-title {
    font-weight : bold;
  }
}

/**********
  BLOC AGENDA HOME
**********/

#block-views-evenements-block,
#block-views-actualites-block-1 {
  clear : both;
}

#block-fle-profil-agenda-perso {
  /*margin-top : 0;*/
  @media screen and (max-width : $mobile) {
    @include rem(margin-top, 30px);
  }
  .block-title-wrapper {
    display        : inline-block;
    vertical-align : middle;
    line-height    : 40px;
    min-height     : 40px;
    background     : url(../images/icone-agenda.png) no-repeat left top;

    @media screen and (max-width : $mobile) {
      max-width : 90%;
      margin    : 0 auto 7px;
    }
    .block-title {
      @extend .phenomena-regular;
      color          : $red;
      @include rem(font-size, 30px);
      @include rem(line-height, 30px);
      display        : inline-block;
      vertical-align : middle;
      padding-left   : 60px;
    }
  }
}

/**********
LIVRES
**********/
.bloc-livres {
  @include rem(padding, 30px 0);
}

#block-views-a36c633d1c6390c483e194b2e87f6159 .view-contenus-complementaires .views-row {
  cursor : pointer;
}

/**********
  BLOC HEADER (COLLECTION & NIVEAU & LIVRE & PROFIL)
**********/

//#block-fle-bandeaux-header-livre,
//#block-fle-bandeaux-header-collection,
.block-fle-bandeaux {
  .content {
    font-size : 0;

    #intro-collection,
    .field-name-field-image-header {
      display        : inline-block;
      vertical-align : middle;
    }

    #intro-collection {
      @include rem(height, auto);
      width   : 25%;
      padding : 15px 0;
      @extend .phenomena-regular;
      color   : #ffffff;
      @media screen and (max-width : $screen-sm-max) {
        width      : 100%;
        //@include rem(height, 181px);
        //@include rem(line-height, 181px);
        text-align : center;
      }
      @media screen and (max-width : $screen-xs-max) {
        height      : inherit;
        line-height : initial;
      }

      #intro-collection-inner {
        display        : inline-block;
        vertical-align : middle;
        @include rem(line-height, 25px);

        #surtitre-collection,
        #titre-collection {
          @include rem(font-size, 50px);
          @include rem(line-height, 50px);
          font-weight : 700;
        }

        #type-collection {
          @include rem(padding-bottom, 8px);
          @include rem(font-size, 30px);
          @include rem(line-height, 25px);

          .#{$tid-formation} & {
            display : none;
          }
        }

        #titre-collection {
          display : none;
          @media screen and (max-width : $screen-sm-max) {
            display : inline-block;
          }
        }

        #surtitre-collection {
          @media screen and (max-width : $screen-sm-max) {
            @include rem(font-size, 30px);
          }
        }

        .title-separator {
          background     : url("../images/title-separator.png") no-repeat center;
          @include rem(line-height, 20px);
          @include rem(height, 20px);
          @include rem(margin-top, 5px);
          vertical-align : middle;
        }
      }
    }

    .field-name-field-image-header {
      width : 75%;
      @media screen and (max-width : $screen-sm-max) {
        display : none;
      }
    }
  }
}

#block-fle-bandeaux-header-profil {
  .content {
    font-size : 0;
    #intro-profil {
      display        : inline-block;
      vertical-align : middle;
      width          : 100%;
      @include rem(height, 181px);
      @include rem(line-height, 181px);
      text-align     : center;
      @extend .phenomena-regular;
      color          : #ffffff;

      #intro-profil-inner {
        display        : inline-block;
        vertical-align : middle;
        @include rem(line-height, 25px);

        #titre-profil {
          @include rem(font-size, 30px);
          @include rem(line-height, 30px);
          text-transform : uppercase;
        }
        #soustitre-profil {
          @include rem(padding-bottom, 8px);
          @include rem(font-size, 50px);
          @include rem(line-height, 50px);
          font-weight : 700;
        }
      }
    }
  }
}

.vocabulary-hw_education_niveau,
.vocabulary-hw_collections {
  #page-title {
    @extend .montserrat-regular;
    margin: 5rem 0 1rem 0;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  #block-crumbs-breadcrumb {
    display: block;
    margin-bottom: 2.5rem;
    text-align: center;
  }

  @media screen and (max-width : $screen-sm-max) {
    #page-title {
      margin: 3rem 0 0 0;
    }
    #block-crumbs-breadcrumb {
      display: none;
    }
  }
}

#block-menu-menu-menu-2-home-page {
  @include rem(padding, 40px $grid-gutter-width/2 80px);
  background : url("../images/fond-accueil.jpg") no-repeat center;
  text-align : center;
  @media screen and (max-width : $screen-md-max) {
    margin : 0 -15px;
  }

  .block-title {
    @extend .phenomena-bold;
    @include rem(font-size, 60px);
    @include rem(line-height, 60px);
    color          : $white;
    text-transform : uppercase;
    @media screen and (max-width : $screen-xs-max) {
      font-size   : 30px;
      line-height : 30px;
    }

    &:after {
      content    : "";
      display    : block;
      height     : 12px;
      margin-top : 16px;
      background : url("../images/fleche-bas.png") no-repeat center;
      text-align : center;
    }
  }
}

/**********
Auto-promo
**********/
#block-block-6 {
  display    : inline-block;
  width      : 100%;
  @include rem(margin-bottom, 20px);
  clear      : both;
  text-align : center;

  .not-front & {
    @media screen and (max-width : $screen-xs-max) {
      display : none;
    }
  }

  .front & {
    @include rem(margin, 75px 0 15px);
  }

  .content {
    @media screen and (max-width : $screen-xs-max) {
      margin : 0 -15px;
    }
  }

  img {
    width  : 100%;
    height : auto !important;
  }
}

/**********
Titres blocs
**********/
.block-title {
  #block-views-partenaires-block & {
    @include rem(margin-bottom, 45px);
    @extend .titre-ressources;
  }

  #block-menu-menu-les-supports & {
    color          : $numerique;
    @extend .phenomena-regular;
    @include rem(font-size, 50px);
    text-transform : uppercase;
    text-align     : center;
  }

  .blocs-support-num & {
    color : $numerique;
  }

  #block-views-livres-block-5 & {
    color : $gris-base;
    @include rem(margin-bottom, 16px);
  }

  #block-views-actualites-block-4 & {
    text-align : center;
  }
}

/**********
Partenaires
**********/
#block-views-partenaires-block {
  width  : 90%;
  margin : 0 auto;
}

/*****/

/**********
Bloc Numérique page niveaux
**********/
.wrapper-infos-num {
  color : $white;

  ul {
    padding-left  : 15px;
    margin-bottom : 20px;

    li {
      @extend .montserrat-regular;
      font-size   : 14px;
      line-height : 20px;
      @media screen and (max-width : $screen-xs-max) {
        margin : 10px 0;
      }
    }
  }
}

/**********
  Recherche
**********/
#block-current-search-standard,
#block-views-exp-recherche-avancee-page {
  width         : 100%;
  margin-left   : auto;
  margin-right  : auto;
  margin-top    : 25px;
  margin-bottom : 25px;
  @media #{$tablet} {
    display        : inline-block;
    vertical-align : middle;
    width          : 50%;
    margin-top     : 0;
  }
}

#block-current-search-standard {
  text-align : center;
  @media #{$tablet} {
    text-align : left;
  }
}

#block-views-exp-recherche-avancee-page {
  text-align : center;
  @media #{$tablet} {
    text-align : right;
  }
}

/**********
  Numérique
**********/
#block-menu-menu-les-supports {
  margin : 0 -7px;
  @media #{$tablet} {
    margin : 30px -15px 40px;
  }

  .menu {
    @include rem(margin-top, 30px);
    font-size  : 0;
    text-align : center;

    li {
      float : none;
      @media screen and (max-width : $screen-xs-max) {
        display        : inline-block;
        vertical-align : middle;
        margin-bottom  : $grid-gutter-width/2;
        padding        : 0 8px;
      }
    }

    a {
      display          : inline-block;
      width            : 100%;
      background-color : $bg-gris-livre !important;
      border-radius    : 25px;
      color            : $gris-base;
      @extend .montserrat-bold;
      text-transform   : uppercase;
      @media #{$tablet} {
        @include rem(padding, 155px 5px 50px);
        @include rem(font-size, 18px);
        border-radius       : 25px;
        background-position : 50% 50px !important;
      }
      @media screen and (max-width : $screen-xs-max) {
        padding             : 90px 5px 25px;
        font-size           : 10px;
        border-radius       : 15px;
        background-position : 50% 30px !important;
      }

      &:hover {
        color            : $white;
        background-color : $numerique !important;
        box-shadow       : 0 0 16px rgba(0, 0, 0, 0.19);
      }

      div {
        @extend .montserrat-light;
      }
    }

    .manuels-num {
      a {
        background : url("../images/manuel-num.png") no-repeat;
        @media screen and (max-width : $screen-xs-max) {
          background-size : 60px !important;
        }

        &:hover {
          background : url("../images/manuel-num.png") no-repeat;
        }
      }
    }

    .parcours-digital {
      a {
        background : url("../images/parcours-digit-vert.png") no-repeat;
        @media screen and (max-width : $screen-xs-max) {
          background-size : 80px !important;
        }

        &:hover {
          background : url("../images/parcours-digit-blanc.png") no-repeat;
        }
      }
    }

    .application {
      a {
        background : url("../images/application-tab.png") no-repeat;
        @media screen and (max-width : $screen-xs-max) {
          background-size : 60px !important;
        }

        &:hover {
          background : url("../images/application-tab-blanc.png") no-repeat;
        }
      }
    }

    .ebooks {
      a {
        background : url("../images/ebooks-vert.png") no-repeat;
        @media screen and (max-width : $screen-xs-max) {
          background-size : 30px !important;
        }

        &:hover {
          background : url("../images/ebooks-vert.png") no-repeat;
        }
      }
    }
  }
}

#block-block-11,
#block-block-12 {
  @include rem(padding, $grid-gutter-width $grid-gutter-width 40px);
  border : 1px solid $border-gris;
  @media #{$tablet} {
    width : 33.97%;
  }
}

#block-block-13 {
  @media #{$tablet} {
    width        : 29%;
    margin-left  : 1%;
    margin-right : 1%;
  }
}

.blocs-support-num {
  display        : inline-block;
  @include rem(margin-top, 50px);
  width          : 100%;
  text-align     : center;
  vertical-align : top;

  img {
    margin : 20px 0 10px;
  }

  p {
    @extend .montserrat-light;
  }
}

/**********
  Header page Manuels Numériques
**********/
.page-numerique-parcours-digital {
  #block-block-17,
  #block-block-29,
  #block-block-30 {
    display : none;
  }
}

.page-numerique-manuels-numeriques {
  #block-block-28,
  #block-block-29,
  #block-block-30 {
    display : none;
  }
}

.page-numerique-ebooks {
  #block-block-28,
  #block-block-17,
  #block-block-30 {
    display : none;
  }
}

.page-numerique-application-tablette {
  #block-block-28,
  #block-block-17,
  #block-block-29 {
    display : none;
  }
}

.block-fle-bandeaux {
  .page-numerique & {
    position : relative;
    @media screen and (max-width : $screen-sm-max) {
      margin : 0 -15px;
    }

    .field-name-field-image-header {
      color          : $gris-base;
      @extend .montserrat-bold;
      @include rem(font-size, 36px);
      text-transform : uppercase;
      @media #{$tablet} {
        position     : absolute;
        @include rem(top, -47px);
        right        : -15px;
        @include rem(padding-top, 26px);
        @include rem(padding-right, 15px);
        @include rem(padding-bottom, 26px);
        padding-left : 26%;
        height       : 184px;
      }
      @media screen and (max-width : $screen-sm-max) {
        display   : block;
        width     : 100%;
        height    : auto;
        position  : inherit;
        padding   : 30px 15px 30px 25%;
        font-size : 18px;
      }

      .field-item {
        @media #{$normal} {
          position  : absolute;
          top       : 50%;
          transform : translateY(-50%);
        }
        @media screen and (max-width : $screen-sm-max) {
          padding-left : 15px;
        }
      }
    }
  }
}

.field-name-field-image-header {
  #block-block-17 & {
    background          : url("../images/icone-manuels.png") no-repeat $bg-gris-livre;
    background-position : 13% 50%;
    @media screen and (max-width : $screen-sm-max) {
      background-size : 90px;
    }
    @media screen and (max-width : $screen-xs-max) {
      background-size     : 70px;
      background-position : 6% 50%;
    }
  }
  #block-block-35 & {
    background          : url("../images/icone-ressources.png") no-repeat $bg-gris-livre;
    background-position : 13% 50%;
    @media screen and (max-width : $screen-sm-max) {
      background-size : 90px;
    }
    @media screen and (max-width : $screen-xs-max) {
      background-size     : 70px;
      background-position : 6% 50%;
    }
  }

  #block-block-30 & {
    background          : url("../images/icone-appli.png") no-repeat $bg-gris-livre;
    background-position : 13% 50%;
    @media screen and (max-width : $screen-sm-max) {
      background-size : 90px;
    }
    @media screen and (max-width : $screen-xs-max) {
      background-size     : 70px;
      background-position : 6% 50%;
    }
  }

  #block-block-29 & {
    background          : url("../images/icone-ebooks.png") no-repeat $bg-gris-livre;
    background-position : 13% 50%;
    @media screen and (max-width : $screen-sm-max) {
      background-size : 90px;
    }
    @media screen and (max-width : $screen-xs-max) {
      background-size     : 70px;
      background-position : 6% 50%;
    }
  }

  #block-block-28 & {
    background          : url("../images/icone-parcours.png") no-repeat $bg-gris-livre;
    background-position : 13% 50%;
    @media screen and (max-width : $screen-sm-max) {
      background-size : 90px;
    }
    @media screen and (max-width : $screen-xs-max) {
      background-size     : 70px;
      background-position : 6% 50%;
    }
  }
}

#block-block-15 {
  .block-title-wrapper {
    padding : 0 15px;
    @media screen and (max-width : $screen-sm-max) {
      padding : 0;
    }
  }

  .field-name-field-image-header {
    @media screen and (max-width : $screen-sm-max) {
      @include rem(margin-bottom, 25px);
    }
  }
}

//#block-block-14 {
//  position : absolute;
//  top      : -95px;
//  right    : 15px;
//}

#block-block-21 {
  @include rem(padding-top, $grid-gutter-width);
  @include rem(margin-top, $grid-gutter-width);
  @media #{$tablet} {
    @include rem(margin-bottom, 100px);
  }
  @media screen and (max-width : $screen-sm-max) {
    margin-bottom : 30px;
  }

  .page-numerique & {
    border-top : 1px solid #9bd7be;
  }

  .page-faq & {
    @media #{$tablet} {
      border-top : 1px solid $red;
    }
    @media screen and (max-width : $screen-sm-max) {
      margin           : 0 -15px;
      padding          : 35px 15px 20px;
      background-color : #b3aeac;
    }
  }

  p {
    margin-bottom : 20px;
    @extend .montserrat-regular;
    @include rem(font-size, 16px);
    line-height   : 26px;

    .page-faq & {
      @media screen and (max-width : $screen-sm-max) {
        color : $white;
      }
    }
  }
}

#block-views-faq-block-1,
#block-block-21 {
  max-width    : 800px;
  margin-left  : auto;
  margin-right : auto;
}

#block-views-niveaux-block-1 {
  .page-taxonomy-term-7835 & {
    display : none;
  }
}

#block-block-10 {
  @media screen and (max-width : $screen-sm-max) {
    @include rem(margin-top, 25px);
  }
}

#block-block-18 {
  font-size  : 0;
  text-align : center;
  .cadre-blanc-teaser {
    height     : auto;
    display    : inline-block;
    text-align : left;
  }
}

#block-block-23 {
  .content {
    p {
      color : $gris-base;
    }
  }

  #biblio-top {
    @include rem(padding-bottom, 40px);
  }
  #biblio-left, #biblio-right {
    .biblio-inner {
      @extend .cadre-blanc-teaser;
    }
    .biblio-link {
      @include rem(padding, 16px 0);
    }
    .biblio-title {
      @include rem(font-size, 16px);
      @include rem(line-height, 22px);
    }
  }
  #biblio-left {
    .biblio-link {
      font-size  : 0;
      text-align : center;
      .biblio-link-wrapper {
        font-size : 0;
        display   : inline-block;
        .biblio-image-link {
          text-indent    : -9999px;
          background     : url("../images/icone-lien.png") no-repeat center;
          width          : 61px;
          height         : 61px;
          display        : inline-block;
          vertical-align : middle;
          margin-right   : 16px;
        }

        .bouton {
          display          : inline-block;
          vertical-align   : middle;
          color            : $white;
          background-color : $vert;
          border-color     : $vert;
          &:hover {
            background : transparent;
            color      : $vert;
          }
        }
      }
    }
  }
  #biblio-right {
    .biblio-link {
      text-align : center;
      .bouton {
        color            : $white;
        background-color : $red;
        border-color     : $red;
        &:hover {
          background : transparent;
          color      : $red;
        }
      }
    }
  }
}

#block-views-social-content-block {
  margin-top : 0;
}

#block-block-25 {
  @include rem(padding, 30px 0 5px);
  border-top : 1px solid #cfd0d0;
}

#block-block-26 {
  .content {
    p {
      @extend .montserrat-regular;
      @include rem(line-height, 20px);
    }
    .espace-perso-intro {
      @include rem(padding-bottom, 32px);
      p {
        font-size : 18px;
      }
    }
    .espace-perso-col {
      @include rem(margin-bottom, 25px);
      .visuel {
        display       : inline-block;
        line-height   : 50px;
        background    : #f4f5f6;
        border-radius : 25px;
        width         : 50px;
        height        : 50px;
        font-size     : 0;
        position: relative;
        vertical-align: middle;
        @include rem(margin-right, 20px);
        img {
          display     : inline-block;
          line-height : 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 22px;
        }
      }
      .description {
        display: inline-block;
        width: 80%;
        vertical-align: middle;
      }
    }
    .espace-perso-links {
      clear      : both;
      width      : 100%;
      text-align : center;
      font-size  : 0;
      @include rem(padding-top, 96px);
      .espace-perso-links-inner {
        display : inline-block;
      }
    }
    .espace-perso-numerique {
      @include rem(margin-top, -70px);
      padding: 40px;
      border: 3px solid $bleu-ebook;
      border-radius: 50px;

      @media screen and (max-width : $screen-xs-max) {
        @include rem(margin-top, 0px);
      }

      .orange {
        color: $orange-ebook;
        font-style: italic;
      }

      .espace-perso-numerique-intro {
        @include rem(margin-bottom, 20px);
        p {
          font-weight: bold;
          @include rem(font-size, 20px);
        }
      }

      .espace-perso-numerique-content {
        @include rem(margin-bottom, 20px);
      }

      .espace-perso-numerique-cta {
        text-align: center;
        a {
          background: $bleu-ebook;
          padding: 10px 30px;
          border-radius: 25px;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;

          :hover {
            text-decoration: none;
            background: $bleu-fonce-ebook;
          }
        }
      }
    }
  }
}

#block-fle-faq-faq-onglets {
  @media screen and (max-width : $screen-xs-max) {
    margin : 0 -15px;
  }
}

#block-views-distributeur-block-1,
.block-webform {
  .page-contact & {
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 30px;
    }
  }
}

#block-fle-contact-contact-autres-demandes {
  .page-contact & {
    @media #{$tablet} {
      padding-left : 15px;
    }
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 30px;
      text-align : center;
    }

    > .content {
      display : inline-block;

      .form-item-demande {
        max-width : 340px;
        @media (max-width : $screen-xs-max) {
          margin : 0 auto;
        }
      }
    }
  }
}

#block-fle-profil-filtre-geo-distributeur {
  .page-user & {
    margin-bottom : 30px;
  }
}

.block-bootstrap-login-modal {
  clear      : both;
  display    : inline-block;
  @include rem(margin-bottom, 30px);
  margin-top : 15px;
  width      : 50%;
  text-align : center;
  @media screen and (max-width : $screen-xs-max) {
    margin-bottom : 0;
    width      : 100%;
  }

  .menu {
    float         : none !important;
    margin-top    : 0;
    margin-bottom : 0;

    li {
      float          : none;
      display        : inline-block;
      vertical-align : middle;
      @media screen and (max-width : $screen-xs-max) {
        margin-bottom : 30px;
      }
    }

    a {
      @include rem(margin, 0 24px);
      @extend .bouton;
      background-color : $red;
      border-color     : $red;
      color            : $white;
      @media screen and (max-width : $screen-xs-max) {
        width : 277px;
      }

      &:focus {
        background-color : $red;
      }

      &:hover {
        background-color : transparent;
        color            : $red;

        &:before {
          background : url("../images/icone-perso-hover.png") no-repeat;
        }
      }

      &:before {
        content        : "";
        display        : inline-block;
        width          : 32px;
        height         : 23px;
        vertical-align : top;
        background     : url("../images/icone-perso.png") no-repeat;
      }
    }
  }
}

.block-fle-evolutions-edito {
  .part-one {
    @include rem(margin-bottom, 65px);

    @media screen and (max-width : $screen-xs-max) {
      .data:not(:first-child) {
        margin-top: 25px;
      }
    }

  }
  .part-two {
    @include rem(margin-bottom, 65px);

    @media screen and (max-width : $screen-xs-max) {
      .data:not(:first-child) {
        margin-top: 25px;
      }
    }
  }
  img {
    margin-left: 0;
    margin-right: 0;
  }
  .edito-title {
    float: left;
    @extend .firasanscondensed-semibold;
    @include rem(font-size, 26px);
    color: #ea6a6a;
    @include rem(margin-bottom, 15px);
    background : url("../images/actu-logo.png") no-repeat 0;
    @include rem(padding-left, 25px);
  }
  .edito-title.dossier {
    background : url("../images/dossier-small.png") no-repeat 0;
  }
  .edito-title.podcasts {
    background : url("../images/podcast-small.png") no-repeat 0;
  }
  .edito-title.videos {
    background : url("../images/video-small.png") no-repeat 0;
  }
  .more {
    float: right;
    position: absolute;
    top: 12px;
    right: 15px;
    a {
      color: #68737a;
      @include rem(font-size, 14px);
    }
    @extend .montserrat-regular;
    i {
      -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      @include rem(margin-left, 5px);
    }
  }
  .soulign {
    width: 60%;
    margin-left: 20px;
    float: left;
    @media screen and (max-width : $screen-xs-max) {
      width: 37%;
    }
  }
  .views-field-title {
    @include rem(margin-top, 20px);
    a {
      color: #404547;
      @include rem(font-size, 20px);
      font-weight: bold;
    }
  }
  .block-podcasts, .block-videos {

    @media screen and (max-width : $screen-xs-max) {
      @include rem(margin-top, 65px);
    }

    .soulign {
      width: 45%;
      @media screen and (max-width : $screen-xs-max) {
        width: 63%;
      }
    }
    .more {
      right: 15px;
    }
  }
  .block-actus {
    .soulign {
      width: 70%;
      @media screen and (max-width : $screen-xs-max) {
        width: 52%;
      }
    }
  }
  .part-three {
    @include rem(margin-bottom, 60px);

    .perso, .espace-numerique, .events {
      position: relative;

      .img {
        display: none;//test
        width: 100%;
        left: 0;
        right: 0;
        position: absolute;
        text-align: center;
        img {
          @include rem(width, 150px);
          @include rem(min-height, 150px);
          @include rem(max-height, 150px);
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }
      }
      .block-text {
        border-width: 1px;
        border-style: solid;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        border-color: #e7e7e7;
        @include rem(padding-top, 20px);// previous 125
        //@include rem(margin-top, 50px);
        @include rem(min-height, 260px);// previous 350px

        .title p{
          @extend .firasanscondensed-semibold;
          color: #ea6a6a;
          @include rem(font-size, 26px);
          text-align: center;
          @include rem(line-height, 30px);
          min-height: 45px;
        }
        ul {
          li {
            @include rem(margin-top, 20px);
            @include rem(margin-bottom, 20px);
            background : url("../images/circle-check.png") no-repeat 0;
            list-style-type: none;
            @include rem(padding-left, 35px);

            a {
              color: #404547;
              @include rem(font-size, 16px);
              @extend .montserrat-regular;
            }
          }
        }
      }

      @media screen and (max-width : $screen-xs-max) {
        @include rem(margin-bottom, 15px);
      }

    }
    .espace-numerique {
      .title p {
        color: #58b690 !important;
      }
    }
    .events {
      .row {
        margin: 0;

        .event-date {
          background: #ea6a6a;
          border-radius: 0px 10px 10px 0;
          color: white;
          @include rem(padding-bottom, 10px);
          @include rem(padding-top, 10px);
          @include rem(padding-left, 10px);
          .prefix-date {
            @include rem(font-size, 10px);
          }
          .date-day {
            @include rem(font-size, 30px);
            font-weight: bold;
          }
          .date-month, .date-year {
            @include rem(font-size, 14px);
          }
        }

        .event-title {
          @include rem(height, 114px);
          .field-content {
            position: absolute;
            top: 25%;
          }
          a {
            color: #404547;
            @include rem(font-size, 16px);
            @extend .montserrat-regular;
            font-style: bold;
          }
        }
        .event-chapo {
          @include rem(margin-top, 20px);
          @include rem(font-size, 13px);
          @extend .montserrat-light;
        }
      }
    }
  }
}


/*** BLOCK > SLIDESHOW full width ***/
#block-views-slideshow-accueil-block{
  @include rem(margin-top, 40px);
  /*@include rem(margin-bottom, 40px);*/
  padding:0;
  position:relative;

  .views-field-hw-slide-image{
    display:block;
    float:left;
    width:100%;

    img {
      width: 100%;
    }
  }
  .views-field-field-slide-image-mobile{
    display:none;
  }
  .slick {
    .slick-arrow {
      display: inline-block!important;
      border: none;
      background: none;
      color: #000000;
      padding: 20px 25px;
      border-radius: 50%;
      box-shadow: 0 2px 6px 1px rgba(0,0,0,0.15);
      background-color: white;
      transform: scale(1);
      font-size: 0;
      transition: all 0.4s ease 0s;
      cursor: pointer;
    }
    .slick-arrow:before {
      display: block;
      float: left;
      content: "\f054";
      font-family: "fontAwesome";
      font-size: 1rem;
      color: #4d5254;
      transition: color 0.5s ease;
    }
    .slick__slide {
      @include rem(margin-left, 20px);
      @include rem(margin-right, 20px);
    }
    .slick-current {
      //padding: 0;
    }
    .slick-prev {
      transform: rotate(180deg);
    }
    .slick-next {
    }
    .slick__arrow {
      top: 40%;
    }
  }
}
/* mobile */
@media( max-width:718px ){
  #block-views-slideshow-accueil-block .slick{ margin:0 auto; }
  /*#block-views-slideshow-accueil-block .slick .slick__arrow{ position:static; width:100%;  }
  #block-views-slideshow-accueil-block .slick .slick-prev{ left:-10px; top:40%; }
  #block-views-slideshow-accueil-block .slick .slick-next{ right:-10px; top:40%; }*/
  #block-views-slideshow-accueil-block .views-field-field-slide-image-mobile{ display:block;}
  #block-views-slideshow-accueil-block .views-field-hw-slide-image{ display:none;   }
  #block-views-slideshow-accueil-block .slick .slick__slide {
    @include rem(margin-left, 5px);
    @include rem(margin-right, 5px);
  }
}

.slick .slick-dots{ display:block; width:100%; position:static; margin:20px 0; left:0; text-align:center; }
.slick .slick-dots li{ display:inline-block; width:10px; height:10px; margin:0 20px 0 0; background:#a3abb8; border-radius:20px; text-indent:-9000px; text-align:left; border:none; cursor:pointer;}
.slick .slick-dots li.slick-active{ background:#038abf; }


.page-le-blog {
  .bloc-edito {
    @media screen and (max-width : $screen-xs-max) {
      text-align: left;
    }
  }
  .view-blog {
    .field-name-title-field {
      @media screen and (max-width : $screen-xs-max) {
        min-height: inherit;
        @include rem(margin-bottom, 25px);
      }
    }
  }
}

.vocabulary-hw_education_niveau .bloc-edito {
  .block-title-wrapper {
    @include rem(padding, 0 30px);
    @include rem(margin-bottom, 30px);
  }
  .block-title {
    color: $red;
    @include rem(font-size, 30px);
    font-style: italic;
    font-weight: normal;
  }
}
