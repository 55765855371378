.jp-interface {
  .jp-audio & {
    display       : inline-block;
    height        : 2.5rem !important;
    border        : none;
    border-radius : 25px;

    .#{$enfants} & {
      background-color : $vert-clair;
    }

    .adolescents & {
      background-color : $univers-ado;
    }

    .grands-ados-et-adultes & {
      background-color : $univers-adulte;
    }

    .outils & {
      background-color : $univers-outils;
    }

    .enseignants & {
      background-color : $univers-enseignants;
    }

    .page-numerique & {
      background-color : $numerique;
    }

    .jp-controls {
      a {
        width     : 18px !important;
        height    : 18px !important;
        top       : 50% !important;
        transform : translateY(-50%);
        outline   : none;
      }
    }

    .jp-play,
    .jp-pause {
      left       : 1rem !important;
      background : url("../images/player-play.png") no-repeat center;
    }

    .jp-mute,
    .jp-unmute {
      right      : 1rem !important;
      left       : inherit !important;
      background : url("../images/player-vol.png") no-repeat center;
    }

    .jp-previous,
    .jp-next {
      display : none;
    }

    .jp-progress {
      width            : 100% !important;
      @include rem(padding, 0 50px);
      top              : 50% !important;
      left             : inherit !important;
      transform        : translateY(-50%);
      outline          : none;
      background-color : transparent;

      .jp-seek-bar {
        width      : 100% !important;
        border     : 1px solid $white;
        background : transparent;

        .jp-play-bar {
          background : $white;
        }
      }
    }
  }
}