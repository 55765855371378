/***********
Profil
***********/

.quicktabs-wrapper {
  @media (max-width : $screen-xs-max) {
    //display : none;
  }

  .quicktabs_main {
    background : $bg-gris-livre;
  }

  .quicktabs-tabs {
    margin-bottom : 0;
    padding       : 0;
    font-size     : 0;

    > li {
      display : inline-block;
    }
  }
}

ul.quicktabs-tabs {
  @include rem(margin-top, -62px);

  li {
    display : inline-block !important;
    padding : 0 !important;
  }

  .page-faq &,
  .page-user & {
    li {
      text-align : center;
      @media #{$tablet} {
        position         : relative;
        width            : 16.66%;
        background-color : $white;

        &:last-child {
          a {
            border-right : 1px solid $bg-gris-livre;
          }
        }
      }

      a {
        @extend .montserrat-regular;
        @media #{$tablet} {
          display        : table-cell;
          width          : 16.66%;
          padding        : 0 15px;
          @include rem(height, 63px);
          vertical-align : middle;
          border         : 1px solid $bg-gris-livre;
          font-size      : 13px;
          color          : $gris-contenu;
          border-right   : none;
        }

        &:hover,
        &:focus {
          text-decoration : none;
        }
      }

      &.active {
        background-color : $bg-gris-livre;

        a {
          color : $red;
        }
      }
    }
  }

  .page-contact & {
    padding    : 0;
    font-size  : 0;
    text-align : center;
    @media #{$tablet} {
      margin-bottom : 50px;
      margin-top    : 0;
    }
    @media (max-width : $screen-xs-max) {
      margin-top    : -19px;
      margin-bottom : 0;
    }

    li {
      @media #{$tablet} {
        position       : relative;
        max-width      : 220px;
        width          : 100%;
        margin         : 0 10px;
        border-color   : #8a8c8d;
        font-size      : 13px;
        line-height    : 16px;
        text-transform : uppercase;
        color          : $gris-contenu;
      }
      @media (max-width : $screen-xs-max) {
        width         : 100%;
        border-bottom : 1px solid #e6e6e6;
      }

      a {
        @extend .montserrat-regular;
        text-transform : uppercase;
        @media #{$tablet} {
          display        : table-cell;
          @include rem(height, 96px);
          @include rem(padding, 30px);
          vertical-align : middle;
          border-radius  : 25px;
          font-weight    : bold;
          border         : 1px solid $gris-contenu;
          font-size      : 13px;
          color          : $gris-contenu;
        }
        @media (max-width : $screen-xs-max) {
          display : inline-block;
          width   : 100%;
          padding : 15px;
          color   : #9fa2a3;
        }

        &:hover {
          text-decoration : none;
        }
      }

      &.active {
        a {
          color            : $white;
          text-decoration  : none;
          border-color     : $red;
          background-color : $red;

          &:after {
            content    : "";
            display    : block;
            @include rem(width, 87px);
            @include rem(height, 22px);
            position   : absolute;
            left       : 0;
            right      : 0;
            bottom     : -18px;
            margin     : 0 auto;
            background : url("../images/fleche-bas-rouge.png") no-repeat center;

          }
        }
      }
    }
  }
}

#quicktabs-profil_quicktab {
  #quicktabs-container-profil_quicktab {
    @include rem(padding-top, 28px);
  }
}

#quickset-contact_quicktab_mobile,
#quickset-profil_quicktab_mobile,
#quickset-faq_quicktab_mobile {
  display : none;
  width   : 100%;
  @media (max-width : $screen-xs-max) {
    display : block;
  }

  > h3 {
    background : $white;
    padding    : 10px 0 16px 15px;
    display    : block;

    a {
      color : $gris-contenu;
      @extend .montserrat-regular;
      @media (max-width : $screen-xs-max) {
        display      : block;
        margin-right : 15px;
        background   : url("../images/arrow-down-blanche.png") no-repeat right;
      }
    }

    .ui-accordion-header-icon {
      left        : initial;
      right       : 15px;
      background  : url(../images/arrow.png) no-repeat center right;
      width       : 13px;
      height      : 16px;
      line-height : 16px;
    }

    &.ui-accordion-header-active {
      .ui-accordion-header-icon {
        background : url(../images/arrow-down.png) no-repeat center right;
      }

      a {
        color   : $red;
        outline : none;
      }
    }

  }

  > div {
    padding    : 15px;
    background : none;

    .page-contact & {
      @media (max-width : $screen-xs-max) {
        padding-top : 0;
      }
    }
  }

  > h3,
  > div {
    height : auto !important;
    border : none;
  }
}

#quicktabs-tabpage-contact_quicktab-2 {
  /*max-width : 350px;
  margin    : 0 auto 30px;*/
  @media (max-width : $screen-xs-max) {
    margin-top : 30px;
  }
}

/***********
Faq
***********/

#quicktabs-container-faq_quicktab {
  @include rem(margin-top, 70px);
  padding : 0 15px;
} 
