/**********
  FOOTER
**********/

/***** Pre-footer *****/

.pre-footer {
  display          : inline-block;
  width            : 100%;
  clear            : both;
  background-color : $red;
  
  .region-pre-footer {
    @include rem(line-height, 87px);
    @include rem(min-height, 87px);
    vertical-align : middle;
    font-size      : 0;
    @media screen and (max-width : $screen-sm-max) {
      @include rem(line-height, 28px);
      @include rem(min-height, 28px);
    }
    
    > .block {
      display : inline-block;
      
      &#block-block-1 {
        @media #{$tablet} {
          width : 82%;
        }
        /*@media screen and (max-width : $desk-inter) and (min-width : $tab) {
          width : 80%;
        }*/
        @media screen and (max-width : $screen-sm-max) {
          width   : 100%;
          display : block;
          @include rem(padding-top, 24px);
          @include rem(margin-bottom, 28px);
        }
        
        .content {
          font-size : 0;
          
          > div {
            width          : 33.3333%;
            display        : inline-block;
            vertical-align : middle;
            text-align     : center;
            @include rem(line-height, 20px);
            
            @media screen and (max-width : $screen-sm-max) {
              width   : 100%;
              display : block;
            }
            
            &#raccourci-faq, &#raccourci-catalogue {
              padding-right : 1%;
              @media screen and (max-width : $screen-sm-max) {
                padding : 1rem 0;
                padding : 1rem 0;
              }
            }
            
            .bouton {
              @extend .bouton;
              color      : $red;
              width      : 100%;
              text-align : center;
              background : $white;
              
              @media screen and (max-width : $screen-sm-max) {
                width   : 277px;
                display : block;
                margin  : 0 auto;
              }
              
              &:hover {
                background   : transparent;
                color        : $white;
                border-color : $white;
                
                span {
                  color : $white;
                }
              }
              
              span {
                display        : inline-block;
                vertical-align : middle;
                min-height     : 22px;
                line-height    : 22px;
                color          : $red;
                font-weight    : 700;
                
                &.icone {
                  position : relative;
                  left     : -10px;
                  
                  &#icone-faq {
                    background : url('../images/contact-hover.png') no-repeat right center;
                    width      : 22px;
                  }
                  &#icone-catalogue {
                    background : url('../images/catalogue-hover.png') no-repeat right center;
                    width      : 24px;
                  }
                  &#icone-map {
                    background : url('../images/point-vente-hover.png') no-repeat right center;
                    width      : 18px;
                  }
                }
                &.texte-lien {
                }
              }
            }
          }
        }
      }
      &#block-block-2 {
        width : 18%;
        @media screen and (max-width : $screen-sm-max) {
          margin  : 6px auto 0;
          display : block;
          width   : 168px;
          @include rem(margin-bottom, 40px);
        }
        
        .content {
          text-align : right;
          @media screen and (max-width : $screen-sm-max) {
            text-align : center;
          }
          
          a {
            display         : inline-block;
            vertical-align  : middle;
            width           : 40px;
            height          : 40px;
            text-decoration : none;
            
            &#facebook-rs-link {
              background : url('../images/icone-facebook.png') no-repeat center;
            }
            
            &#twitter-rs-link {
              background : url('../images/icone-twitter.png') no-repeat center;
            }
            
            &#youtube-rs-link {
              background : url('../images/icone-youtube.png') no-repeat center;
            }
            
            &:nth-child(2),
            &:first-child {
              margin-right : 6.5%;
            }
          }
        }
      }
    }
  }
}

/***** Menu footer *****/
.footer {
  text-align       : left;
  margin-top       : 0;
  border-top       : none;
  @include rem(min-height, 163px);
  background-color : #404547;
  
  .region-footer {
    font-size : 0;
    .block {
      display        : inline-block;
      vertical-align : middle;
      
      &#block-block-4 {
        width         : 25%;
        padding-right : 5%;
        
        @media screen and (max-width : $screen-sm-max) {
          @include rem(margin-bottom, 35px);
          @include rem(margin-left, auto);
          @include rem(margin-right, auto);
          width : 100%;
        }
        
        .content {
          text-align : left;
          @media screen and (max-width : $screen-sm-max) {
            text-align : center;
          }
          img {
            max-width : 100%;
          }
        }
      }
      &#block-menu-menu-footer {
        width : 75%;
        @media screen and (max-width : $screen-sm-max) {
          width : 100%;
        }
      }
      
      .menu-section {
        display        : inline-block;
        vertical-align : top;
        text-align     : left;
        
        &#menu-section-1,
        &#menu-section-2 {
          margin-right : 9.3%;
          @media screen and (max-width : $screen-sm-max) {
            margin-right : 1.5%;
          }
          @media screen and (max-width : $screen-xs-max) {
            margin-right : 0;
          }
        }
        
        @media screen and (max-width : $screen-sm-max) {
          width : 100%;
        }
        
        > a {
          display : none;
        }
        ul {
          padding : 0;
          margin  : 0;
          
          > li {
            
            @media screen and (max-width : $screen-sm-max) {
              text-align : center;
            }
            
            > a {
              color           : $gris-secondaire;
              font-family     : "montserratregular", "serif";
              font-size       : 13px;
              @include rem(line-height, 25px);
              text-transform  : uppercase;
              text-decoration : none;
              &:hover {
                text-decoration : underline;
              }
              @media screen and (max-width : $screen-sm-max) {
                text-transform : none;
              }
            }
          }
        }
      }
    }
  }
}     

