#block-crumbs-breadcrumb {
  display : inline-block;

  .page-recherche &,
  .page-recherche-avancee & {
    width : 100%;
  }
}

.breadcrumb {
  @extend .container-fluid;
  @include rem(margin-top, 7px);
  @include rem(margin-bottom, 7px);
  padding-left : 0;
  background   : transparent;
  float        : none !important;
  font-size    : 13px;
  @media screen and (max-width : $screen-xs-max) {
    display : none;
  }

  .page-node &,
  .numerique & {
    //float : left !important;
  }

  li,
  a,
  .crumbs-current-page {
    @extend .montserrat-light;
    color     : $gris-base;
    font-size : 13px;
  }

  .vocabulary-hw_education_niveau & li,
  .vocabulary-hw_education_niveau & a,
  .vocabulary-hw_education_niveau & .crumbs-current-page,
  .vocabulary-hw_collections & li,
  .vocabulary-hw_collections & a,
  .vocabulary-hw_collections & .crumbs-current-page {
    font-size: 1rem;
  }
}
