.site-map-menu {
  > .expanded {
    @include rem(margin-bottom, 30px);
  }

  li {
    a {
      @extend .montserrat-regular;
      color : $gris-contenu;
    }
  }

  .bouton {
    border         : none;
    padding        : 0;
    font-size      : 16px;
    @extend .montserrat-regular;
    color          : $gris-contenu;
    text-transform : none;
    text-align     : left;
  }
}

.title {
  #site-map & {
    @extend .montserrat-regular;
    color : $gris-contenu;
  }
}
