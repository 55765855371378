.form-group {
  margin : 0;

  .group-gauche &,
  .group-droite & {
    #user-register-form & {
      margin : 10px 0;
    }
  }

  &.form-wrapper {
    .group-bas & {
      #user-register-form & {
        margin : 20px 0;
        @media #{$tablet} {
          display        : inline-block;
          padding-right  : 2.5%;
          vertical-align : top;
        }
      }
    }
  }
}

.form-radio {
  #user-profile-form & {
    border : none;
  }
}

.form-control {
  font-size     : 13px;
  box-shadow    : none;
  border-radius : 7px;
  @extend .montserrat-light;

  #block-views-exp-recherche-page & {
    border-right  : none;
    font-size     : 18px;
    color         : $gris-contenu;
    font-style    : italic;
  }

  #fle-profil-filtre-geo-form &#edit-zones {
    /*background-position : 95% center;*/
    background-position : right 20px center;
  }

  #user-profile-form & {
    border : none;
  }
}

.form-select {
  width              : 100%;
  -webkit-appearance : none;
  -moz-appearance    : none;
  appearance         : none;
  padding            : 10px 55px 10px 15px;
  height             : auto;
  border-radius      : 10px;
  border             : 1px solid rgba(112, 120, 124, 0.4);
  font-size          : 12px !important;
  @extend .montserrat-light;
  color              : $gris-contenu;
  box-shadow         : inherit;
  /*background         : url("../images/arrow-dark-down.png") no-repeat #f8f8f8 93% center;*/
  background         : url("../images/arrow-dark-down.png") no-repeat #f8f8f8 right 20px center;
  cursor             : pointer;

  .page-contact & {
    background-color    : $white;
    background-position : 95% center;
  }

  .page-user & {
    font-size : 12px;
  }

  #user-register-form & {
    background-position : 96% center;
  }
}

.form-textarea {
  border-radius : 7px !important;
}

.grippie {
  display : none;
}

.form-item {
  &.form-type-select {
    position : relative;

    &:after {
      content          : "";
      display          : inline-block;
      width            : 1px;
      height           : 38px;
      position         : absolute;
      bottom           : 0;
      right            : 50px;
      background-color : rgba(112, 120, 124, 0.4);

      .webform-client-form & {
        right : 45px;
      }

      .field-name-field-pays & {
        @include rem(height, 92px);
      }
    }
  }
}

button {
  #block-views-exp-recherche-page & {
    /*height        : 34px;
    padding       : 5px 8px 5px;
    border-radius : 0 25px 25px 0;
    background    : $white;
    border-color  : $gris-classique;
    border-left   : none;*/
    font-size: 15px;
    background-color: #ea6a6a;
    /* height: 29px; */
    padding: 8px;
    border: none;
    width: 140px;
    border-radius: 25px;

    &:before {
      content: "\e003";
      padding-right: 5px;
      font-family: 'Glyphicons Halflings';
    }

    &:hover {
      border      : 1px solid $gris-classique;
      border-left : none;
    }
  }
}

#edit-submit-recherche {
  &:after {
    /*content : "OK";
    color   : $red;
    @extend .montserrat-bold;
    @include rem(font-size, 14px);*/
  }
}

.form-type-bef-link {
  display : inline-block;
}

/* inscription/édition du profil */

#edit-field-civilite {
  .form-radios {
    font-size : 0;

    .form-type-radio {
      display   : inline-block;
      font-size : 14px;
      @include rem(margin-right, 40px);
    }
  }
}

.field-name-field-nom {
  #user-register-form & {
    margin-top : -9px;
  }
}

#user-profile-form {
  @include rem(padding-bottom, 85px);

  .ds-form > .row > .col-sm-12 {
    padding : 0;
  }

  label {
    @include rem(margin-bottom, 7px);
    color      : $gris-base;
    text-align : left;
  }

  #edit-account {
    margin : 0;
    width  : 100%;

    .help-block {
      display : none;
    }
  }

  #edit-account.form-group > .form-item,
  .form-wrapper,
  .form-item-pass-pass2 {
    @include rem(margin-top, 20px);
  }

  .form-wrapper {
    @include rem(margin-top, 15px);
    display        : inline-block;
    vertical-align : top;
    font-size      : 14px;
    &#edit-field-updated {
     display: none;
    }
  }

  .group-gauche {
    border-right  : 1px solid $gris-base;
    font-size     : 0;
    padding-right : 0;
    @media screen and (max-width : $mobile) {
      border        : none;
      padding-right : 15px;
    }

    .form-wrapper {
      width        : 45%;
      margin-right : 5%;
      clear        : none;
      @media screen and (max-width : $mobile) {
        width        : 100%;
        margin-right : 0;
      }

      &#edit-field-civilite {
        width : 100%;
      }
    }
  }

  .group-droite {
    padding-left : 3.333333%;
    @media screen and (max-width : $mobile) {
      padding-left : 15px;
    }
    h2, h3 {
      @include rem(margin-bottom, 7px);
      span {
        font-size : inherit;
        @extend .phenomena-regular;
        color     : $gris-base;
      }
    }
    #edit-account {
      .form-type-password {
        clear : none;
        width : 100%;
      }
      .form-type-password-confirm {

        .password-help {
          border-left : none;
          left        : initial;
          margin-left : 0;
          position    : static;
          display     : none !important;
        }
      }
      .help-block a {
        color           : #777777;
        font-size       : 12px;
        text-decoration : underline;
      }
    }
  }

  .group-bas {
    @include rem(margin-top, 15px);
    @media screen and (max-width : $mobile) {
      margin-top : 0;
    }

    > div {
      padding-left  : 0;
      padding-right : 0;
      @media screen and (max-width : $mobile) {
        width : 100%;
      }

      > .form-item {
        margin-right : 16.486%;
        &.form-item-field-niveau-cecrl-und {
          font-size : 0;
          > label {
            font-size : 14px;
          }
          .form-type-checkbox {
            display        : inline-block;
            vertical-align : middle;
            width          : 50%;
            font-size      : 14px;
            @media screen and (max-width : $min-mobile) {
              width : 100%;
            }
          }
        }
      }

      &:nth-child(3) > .form-item {
        margin-left  : 11.6666666%;
        margin-right : 0;
        @media screen and (max-width : $mobile) {
          margin-left : 0;
        }
      }
    }
  }

  .form-item-status.form-group {
    width : 33.33333333%;
  }
  .form-item-roles {
    width : 66.66666666%;
  }

  .form-item-status.form-group,
  .form-item-roles {
    padding-left   : 15px;
    padding-right  : 15px;
    @include rem(padding-top, 63px);
    display        : inline-block;
    vertical-align : top;
    clear          : both;
    float          : none;
    .form-item {
      display       : inline-block;
      padding-right : 15px;
    }
  }

  .form-actions {
    float      : none;
    text-align : center;
    @include rem(padding-top, 85px);
    width      : 100%;

    .bouton {
      color            : $white;
      background-color : $red;
      border-color     : $red;

      .glyphicon {
        display : none;
      }

      &:hover {
        background : transparent;
        color      : $red;
      }
    }
  }

}

.espace-perso-links-inner {
  .bouton {
    background-color : $red;
    border-color     : $red;
    @include rem(margin, 0 24px);

    &:hover {
      background : transparent;
      .texte-lien {
        color : $red;
      }
      .icone-lien {
        background : url(../images/icone-perso-hover.png) no-repeat;
      }
    }
    .icone-lien {
      display    : inline-block;
      background : url(../images/icone-perso.png) no-repeat;
      width      : 32px;
      height     : 23px;
    }
    .texte-lien {
      color : $white;
    }
  }
}

.group-droite {
  #user-register-form & {
    background-color : $bg-gris;
    @media screen and (max-width : $screen-xs-max) {
      margin-top : $grid-gutter-width/2;
    }

    h2 {
      font-size : 20px;

      span {
        font-size      : 20px;
        text-transform : uppercase;
        color          : $red;
      }
    }
  }
}

/**********
CONTACTEZ-NOUS
**********/

#webform-component-destinataire {
  display : none;

  .form-item-submitted-destinataire-mail-destinataire-2 {
    display : none;
  }
}

#webform-component-destinataire,
#webform-component-qui-vous-etes,
#webform-component-votre-message {
  @include rem(padding-top, 50px);
  border-top : 1px solid $border-gris;
  color      : $gris-contenu;
}

.panel {
  background-color : transparent;
  border           : none;
  box-shadow       : none;

  > .panel-body {
    display        : inline-block;
    vertical-align : top;
    padding-top    : 0;
    padding-left   : 0;
    padding-right  : 0;
    @media #{$tablet} {
      width : 65%;
    }
    @media screen and (max-width : $screen-xs-max) {
      width        : 100%;
      padding-left : 0;
    }

    .panel-body {
      width : 100%;
    }

    .form-group {
      margin-bottom : 15px !important;
    }

    .form-type-textfield,
    .form-type-webform-email,
    .form-type-select {
      display : inline-block;
      @media #{$tablet} {
        width : 46%;
      }
      @media (max-width : $screen-xs-max) {
        width : 100%;
      }
    }

    .form-item-submitted-votre-message-recevoir-le-catalogue-adresse-postale-catalogue,
    .form-item-submitted-votre-message-specimen-adresse-postale-specimen {
      width : 100%;
    }
  }

  &#webform-component-destinataire,
  &#webform-component-qui-vous-etes,
  &#webform-component-votre-message {
    .form-group {
      @media #{$tablet} {
        margin-left  : 2%;
        margin-right : 2%;
      }
    }
  }
}

.control-label {
  width     : 100%;
  @extend .montserrat-light;
  font-size : 14px;
}

#block-fle-contact-contact-autres-demandes .block-title-wrapper,
.view-footer .block-title,
.panel-heading,
.quicktabs-tabpage .view-header {
  .page-contact & {
    color            : $red;
    background-color : transparent;
    border           : none;
    @media #{$tablet} {
      display        : inline-block;
      width          : 35%;
      vertical-align : top;
    }
    @media screen and (max-width : $screen-xs-max) {
      margin-bottom : 30px;
      text-align    : center;
    }
  }
}

.page-contact {
  .view-display-id-block_3 {
    .view-header {
      width: 100%;
      display: block;
    }
  }
  .view-id-delegue_pedagogique {
    .view-header {
      width: 100%;
      display: block;
    }
  }
  .view-delegue-pedagogique,
  .view-distributeur {
    .block-title {
      display: block;
      width: 100%;
    }
  }
}

.block-title,
.panel-title {
  .page-contact & {
    @extend .phenomena-regular;
    @include rem(font-size, 35px);
    font-weight : 700 !important;
    margin      : 0;
    color       : $red;
    @media #{$tablet} {
      text-align : left !important;
    }
  }
}

.form-item-field-departements-tid {
  margin-right : 15px;
}

.form-type-checkboxes {
  #webform-component-votre-message--specimen & {
    display    : inline-block;
    margin-top : 15px;
    @media #{$tablet} {
      width : 46%;
    }
    @media (max-width : $screen-xs-max) {
      width : 100%;
    }
  }
}

.form-type-checkbox {
  #edit-submitted-votre-message-probleme-ressource-numerique-nature-de-contenu & {
    display : inline-block;
    @media #{$tablet} {
      width : 25%;
    }
    @media (max-width : $screen-xs-max) {
      width          : 50%;
      vertical-align : middle;
    }
  }
}

.form-file {
  background    : transparent;
  border-radius : 7px 0 0 7px !important;
}

.input-group {
  .webform-client-form & {
    width : 100%;

    .input-group-btn {
      width : 87px;
    }
  }
}

.form-submit {
  .webform-client-form &,
  #block-fle-contact-contact-autres-demandes & {
    padding-bottom   : 7px;
    background-color : $red;
    border-color     : $red;
    border-radius    : 0 7px 7px 0;
    color            : $white;

    &:hover {
      color            : $red;
      background-color : transparent;
      border           : 1px solid $red;
    }
  }
}

#edit-actions {
  .webform-client-form & {
    text-align : center;
  }
}

#edit-bouton,
#edit-submit {
  .webform-client-form &,
  #block-fle-contact-contact-autres-demandes & {
    @extend .bouton;

    &:before {
      content      : "";
      width        : 14px;
      height       : 11px;
      display      : inline-block;
      margin-right : 15px;
      background   : url("../images/icone-contact.png") no-repeat left center;
    }

    &:hover {
      &:before {
        background : url("../images/icone-contact_hover.png") no-repeat left center;
      }
    }
  }
}

.form-item-demande {
  @include rem(margin-bottom, 30px);
}

#register-modal {
  max-width : 920px;
  margin    : 0 auto;
}

#ajax-user-login-wrapper form {
  @include rem(padding, 30px 15px);
  @media screen and (max-width : $screen-xs-max) {
    padding-left  : 0;
    padding-right : 0;
  }

  #password-reset {
    text-align : right;

    .password-reset-link {
      font-size       : 14px;
      color           : $gris-base;
      text-decoration : underline;
    }
  }

  .group-gauche,
  .group-droite {
    margin-bottom : 15px;
  }

  .register-desc,
  .group-gauche,
  .group-droite {
    @media screen and (max-width : $screen-xs-max) {
      padding : 0;
    }
  }

  .group-droite {
    @include rem(padding, 20px 25px 35px);
    background-color : $bg-gris-livre;
    @media screen and (max-width : $screen-xs-max) {
      margin-top : 15px
    }

    .form-type-password {
      width : 100%;
    }

  }

  h2 {
    margin-top : 0;
    color      : $red;

    span {
      font-size : 30px;
      color     : $red;
    }
  }

  .form-group:not(:last-child) {
    margin-bottom : 15px;

    .form-type-radio {
      margin-bottom : 0;
    }
  }

  .group-gauche {
    .form-select,
    .form-control {
      background-color : $bg-gris-livre;
    }
  }

  .group-bas {
    border-top  : 1px solid $bg-gris-livre;
    margin-top  : 30px;
    padding-top : 20px;

    .field-widget-options-buttons {
      margin : 10px 0 20px;
      @media #{$tablet} {
        display        : inline-block;
        width          : 50%;
        vertical-align : top;
      }
    }

    #edit-field-niveau-cecrl,
    #edit-field-niveau-cecrl-enseignes {
      width : 100%;

      .form-type-checkbox {
        @media #{$tablet} {
          width   : 50%;
          display : inline-block;
        }
      }
    }
  }

  .form-actions {
    text-align : center;
  }
}

.form-actions {
  text-align : center;

  .form-submit {
    @extend .bouton;
    margin-top : 0;
  }
}

#user-pass {
  .form-submit {
    margin           : 14px 0 30px;
    background-color : $red;
    border-color     : $red;
    color            : $white;
    &:hover {
      color            : $red;
      background-color : transparent;
      border           : 1px solid $red;
    }
  }
}

.form-type-password {
  #user-register-form & {
    width : 100%;
  }
}

.form-type-password-confirm .help-block {
  position : relative;
  top      : 0;
  left     : 0;
  color    : $red;
}

.register-desc {
  @include rem(margin-bottom, 50px);
  padding : 0 15px;
}

.webform-client-form {
  .panel-body {
    > .webform-component-fieldset {
      display : none;
    }
  }
}

#webform-client-form-43872 {
  .form-item-submitted-destinataire-mail-destinataire {
    display: none;
  }
}
