.menu {
  #block-system-main-menu & {
    text-align : left;
    @media #{$tablet} {
      text-align : center;
    }
  }

  > li {
    clear : both;
    @media #{$tablet} {
      display        : inline-block;
      vertical-align : middle;
    }

    #block-menu-menu-menu-secondaire & {
      width      : 30%;
      @include rem(max-width, 105px);
      text-align : center;
      @media (max-width : $screen-sm-min - 1) {
        display        : inline-block;
        vertical-align : middle;
      }

      a {
        @include rem(padding, 30px 3px 0);
        @include rem(font-size, 13px);
        @extend .montserrat-regular;
        color: #68737a;
        @media (max-width : $screen-sm-min - 1) {
          text-indent : -999999px;
        }
      }
      a:hover {
        color: #ea6a6a;
      }
    }

    #block-system-main-menu & {
      @media #{$tablet} {
        @include rem(padding, 0 5px);
      }

      &:not(.first):before {
        @media #{$tablet} {
          /*content          : "";
          display          : inline-block;
          width            : 8px;
          height           : 8px;
          position         : relative;
          @include rem(left, -7px);
          background-color : #404547;
          opacity          : 0.4;
          border-radius    : 50%;*/
        }
      }

      @media #{$tablet} {
        &:hover {
          > a {
            background-color : $vert-clair;
            color            : $white !important;
          }

          &:nth-child(3) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : $univers-adulte;

              .bouton {
                color : $univers-adulte !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }

          &:nth-child(2) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : $univers-ado;

              .bouton {
                color : $univers-ado !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }

          &:nth-child(4) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : $univers-outils;

              .bouton {
                color : $univers-outils !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }

          &:nth-child(5) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : $univers-enseignants;

              .bouton {
                color : $univers-enseignants !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }

          &:nth-child(6) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : $numerique;

              .bouton {
                color : $numerique !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }

          &:nth-child(7) {
            .dropdown-toggle,
            .dropdown-menu {
              background-color : #68737a;

              .bouton {
                color : #68737a !important;

                &:hover {
                  color : $white !important;
                }
              }
            }
          }
        }
      }

      @media (max-width : $screen-xs-max) {
        &:nth-child(1) {
          background-color : $vert-clair;

          .dropdown-menu {
            background-color : $vert-clair;
          }

          .bouton {
            color : $vert-clair !important;
          }
        }

        &:nth-child(2) {
          background-color : $univers-ado;

          .dropdown-menu {
            background-color : $univers-ado;
          }

          .bouton {
            color : $univers-ado !important;
          }
        }

        &:nth-child(3) {
          background-color : $univers-adulte;

          .dropdown-menu {
            background-color : $univers-adulte;
          }

          .bouton {
            color : $univers-adulte !important;
          }
        }

        &:nth-child(4) {
          background-color : $univers-outils;

          .dropdown-menu {
            background-color : $univers-outils;
          }

          .bouton {
            color : $univers-outils !important;
          }
        }

        &:nth-child(5) {
          background-color : $univers-enseignants;

          .dropdown-menu {
            background-color : $univers-enseignants;
          }

          .bouton {
            color : $univers-enseignants !important;
          }
        }

        &:nth-child(6) {
          background-color : $numerique;

          .dropdown-menu {
            background-color : $numerique;
          }

          .bouton {
            color : $numerique !important;
          }
        }

        &:nth-child(7) {
          background-color : #68737a;

          .dropdown-menu {
            background-color : #68737a;
          }
        }
      }

      .dropdown-menu {
        @media screen and (max-width : $screen-xs-max) {
          margin  : -1px 0 0;
          padding : 0 0 15px;
        }
      }

      > a {
        display        : inline-block;
        vertical-align : middle;
        @include rem(padding, 10px 15px);
        @include rem(margin, 10px 0px);
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        border-radius: 25px;
        color          : $menu;
        @include rem(font-size, 15px);
        text-transform : uppercase;
        letter-spacing : -0.15px;

        &.dropdown-toggle {
          @media (max-width : $screen-xs-max) {
            width         : 100%;
            padding-left  : 15px;
            padding-right : 15px;
            color         : $white;
          }
        }

        &.active-trail {
          @media #{$tablet} {
            .grands-ados-et-adultes & {
              color : $univers-adulte;
            }

            .enfants-et-preados & {
              color : $vert-clair;
            }

            .adolescents & {
              color : $univers-ado;
            }

            .outils & {
              color : $univers-outils;
            }

            .enseignants & {
              color : $univers-enseignants;
            }

            .numerique & {
              color : $numerique;
            }

            .actualites & {
              color : $red;
            }
          }
        }
      }
    }

    a {
      @extend .montserrat-regular;

      #block-system-main-menu & {
        @media (max-width : $screen-xs-max) {
          color : $white !important;
        }
      }

      &.bouton {
        @media (max-width : $screen-xs-max) {
          margin-left   : 15px;
          padding-left  : 15px;
          padding-right : 15px;
        }
      }
    }
  }

  .caret {
    color : $white;
    @media #{$tablet} {
      display : none;
    }
  }

}

.nav li > a:hover,
.nav li > a:active,
.nav li > a:focus {
  background-color : transparent;
}

.dropdown-menu {
  .expanded:hover & {
    display : block;
  }

  li.active {
    a {
      background-color : transparent;
    }
  }

  #block-system-main-menu & {
    border        : none;
    border-radius : 0;
    @media #{$tablet} {
      @include rem(left, -18px);
      top              : 97%;
      width            : 300px;
      @include rem(padding, 33px 19px);
      @include box-shadow(0 14px 21px rgba(0, 0, 0, 0.22));
      background-color : $vert-clair;

      a {
        color : $white;

        &:hover {
          color : $white;
        }
      }
    }

    @media (max-width : $screen-xs-max) {
      width      : 100%;
      position   : inherit;
      text-align : left;
      box-shadow : none;
    }

    a {
      @include rem(font-size, 16px);
      background-color : transparent;
    }
  }

  .leaf {
    a:hover {
      text-decoration : underline;
    }
  }

  .last {
    @include rem(margin-top, 25px);
    @media #{$tablet} {
      @include rem(margin, 25px 20px 0);
    }
  }
}
