.rrssb {
  padding : 15px 0 !important;

  .node-hw_intervenant-full &,
  .node-edito-full & {
    @media #{$tablet} {
      text-align : left;
    }
  }

  li {
    width     : 20%;
    max-width : 50px !important;
  }

  span {
    font-size : 16px;

    &.rrssb-text {
      display     : block;
      text-indent : -999999px;
    }
  }

  a {
    width   : 40px;
    margin  : 0 auto;
    opacity : 0.8;

    .enfants-et-preados & {
      background-color : $vert-clair !important;
    }

    .adolescents & {
      background-color : $univers-ado !important;
    }

    .grands-ados-et-adultes & {
      background-color : $univers-adulte !important;
    }

    .outils & {
      background-color : $univers-outils !important;
    }

    .enseignants & {
      background-color : $univers-enseignants !important;
    }

    .page-numerique & {
      background-color : $numerique !important;
    }

    .node-hw_intervenant-full &,
    .node-edito-full & {
      background-color : $red !important;
    }

    &:hover {
      opacity : 1;
    }
  }
}
