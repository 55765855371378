@font-face {
  font-family : 'montserratbold';
  src         : url('../fonts/montserrat/montserrat-bold.eot');
  src         : url('../fonts/montserrat/montserrat-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/montserrat-bold.woff2') format('woff2'),
  url('../fonts/montserrat/montserrat-bold.woff') format('woff'),
  url('../fonts/montserrat/montserrat-bold.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : 'montserratregular';
  src         : url('../fonts/montserrat/montserrat-regular.eot');
  src         : url('../fonts/montserrat/montserrat-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/montserrat-regular.woff2') format('woff2'),
  url('../fonts/montserrat/montserrat-regular.woff') format('woff'),
  url('../fonts/montserrat/montserrat-regular.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : 'montserratlight';
  src         : url('../fonts/montserrat/montserrat-light.eot');
  src         : url('../fonts/montserrat/montserrat-light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/montserrat/montserrat-light.woff2') format('woff2'),
  url('../fonts/montserrat/montserrat-light.woff') format('woff'),
  url('../fonts/montserrat/montserrat-light.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}
@font-face {
  font-family : 'phenomenabold';
  src         : url('../fonts/phenomena/phenomena-bold.eot');
  src         : url('../fonts/phenomena/phenomena-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/phenomena/phenomena-bold.woff2') format('woff2'),
  url('../fonts/phenomena/phenomena-bold.woff') format('woff'),
  url('../fonts/phenomena/phenomena-bold.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}
@font-face {
  font-family : 'phenomenaregular';
  src         : url('../fonts/phenomena/phenomena-regular.eot');
  src         : url('../fonts/phenomena/phenomena-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/phenomena/phenomena-regular.woff2') format('woff2'),
  url('../fonts/phenomena/phenomena-regular.woff') format('woff'),
  url('../fonts/phenomena/phenomena-regular.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal; 
}
@font-face {
  font-family : 'firasanscondensedregular';
  src         : url('../fonts/firasanscondensed/firasanscondensed-regular.eot');
  src         : url('../fonts/firasanscondensed/firasanscondensed-regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/firasanscondensed/firasanscondensed-regular.woff2') format('woff2'),
  url('../fonts/firasanscondensed/firasanscondensed-regular.woff') format('woff'),
  url('../fonts/firasanscondensed/firasanscondensed-regular.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}
@font-face {
  font-family : 'firasanscondensedsemibold';
  src         : url('../fonts/firasanscondensed/firasanscondensed-semibold.eot');
  src         : url('../fonts/firasanscondensed/firasanscondensed-semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/firasanscondensed/firasanscondensed-semibold.woff2') format('woff2'),
  url('../fonts/firasanscondensed/firasanscondensed-semibold.woff') format('woff'),
  url('../fonts/firasanscondensed/firasanscondensed-semibold.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}

@font-face {
  font-family : 'firasanscondensedbold';
  src         : url('../fonts/firasanscondensed/firasanscondensed-bold.eot');
  src         : url('../fonts/firasanscondensed/firasanscondensed-bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/firasanscondensed/firasanscondensed-bold.woff2') format('woff2'),
  url('../fonts/firasanscondensed/firasanscondensed-bold.woff') format('woff'),
  url('../fonts/firasanscondensed/firasanscondensed-bold.ttf') format('truetype');
  font-weight : normal;
  font-style  : normal;
}