// BREAKPOINTS //////////
$min-mobile : 480px;
$mobile : 768px;
$tab : 992px;
$desk-inter : 1200px;
$desk : 10000px;

$font-html-desktop-rem : 16; // la font-size de référence pour les rem en desktop

// Font-awesome icons //////////
$share : "\f1e0";
$favoris : "\f006";
$favoris-out : "\f005";

// Classes TID
$enfants : tid-7879;
$ados : tid-7867;
$grands-ados : grands-ados-et-adultes;
$tid-grands-ados : tid-7872;
$tid-formation : tid-7835;
$page-enseignants : page-taxonomy-term-23;
$enseignants : enseignants;