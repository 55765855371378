.fond {
  background-color : $gris-base;
}

.#{$enfants},
.enfants-et-preados {
  .fond {
    background-color : $vert-clair;
  }
}

.#{$tid-grands-ados},
.#{$grands-ados} {
  .fond {
    background-color : $univers-adulte;
  }
}

.adolescents {
  .fond {
    background-color : $univers-ado;
  }
}

.outils {
  .fond {
    background-color : $univers-outils;
  }
}

.#{$tid-formation},
.#{$enseignants} {
  .fond {
    background-color : $univers-enseignants;
  }
}

.page-numerique {
	#page-title-wrapper,
	.fond {
	  background: $numerique;
	}
}

