/* Transformer les pixels en rem */

@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : ();
  $rem : ();
  
  @each $value in $values {
    
    @if $value == 0 or $value == auto {
      $px : append($px, $value);
      $rem : append($rem, $value);
    } @else {
      $unit : unit($value);
      $val : parseInt($value);
      $px : append($px, $value);
      $rem : append($rem, ($val / $font-html-desktop-rem + rem));
    }
  }
  
  @if $px == $rem {
    #{$property}: $px;
  } @else {
    #{$property}: $px;
    #{$property}: $rem;
  }
}

/*****
Font-awesome
*****/
@mixin fontawesome-icon {
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}